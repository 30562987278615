import { combineReducers } from '@reduxjs/toolkit';

import employeeDetails from './employeeDetails';
import employeeMap from './employeeMap';
import employeePagination from './employeePagination';

const employees = combineReducers({
  employeeMap,
  employeeDetails,
  employeePagination,
});

export default employees;

export * from './selectors';
