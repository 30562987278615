// source: proto/entities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

import * as proto_audit_action_pb from 'hr_web/proto/audit_action_pb';
goog.object.extend(proto, proto_audit_action_pb);
goog.exportSymbol('proto.hr_system.AuditRecord', null, global);
goog.exportSymbol('proto.hr_system.AuditRecord.AuditContent', null, global);
goog.exportSymbol('proto.hr_system.BusinessUnit', null, global);
goog.exportSymbol('proto.hr_system.CostCenter', null, global);
goog.exportSymbol('proto.hr_system.Department', null, global);
goog.exportSymbol('proto.hr_system.EmploymentType', null, global);
goog.exportSymbol('proto.hr_system.JobFunction', null, global);
goog.exportSymbol('proto.hr_system.JobType', null, global);
goog.exportSymbol('proto.hr_system.Level', null, global);
goog.exportSymbol('proto.hr_system.Site', null, global);
goog.exportSymbol('proto.hr_system.Team', null, global);
goog.exportSymbol('proto.hr_system.Title', null, global);
goog.exportSymbol('proto.hr_system.User', null, global);
goog.exportSymbol('proto.hr_system.User.EmployeeStatus', null, global);
goog.exportSymbol('proto.hr_system.UserSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.User.repeatedFields_, null);
};
goog.inherits(proto.hr_system.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.User.displayName = 'proto.hr_system.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UserSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.UserSummary.repeatedFields_, null);
};
goog.inherits(proto.hr_system.UserSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UserSummary.displayName = 'proto.hr_system.UserSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.Department = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.Department, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.Department.displayName = 'proto.hr_system.Department';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.EmploymentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.EmploymentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.EmploymentType.displayName = 'proto.hr_system.EmploymentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.JobFunction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.JobFunction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.JobFunction.displayName = 'proto.hr_system.JobFunction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.JobType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.JobType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.JobType.displayName = 'proto.hr_system.JobType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.Level = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.Level, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.Level.displayName = 'proto.hr_system.Level';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.Site = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.Site.displayName = 'proto.hr_system.Site';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.Team = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.Team, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.Team.displayName = 'proto.hr_system.Team';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.Title = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.Title, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.Title.displayName = 'proto.hr_system.Title';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.BusinessUnit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.BusinessUnit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.BusinessUnit.displayName = 'proto.hr_system.BusinessUnit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CostCenter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CostCenter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CostCenter.displayName = 'proto.hr_system.CostCenter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.AuditRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.AuditRecord.repeatedFields_, null);
};
goog.inherits(proto.hr_system.AuditRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.AuditRecord.displayName = 'proto.hr_system.AuditRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.AuditRecord.AuditContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.AuditRecord.AuditContent.repeatedFields_, null);
};
goog.inherits(proto.hr_system.AuditRecord.AuditContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.AuditRecord.AuditContent.displayName = 'proto.hr_system.AuditRecord.AuditContent';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.User.repeatedFields_ = [23,24,25,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.User.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    employeeId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    companyEmail: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    ssoUserId: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    firstName: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    middleName: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    lastName: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    legalName: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    displayName: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    site: (f = msg.getSite()) && proto.hr_system.Site.toObject(includeInstance, f),
    employmentType: (f = msg.getEmploymentType()) && proto.hr_system.EmploymentType.toObject(includeInstance, f),
    jobFunction: (f = msg.getJobFunction()) && proto.hr_system.JobFunction.toObject(includeInstance, f),
    jobType: (f = msg.getJobType()) && proto.hr_system.JobType.toObject(includeInstance, f),
    level: (f = msg.getLevel()) && proto.hr_system.Level.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && proto.hr_system.Title.toObject(includeInstance, f),
    startingDate: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f,
    status: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f,
    avatarUrl: (f = jspb.Message.getField(msg, 18)) == null ? undefined : f,
    techLeadAreas: (f = jspb.Message.getField(msg, 19)) == null ? undefined : f,
    projectLeadAreas: (f = jspb.Message.getField(msg, 20)) == null ? undefined : f,
    notes: (f = jspb.Message.getField(msg, 21)) == null ? undefined : f,
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    proto.hr_system.Department.toObject, includeInstance),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.hr_system.Team.toObject, includeInstance),
    costCentersList: jspb.Message.toObjectList(msg.getCostCentersList(),
    proto.hr_system.CostCenter.toObject, includeInstance),
    businessUnitsList: jspb.Message.toObjectList(msg.getBusinessUnitsList(),
    proto.hr_system.BusinessUnit.toObject, includeInstance),
    levelString: (f = jspb.Message.getField(msg, 27)) == null ? undefined : f,
    isPerfParticipant: (f = jspb.Message.getBooleanField(msg, 28)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.User}
 */
proto.hr_system.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.User;
  return proto.hr_system.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.User}
 */
proto.hr_system.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyEmail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSsoUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 10:
      var value = new proto.hr_system.Site;
      reader.readMessage(value,proto.hr_system.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 11:
      var value = new proto.hr_system.EmploymentType;
      reader.readMessage(value,proto.hr_system.EmploymentType.deserializeBinaryFromReader);
      msg.setEmploymentType(value);
      break;
    case 12:
      var value = new proto.hr_system.JobFunction;
      reader.readMessage(value,proto.hr_system.JobFunction.deserializeBinaryFromReader);
      msg.setJobFunction(value);
      break;
    case 13:
      var value = new proto.hr_system.JobType;
      reader.readMessage(value,proto.hr_system.JobType.deserializeBinaryFromReader);
      msg.setJobType(value);
      break;
    case 14:
      var value = new proto.hr_system.Level;
      reader.readMessage(value,proto.hr_system.Level.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    case 15:
      var value = new proto.hr_system.Title;
      reader.readMessage(value,proto.hr_system.Title.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartingDate(value);
      break;
    case 17:
      var value = /** @type {!proto.hr_system.User.EmployeeStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTechLeadAreas(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectLeadAreas(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 23:
      var value = new proto.hr_system.Department;
      reader.readMessage(value,proto.hr_system.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    case 24:
      var value = new proto.hr_system.Team;
      reader.readMessage(value,proto.hr_system.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 25:
      var value = new proto.hr_system.CostCenter;
      reader.readMessage(value,proto.hr_system.CostCenter.deserializeBinaryFromReader);
      msg.addCostCenters(value);
      break;
    case 26:
      var value = new proto.hr_system.BusinessUnit;
      reader.readMessage(value,proto.hr_system.BusinessUnit.deserializeBinaryFromReader);
      msg.addBusinessUnits(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevelString(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPerfParticipant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.hr_system.Site.serializeBinaryToWriter
    );
  }
  f = message.getEmploymentType();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.hr_system.EmploymentType.serializeBinaryToWriter
    );
  }
  f = message.getJobFunction();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.hr_system.JobFunction.serializeBinaryToWriter
    );
  }
  f = message.getJobType();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.hr_system.JobType.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.hr_system.Level.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.hr_system.Title.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = /** @type {!proto.hr_system.User.EmployeeStatus} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeString(
      19,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.hr_system.Department.serializeBinaryToWriter
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.hr_system.Team.serializeBinaryToWriter
    );
  }
  f = message.getCostCentersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.hr_system.CostCenter.serializeBinaryToWriter
    );
  }
  f = message.getBusinessUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.hr_system.BusinessUnit.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeString(
      27,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeBool(
      28,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.hr_system.User.EmployeeStatus = {
  UNKNOWN: 0,
  ACTIVE: 1,
  INACTIVE: 2
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string employee_id = 2;
 * @return {string}
 */
proto.hr_system.User.prototype.getEmployeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setEmployeeId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearEmployeeId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasEmployeeId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string company_email = 3;
 * @return {string}
 */
proto.hr_system.User.prototype.getCompanyEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setCompanyEmail = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearCompanyEmail = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasCompanyEmail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 sso_user_id = 4;
 * @return {number}
 */
proto.hr_system.User.prototype.getSsoUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setSsoUserId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearSsoUserId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasSsoUserId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string first_name = 5;
 * @return {string}
 */
proto.hr_system.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setFirstName = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearFirstName = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string middle_name = 6;
 * @return {string}
 */
proto.hr_system.User.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setMiddleName = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearMiddleName = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasMiddleName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string last_name = 7;
 * @return {string}
 */
proto.hr_system.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setLastName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearLastName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string legal_name = 8;
 * @return {string}
 */
proto.hr_system.User.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setLegalName = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearLegalName = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasLegalName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string display_name = 9;
 * @return {string}
 */
proto.hr_system.User.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setDisplayName = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearDisplayName = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Site site = 10;
 * @return {?proto.hr_system.Site}
 */
proto.hr_system.User.prototype.getSite = function() {
  return /** @type{?proto.hr_system.Site} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.Site, 10));
};


/**
 * @param {?proto.hr_system.Site|undefined} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasSite = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional EmploymentType employment_type = 11;
 * @return {?proto.hr_system.EmploymentType}
 */
proto.hr_system.User.prototype.getEmploymentType = function() {
  return /** @type{?proto.hr_system.EmploymentType} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.EmploymentType, 11));
};


/**
 * @param {?proto.hr_system.EmploymentType|undefined} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setEmploymentType = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearEmploymentType = function() {
  return this.setEmploymentType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasEmploymentType = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional JobFunction job_function = 12;
 * @return {?proto.hr_system.JobFunction}
 */
proto.hr_system.User.prototype.getJobFunction = function() {
  return /** @type{?proto.hr_system.JobFunction} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.JobFunction, 12));
};


/**
 * @param {?proto.hr_system.JobFunction|undefined} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setJobFunction = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearJobFunction = function() {
  return this.setJobFunction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasJobFunction = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional JobType job_type = 13;
 * @return {?proto.hr_system.JobType}
 */
proto.hr_system.User.prototype.getJobType = function() {
  return /** @type{?proto.hr_system.JobType} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.JobType, 13));
};


/**
 * @param {?proto.hr_system.JobType|undefined} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setJobType = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearJobType = function() {
  return this.setJobType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasJobType = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Level level = 14;
 * @return {?proto.hr_system.Level}
 */
proto.hr_system.User.prototype.getLevel = function() {
  return /** @type{?proto.hr_system.Level} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.Level, 14));
};


/**
 * @param {?proto.hr_system.Level|undefined} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Title title = 15;
 * @return {?proto.hr_system.Title}
 */
proto.hr_system.User.prototype.getTitle = function() {
  return /** @type{?proto.hr_system.Title} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.Title, 15));
};


/**
 * @param {?proto.hr_system.Title|undefined} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string starting_date = 16;
 * @return {string}
 */
proto.hr_system.User.prototype.getStartingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setStartingDate = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearStartingDate = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasStartingDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional EmployeeStatus status = 17;
 * @return {!proto.hr_system.User.EmployeeStatus}
 */
proto.hr_system.User.prototype.getStatus = function() {
  return /** @type {!proto.hr_system.User.EmployeeStatus} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.hr_system.User.EmployeeStatus} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string avatar_url = 18;
 * @return {string}
 */
proto.hr_system.User.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearAvatarUrl = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasAvatarUrl = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string tech_lead_areas = 19;
 * @return {string}
 */
proto.hr_system.User.prototype.getTechLeadAreas = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setTechLeadAreas = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearTechLeadAreas = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasTechLeadAreas = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string project_lead_areas = 20;
 * @return {string}
 */
proto.hr_system.User.prototype.getProjectLeadAreas = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setProjectLeadAreas = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearProjectLeadAreas = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasProjectLeadAreas = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string notes = 21;
 * @return {string}
 */
proto.hr_system.User.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setNotes = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearNotes = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasNotes = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated Department departments = 23;
 * @return {!Array<!proto.hr_system.Department>}
 */
proto.hr_system.User.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.hr_system.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.Department, 23));
};


/**
 * @param {!Array<!proto.hr_system.Department>} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.hr_system.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.Department}
 */
proto.hr_system.User.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.hr_system.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * repeated Team teams = 24;
 * @return {!Array<!proto.hr_system.Team>}
 */
proto.hr_system.User.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.hr_system.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.Team, 24));
};


/**
 * @param {!Array<!proto.hr_system.Team>} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.hr_system.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.Team}
 */
proto.hr_system.User.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.hr_system.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * repeated CostCenter cost_centers = 25;
 * @return {!Array<!proto.hr_system.CostCenter>}
 */
proto.hr_system.User.prototype.getCostCentersList = function() {
  return /** @type{!Array<!proto.hr_system.CostCenter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.CostCenter, 25));
};


/**
 * @param {!Array<!proto.hr_system.CostCenter>} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setCostCentersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.hr_system.CostCenter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.CostCenter}
 */
proto.hr_system.User.prototype.addCostCenters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.hr_system.CostCenter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearCostCentersList = function() {
  return this.setCostCentersList([]);
};


/**
 * repeated BusinessUnit business_units = 26;
 * @return {!Array<!proto.hr_system.BusinessUnit>}
 */
proto.hr_system.User.prototype.getBusinessUnitsList = function() {
  return /** @type{!Array<!proto.hr_system.BusinessUnit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.BusinessUnit, 26));
};


/**
 * @param {!Array<!proto.hr_system.BusinessUnit>} value
 * @return {!proto.hr_system.User} returns this
*/
proto.hr_system.User.prototype.setBusinessUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.hr_system.BusinessUnit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.BusinessUnit}
 */
proto.hr_system.User.prototype.addBusinessUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.hr_system.BusinessUnit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearBusinessUnitsList = function() {
  return this.setBusinessUnitsList([]);
};


/**
 * optional string level_string = 27;
 * @return {string}
 */
proto.hr_system.User.prototype.getLevelString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setLevelString = function(value) {
  return jspb.Message.setField(this, 27, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearLevelString = function() {
  return jspb.Message.setField(this, 27, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasLevelString = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional bool is_perf_participant = 28;
 * @return {boolean}
 */
proto.hr_system.User.prototype.getIsPerfParticipant = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.setIsPerfParticipant = function(value) {
  return jspb.Message.setField(this, 28, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.User} returns this
 */
proto.hr_system.User.prototype.clearIsPerfParticipant = function() {
  return jspb.Message.setField(this, 28, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.User.prototype.hasIsPerfParticipant = function() {
  return jspb.Message.getField(this, 28) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.UserSummary.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UserSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UserSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UserSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UserSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.hr_system.User.toObject(includeInstance, f),
    directManagerEmailsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dottedManagerEmailsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UserSummary}
 */
proto.hr_system.UserSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UserSummary;
  return proto.hr_system.UserSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UserSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UserSummary}
 */
proto.hr_system.UserSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.User;
      reader.readMessage(value,proto.hr_system.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDirectManagerEmails(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDottedManagerEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UserSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UserSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UserSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UserSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.User.serializeBinaryToWriter
    );
  }
  f = message.getDirectManagerEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDottedManagerEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.hr_system.User}
 */
proto.hr_system.UserSummary.prototype.getUser = function() {
  return /** @type{?proto.hr_system.User} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.User, 1));
};


/**
 * @param {?proto.hr_system.User|undefined} value
 * @return {!proto.hr_system.UserSummary} returns this
*/
proto.hr_system.UserSummary.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.UserSummary} returns this
 */
proto.hr_system.UserSummary.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UserSummary.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string direct_manager_emails = 2;
 * @return {!Array<string>}
 */
proto.hr_system.UserSummary.prototype.getDirectManagerEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.UserSummary} returns this
 */
proto.hr_system.UserSummary.prototype.setDirectManagerEmailsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserSummary} returns this
 */
proto.hr_system.UserSummary.prototype.addDirectManagerEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UserSummary} returns this
 */
proto.hr_system.UserSummary.prototype.clearDirectManagerEmailsList = function() {
  return this.setDirectManagerEmailsList([]);
};


/**
 * repeated string dotted_manager_emails = 3;
 * @return {!Array<string>}
 */
proto.hr_system.UserSummary.prototype.getDottedManagerEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.UserSummary} returns this
 */
proto.hr_system.UserSummary.prototype.setDottedManagerEmailsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserSummary} returns this
 */
proto.hr_system.UserSummary.prototype.addDottedManagerEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UserSummary} returns this
 */
proto.hr_system.UserSummary.prototype.clearDottedManagerEmailsList = function() {
  return this.setDottedManagerEmailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.Department.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.Department.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.Department} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Department.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    parentId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.Department}
 */
proto.hr_system.Department.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.Department;
  return proto.hr_system.Department.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.Department} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.Department}
 */
proto.hr_system.Department.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.Department.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.Department.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.Department} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Department.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.Department.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.Department} returns this
 */
proto.hr_system.Department.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Department} returns this
 */
proto.hr_system.Department.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Department.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.Department.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.Department} returns this
 */
proto.hr_system.Department.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Department} returns this
 */
proto.hr_system.Department.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Department.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 parent_id = 3;
 * @return {number}
 */
proto.hr_system.Department.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.Department} returns this
 */
proto.hr_system.Department.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Department} returns this
 */
proto.hr_system.Department.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Department.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.EmploymentType.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.EmploymentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.EmploymentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.EmploymentType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.EmploymentType}
 */
proto.hr_system.EmploymentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.EmploymentType;
  return proto.hr_system.EmploymentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.EmploymentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.EmploymentType}
 */
proto.hr_system.EmploymentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.EmploymentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.EmploymentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.EmploymentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.EmploymentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.EmploymentType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.EmploymentType} returns this
 */
proto.hr_system.EmploymentType.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.EmploymentType} returns this
 */
proto.hr_system.EmploymentType.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.EmploymentType.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.EmploymentType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.EmploymentType} returns this
 */
proto.hr_system.EmploymentType.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.EmploymentType} returns this
 */
proto.hr_system.EmploymentType.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.EmploymentType.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.JobFunction.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.JobFunction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.JobFunction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.JobFunction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.JobFunction}
 */
proto.hr_system.JobFunction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.JobFunction;
  return proto.hr_system.JobFunction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.JobFunction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.JobFunction}
 */
proto.hr_system.JobFunction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.JobFunction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.JobFunction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.JobFunction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.JobFunction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.JobFunction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.JobFunction} returns this
 */
proto.hr_system.JobFunction.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.JobFunction} returns this
 */
proto.hr_system.JobFunction.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.JobFunction.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.JobFunction.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.JobFunction} returns this
 */
proto.hr_system.JobFunction.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.JobFunction} returns this
 */
proto.hr_system.JobFunction.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.JobFunction.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.JobType.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.JobType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.JobType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.JobType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.JobType}
 */
proto.hr_system.JobType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.JobType;
  return proto.hr_system.JobType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.JobType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.JobType}
 */
proto.hr_system.JobType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.JobType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.JobType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.JobType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.JobType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.JobType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.JobType} returns this
 */
proto.hr_system.JobType.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.JobType} returns this
 */
proto.hr_system.JobType.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.JobType.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.JobType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.JobType} returns this
 */
proto.hr_system.JobType.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.JobType} returns this
 */
proto.hr_system.JobType.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.JobType.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.Level.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.Level.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.Level} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Level.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.Level}
 */
proto.hr_system.Level.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.Level;
  return proto.hr_system.Level.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.Level} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.Level}
 */
proto.hr_system.Level.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.Level.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.Level.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.Level} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Level.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.Level.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.Level} returns this
 */
proto.hr_system.Level.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Level} returns this
 */
proto.hr_system.Level.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Level.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.Level.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.Level} returns this
 */
proto.hr_system.Level.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Level} returns this
 */
proto.hr_system.Level.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Level.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.Site.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.Site.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.Site} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Site.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.Site}
 */
proto.hr_system.Site.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.Site;
  return proto.hr_system.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.Site}
 */
proto.hr_system.Site.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.Site.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Site.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.Site.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.Site} returns this
 */
proto.hr_system.Site.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Site} returns this
 */
proto.hr_system.Site.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Site.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.Site.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.Site} returns this
 */
proto.hr_system.Site.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Site} returns this
 */
proto.hr_system.Site.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Site.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.Team.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.Team.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.Team} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Team.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.Team}
 */
proto.hr_system.Team.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.Team;
  return proto.hr_system.Team.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.Team} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.Team}
 */
proto.hr_system.Team.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.Team.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.Team.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.Team} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Team.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.Team.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.Team} returns this
 */
proto.hr_system.Team.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Team} returns this
 */
proto.hr_system.Team.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Team.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.Team.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.Team} returns this
 */
proto.hr_system.Team.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Team} returns this
 */
proto.hr_system.Team.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Team.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.Title.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.Title.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.Title} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Title.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.Title}
 */
proto.hr_system.Title.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.Title;
  return proto.hr_system.Title.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.Title} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.Title}
 */
proto.hr_system.Title.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.Title.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.Title.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.Title} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.Title.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.Title.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.Title} returns this
 */
proto.hr_system.Title.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Title} returns this
 */
proto.hr_system.Title.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Title.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.Title.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.Title} returns this
 */
proto.hr_system.Title.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.Title} returns this
 */
proto.hr_system.Title.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.Title.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.BusinessUnit.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.BusinessUnit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.BusinessUnit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.BusinessUnit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.BusinessUnit}
 */
proto.hr_system.BusinessUnit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.BusinessUnit;
  return proto.hr_system.BusinessUnit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.BusinessUnit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.BusinessUnit}
 */
proto.hr_system.BusinessUnit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.BusinessUnit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.BusinessUnit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.BusinessUnit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.BusinessUnit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.BusinessUnit.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.BusinessUnit} returns this
 */
proto.hr_system.BusinessUnit.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.BusinessUnit} returns this
 */
proto.hr_system.BusinessUnit.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BusinessUnit.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.BusinessUnit.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.BusinessUnit} returns this
 */
proto.hr_system.BusinessUnit.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.BusinessUnit} returns this
 */
proto.hr_system.BusinessUnit.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BusinessUnit.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CostCenter.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CostCenter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CostCenter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CostCenter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CostCenter}
 */
proto.hr_system.CostCenter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CostCenter;
  return proto.hr_system.CostCenter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CostCenter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CostCenter}
 */
proto.hr_system.CostCenter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CostCenter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CostCenter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CostCenter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CostCenter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.CostCenter.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CostCenter} returns this
 */
proto.hr_system.CostCenter.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CostCenter} returns this
 */
proto.hr_system.CostCenter.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CostCenter.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hr_system.CostCenter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.CostCenter} returns this
 */
proto.hr_system.CostCenter.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CostCenter} returns this
 */
proto.hr_system.CostCenter.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CostCenter.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.AuditRecord.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.AuditRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.AuditRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.AuditRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.AuditRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    createTimestampMillis: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    editorId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    editorName: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    targetModelName: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    targetKeyId: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    targetKeyAuditName: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    action: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    auditContentsList: jspb.Message.toObjectList(msg.getAuditContentsList(),
    proto.hr_system.AuditRecord.AuditContent.toObject, includeInstance),
    effectiveDate: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.AuditRecord}
 */
proto.hr_system.AuditRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.AuditRecord;
  return proto.hr_system.AuditRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.AuditRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.AuditRecord}
 */
proto.hr_system.AuditRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateTimestampMillis(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEditorId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditorName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetModelName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetKeyId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetKeyAuditName(value);
      break;
    case 7:
      var value = /** @type {!proto.hr_system.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 8:
      var value = new proto.hr_system.AuditRecord.AuditContent;
      reader.readMessage(value,proto.hr_system.AuditRecord.AuditContent.deserializeBinaryFromReader);
      msg.addAuditContents(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectiveDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.AuditRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.AuditRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.AuditRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.AuditRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {!proto.hr_system.Action} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAuditContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.hr_system.AuditRecord.AuditContent.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.AuditRecord.AuditContent.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.AuditRecord.AuditContent.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.AuditRecord.AuditContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.AuditRecord.AuditContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.AuditRecord.AuditContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyName: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    fromContentList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    toContentList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.AuditRecord.AuditContent}
 */
proto.hr_system.AuditRecord.AuditContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.AuditRecord.AuditContent;
  return proto.hr_system.AuditRecord.AuditContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.AuditRecord.AuditContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.AuditRecord.AuditContent}
 */
proto.hr_system.AuditRecord.AuditContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFromContent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addToContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.AuditRecord.AuditContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.AuditRecord.AuditContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.AuditRecord.AuditContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.AuditRecord.AuditContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromContentList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getToContentList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string key_name = 1;
 * @return {string}
 */
proto.hr_system.AuditRecord.AuditContent.prototype.getKeyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.AuditRecord.AuditContent} returns this
 */
proto.hr_system.AuditRecord.AuditContent.prototype.setKeyName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord.AuditContent} returns this
 */
proto.hr_system.AuditRecord.AuditContent.prototype.clearKeyName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.AuditContent.prototype.hasKeyName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string from_content = 2;
 * @return {!Array<string>}
 */
proto.hr_system.AuditRecord.AuditContent.prototype.getFromContentList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.AuditRecord.AuditContent} returns this
 */
proto.hr_system.AuditRecord.AuditContent.prototype.setFromContentList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.AuditRecord.AuditContent} returns this
 */
proto.hr_system.AuditRecord.AuditContent.prototype.addFromContent = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.AuditRecord.AuditContent} returns this
 */
proto.hr_system.AuditRecord.AuditContent.prototype.clearFromContentList = function() {
  return this.setFromContentList([]);
};


/**
 * repeated string to_content = 3;
 * @return {!Array<string>}
 */
proto.hr_system.AuditRecord.AuditContent.prototype.getToContentList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.AuditRecord.AuditContent} returns this
 */
proto.hr_system.AuditRecord.AuditContent.prototype.setToContentList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.AuditRecord.AuditContent} returns this
 */
proto.hr_system.AuditRecord.AuditContent.prototype.addToContent = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.AuditRecord.AuditContent} returns this
 */
proto.hr_system.AuditRecord.AuditContent.prototype.clearToContentList = function() {
  return this.setToContentList([]);
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.AuditRecord.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 create_timestamp_millis = 12;
 * @return {number}
 */
proto.hr_system.AuditRecord.prototype.getCreateTimestampMillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setCreateTimestampMillis = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearCreateTimestampMillis = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasCreateTimestampMillis = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 editor_id = 2;
 * @return {number}
 */
proto.hr_system.AuditRecord.prototype.getEditorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setEditorId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearEditorId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasEditorId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string editor_name = 3;
 * @return {string}
 */
proto.hr_system.AuditRecord.prototype.getEditorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setEditorName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearEditorName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasEditorName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string target_model_name = 4;
 * @return {string}
 */
proto.hr_system.AuditRecord.prototype.getTargetModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setTargetModelName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearTargetModelName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasTargetModelName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 target_key_id = 5;
 * @return {number}
 */
proto.hr_system.AuditRecord.prototype.getTargetKeyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setTargetKeyId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearTargetKeyId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasTargetKeyId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string target_key_audit_name = 6;
 * @return {string}
 */
proto.hr_system.AuditRecord.prototype.getTargetKeyAuditName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setTargetKeyAuditName = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearTargetKeyAuditName = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasTargetKeyAuditName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Action action = 7;
 * @return {!proto.hr_system.Action}
 */
proto.hr_system.AuditRecord.prototype.getAction = function() {
  return /** @type {!proto.hr_system.Action} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.hr_system.Action} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setAction = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearAction = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasAction = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated AuditContent audit_contents = 8;
 * @return {!Array<!proto.hr_system.AuditRecord.AuditContent>}
 */
proto.hr_system.AuditRecord.prototype.getAuditContentsList = function() {
  return /** @type{!Array<!proto.hr_system.AuditRecord.AuditContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.AuditRecord.AuditContent, 8));
};


/**
 * @param {!Array<!proto.hr_system.AuditRecord.AuditContent>} value
 * @return {!proto.hr_system.AuditRecord} returns this
*/
proto.hr_system.AuditRecord.prototype.setAuditContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.hr_system.AuditRecord.AuditContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.AuditRecord.AuditContent}
 */
proto.hr_system.AuditRecord.prototype.addAuditContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.hr_system.AuditRecord.AuditContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearAuditContentsList = function() {
  return this.setAuditContentsList([]);
};


/**
 * optional string effective_date = 11;
 * @return {string}
 */
proto.hr_system.AuditRecord.prototype.getEffectiveDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.AuditRecord} returns this
 */
proto.hr_system.AuditRecord.prototype.clearEffectiveDate = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.AuditRecord.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


export const { AuditRecord, BusinessUnit, CostCenter, Department, EmploymentType, JobFunction, JobType, Level, Site, Team, Title, User, UserSummary } = proto.hr_system
