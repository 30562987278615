import { combineReducers } from '@reduxjs/toolkit';

import reviewCyclesMap from './reviewCycleMap';

const reviewCycles = combineReducers({
  reviewCyclesMap,
});

export default reviewCycles;

export * from './selectors';
