import { EmploymentType } from 'external/hr_system/proto/entities_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';
import {
  CreateOrUpdateEmploymentTypeRequest,
  CreateOrUpdateEmploymentTypeResponse,
  DeleteResponse,
  GetEmploymentTypesResponse,
} from '../external/hr_system/proto/org_chart_api_pb';

class EmploymentTypeApi extends BaseApi {
  private endpointPrefix = '/employment_types';
  private idBasedMapCacher: IdBasedMapCacher<
    EmploymentType.AsObject,
    GetEmploymentTypesResponse.AsObject
  >;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetEmploymentTypesResponse.AsObject>(() => this.list());
    this.idBasedMapCacher = new IdBasedMapCacher<
      EmploymentType.AsObject,
      GetEmploymentTypesResponse.AsObject
    >(apiCacher, (response) => response.employmentTypesList);
  }

  public async listAsCachedMap(force = false): Promise<Record<number, EmploymentType.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async list(): Promise<GetEmploymentTypesResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetEmploymentTypesResponse.toObject(
      false,
      GetEmploymentTypesResponse.deserializeBinary(buffer),
    );
  }

  public async add(name: string): Promise<CreateOrUpdateEmploymentTypeResponse.AsObject> {
    const request = new CreateOrUpdateEmploymentTypeRequest();
    const employmentType = new EmploymentType();
    employmentType.setName(name);
    request.setEmploymentType(employmentType);

    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateEmploymentTypeResponse.toObject(
      false,
      CreateOrUpdateEmploymentTypeResponse.deserializeBinary(buffer),
    );
  }

  public async update({
    name,
    id,
  }: {
    name: string;
    id: number;
  }): Promise<CreateOrUpdateEmploymentTypeResponse.AsObject> {
    const request = new CreateOrUpdateEmploymentTypeRequest();
    const employmentType = new EmploymentType();
    employmentType.setName(name);
    employmentType.setId(id);
    request.setEmploymentType(employmentType);

    const response = await this.put(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateEmploymentTypeResponse.toObject(
      false,
      CreateOrUpdateEmploymentTypeResponse.deserializeBinary(buffer),
    );
  }

  public async deleteOne(id: number): Promise<DeleteResponse.AsObject> {
    const response = await super.delete(this.endpointPrefix + '/' + id, {});
    const buffer = await getBufferArrayFromResponse(response);
    return DeleteResponse.toObject(false, DeleteResponse.deserializeBinary(buffer));
  }
}

export default new EmploymentTypeApi();
