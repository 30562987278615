import { camelCase, isPlainObject, mapKeys, mapValues, snakeCase } from 'lodash';

type MapKeysDeep = (cb: (value: unknown, key: string) => string) => (obj: any) => any; // eslint-disable-line @typescript-eslint/no-explicit-any

export const mapKeysDeep: MapKeysDeep = (cb) => (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(mapKeysDeep(cb));
  }
  if (isPlainObject(obj)) {
    return mapValues(mapKeys(obj, cb), mapKeysDeep(cb));
  }
  return obj;
};

export const toServerJson = mapKeysDeep((value, key) => {
  let newKey = key === key.toUpperCase() ? key : snakeCase(key);
  // Strip '_list' suffix to match the expect format in Python.
  if (Array.isArray(value) && newKey.endsWith('_list')) {
    return newKey.substr(0, newKey.length - '_list'.length);
  }
  return newKey;
});

export const toClientJson = mapKeysDeep((value, key) => camelCase(key));
export const toCapitalize = (inputString: string): string =>
  inputString[0].toUpperCase() + inputString.slice(1).toLowerCase();

export const getBufferArrayFromResponse = async (response: Response): Promise<Uint8Array> => {
  if (response.headers.get('isbase64encoded')) {
    return new Uint8Array(Buffer.from(await response.text(), 'base64'));
  }
  return new Uint8Array(await response.arrayBuffer());
};
