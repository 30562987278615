import { Title } from 'external/hr_system/proto/entities_pb';
import {
  CreateOrUpdateTitleRequest,
  CreateOrUpdateTitleResponse,
  DeleteResponse,
  GetTitlesResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';

class PositionApi extends BaseApi {
  private endpointPrefix = '/titles';
  private idBasedMapCacher: IdBasedMapCacher<Title.AsObject, GetTitlesResponse.AsObject>;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetTitlesResponse.AsObject>(() => this.list());
    this.idBasedMapCacher = new IdBasedMapCacher<Title.AsObject, GetTitlesResponse.AsObject>(
      apiCacher,
      (response) => response.titlesList,
    );
  }

  public async listAsCachedMap(force = false): Promise<Record<number, Title.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async list(): Promise<GetTitlesResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetTitlesResponse.toObject(false, GetTitlesResponse.deserializeBinary(buffer));
  }

  public async add(name: string): Promise<CreateOrUpdateTitleResponse.AsObject> {
    const request = new CreateOrUpdateTitleRequest();
    const title = new Title();
    title.setName(name);
    request.setTitle(title);

    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateTitleResponse.toObject(
      false,
      CreateOrUpdateTitleResponse.deserializeBinary(buffer),
    );
  }

  public async update({
    name,
    id,
  }: {
    name: string;
    id: number;
  }): Promise<CreateOrUpdateTitleResponse.AsObject> {
    const request = new CreateOrUpdateTitleRequest();
    const title = new Title();
    title.setName(name);
    title.setId(id);
    request.setTitle(title);

    const response = await this.put(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateTitleResponse.toObject(
      false,
      CreateOrUpdateTitleResponse.deserializeBinary(buffer),
    );
  }

  public async deleteOne(id: number): Promise<DeleteResponse.AsObject> {
    const response = await super.delete(this.endpointPrefix + '/' + id, {});
    const buffer = await getBufferArrayFromResponse(response);
    return DeleteResponse.toObject(false, DeleteResponse.deserializeBinary(buffer));
  }
}

export default new PositionApi();
