import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { JobType } from 'external/hr_system/proto/entities_pb';

import jobTypeApi from 'api/jobTypeApi';

interface JobTypesState {
  jobTypesById: Record<number, JobType.AsObject>;
  error?: string;
}

export const fetchJobTypes = createAsyncThunk('jobTypes/fetchAsMap', (force: boolean) =>
  jobTypeApi.listAsCachedMap(force),
);

export const addJobType = createAsyncThunk(
  'jobTypes/add',
  async (name: string): Promise<JobType.AsObject> => {
    const response = await jobTypeApi.add(name);
    return constructJobType(name, response.jobTypeId);
  },
);

export const updateJobType = createAsyncThunk(
  'jobTypes/update',
  async (params: { name: string; id: number }) => {
    const response = await jobTypeApi.update(params);
    return constructJobType(params.name, response.jobTypeId);
  },
);

export const deleteJobType = createAsyncThunk('jobTypes/delete', async (id: number) => {
  await jobTypeApi.deleteOne(id);
  return id;
});

const constructJobType = (name: string, id?: number) => {
  const result = new JobType();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting jobTypeId but it is undefined.');
  }
};

const jobTypes = createSlice({
  name: 'jobTypes',
  initialState: {
    jobTypesById: {},
  } as JobTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobTypes.fulfilled, (state, action) => {
        state.jobTypesById = action.payload;
      })
      .addCase(addJobType.fulfilled, (state, action) => {
        const jobType = action.payload;
        if (jobType.id) {
          state.jobTypesById[jobType.id] = jobType;
        }
        state.error = undefined;
      })
      .addCase(addJobType.rejected, (state, action) => {
        state.error = `Add job type failed ${action.error?.message}`;
      })
      .addCase(updateJobType.fulfilled, (state, action) => {
        const jobType = action.payload;
        if (jobType.id) {
          state.jobTypesById[jobType.id] = jobType;
        }
        state.error = undefined;
      })
      .addCase(updateJobType.rejected, (state, action) => {
        state.error = `Update job type failed ${action.error?.message}`;
      })
      .addCase(deleteJobType.fulfilled, (state, action) => {
        delete state.jobTypesById[action.payload];
        state.error = undefined;
      })
      .addCase(deleteJobType.rejected, (state, action) => {
        state.error = `Delete job type failed ${action.error?.message}`;
      });
  },
});

export default jobTypes.reducer;
