import React, { useState } from 'react';
import { useEffect } from 'react';
import { Publish } from '@material-ui/icons';
import clsx from 'clsx';
import { throttle } from 'lodash';

import useStyle from './style';

export type BackTopButtonProps = {};

const BackTopButton: React.FC<BackTopButtonProps> = () => {
  const classes = useStyle();
  const [display, setDisplay] = useState<boolean>(false);

  const handler = throttle(() => setDisplay(window.scrollY > 300), 200);

  useEffect(() => {
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [handler]);

  return (
    <div
      className={clsx(classes.root, { display })}
      title="Back to Top"
      onClick={(event) => {
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      <Publish />
    </div>
  );
};

export default BackTopButton;
