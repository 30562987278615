import { createSelector } from 'reselect';

import { AppStateType } from 'store';

export const selectPositionIds = createSelector(
  [(state: AppStateType) => state.entities.positions.positionsById],
  (positionsById) => Object.keys(positionsById).map((v) => Number(v)),
);

export const selectBusinessUnitIds = createSelector(
  [(state: AppStateType) => state.entities.businessUnits.businessUnitsById],
  (businessUnitsById) => Object.keys(businessUnitsById).map((v) => Number(v)),
);

export const selectCostCenterIds = createSelector(
  [(state: AppStateType) => state.entities.costCenters.costCentersById],
  (costCentersById) => Object.keys(costCentersById).map((v) => Number(v)),
);

export const selectDepartmentIds = createSelector(
  [(state: AppStateType) => state.entities.departments.departmentsById],
  (departmentsById) => Object.keys(departmentsById).map((v) => Number(v)),
);

export const selectEmploymentTypeIds = createSelector(
  [(state: AppStateType) => state.entities.employmentTypes.employmentTypesById],
  (employmentTypesById) => Object.keys(employmentTypesById).map((v) => Number(v)),
);

export const selectJobFunctionIds = createSelector(
  [(state: AppStateType) => state.entities.jobFunctions.jobFunctionsById],
  (jobFunctionsById) => Object.keys(jobFunctionsById).map((v) => Number(v)),
);

export const selectJobTypeIds = createSelector(
  [(state: AppStateType) => state.entities.jobTypes.jobTypesById],
  (jobTypesById) => Object.keys(jobTypesById).map((v) => Number(v)),
);

export const selectSiteIds = createSelector(
  [(state: AppStateType) => state.entities.sites.sitesById],
  (sitesById) => Object.keys(sitesById).map((v) => Number(v)),
);

export const selectTeamIds = createSelector(
  [(state: AppStateType) => state.entities.teams.teamsById],
  (teamsById) => Object.keys(teamsById).map((v) => Number(v)),
);
