import {
  CreateOrUpdateReviewCycleRequest,
  CreateOrUpdateReviewCycleResponse,
  GetReviewCyclesResponse,
} from 'external/hr_system/proto/perf_tool_api_pb';

import { getBufferArrayFromResponse, toClientJson, toServerJson } from 'common/utils/json';
import BaseApi from './baseApi';
import { ReviewCycle } from '../external/hr_system/proto/review_cycle_pb';

class ReviewCyclesApi extends BaseApi {
  private endpointPrefix = '/perf/review_cycles';

  public async fetchReviewCycles(): Promise<GetReviewCyclesResponse.AsObject> {
    const response = await this.get(this.endpointPrefix);
    const buffer = await getBufferArrayFromResponse(response);
    return GetReviewCyclesResponse.deserializeBinary(buffer).toObject();
  }

  public async createReviewCycle(
    reviewCycle: ReviewCycle.AsObject,
  ): Promise<CreateOrUpdateReviewCycleResponse.AsObject> {
    const request: CreateOrUpdateReviewCycleRequest.AsObject = {
      reviewCycle,
    };
    const response = await this.post(this.endpointPrefix, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async updateReviewCycle(
    reviewCycle: ReviewCycle.AsObject,
  ): Promise<CreateOrUpdateReviewCycleResponse.AsObject> {
    const request: CreateOrUpdateReviewCycleRequest.AsObject = {
      reviewCycle,
    };
    const response = await this.put(this.endpointPrefix, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async deleteReviewCycle(
    reviewCycle: ReviewCycle.AsObject,
  ): Promise<CreateOrUpdateReviewCycleResponse.AsObject> {
    const request: CreateOrUpdateReviewCycleRequest.AsObject = {
      reviewCycle,
    };
    const response = await this.delete(this.endpointPrefix, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async getCurrentReviewCycle(): Promise<GetReviewCyclesResponse.AsObject> {
    const response = await this.get(this.endpointPrefix + '/current');
    const buffer = await getBufferArrayFromResponse(response);
    return GetReviewCyclesResponse.deserializeBinary(buffer).toObject();
  }
}

const reviewCyclesApi = new ReviewCyclesApi();
export default reviewCyclesApi;
