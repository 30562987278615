import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CssBaseline, Hidden, Snackbar, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import Alert from '@material-ui/lab/Alert';
import styled, { createGlobalStyle } from 'styled-components';

import { markPageStart } from 'common/utils/monitor';
import BackTopButton from 'components/BackTopButton';
import Settings from 'components/Settings';
import Sidebar from 'components/Sidebar';
import { StyledPaper } from 'components/styledComponents';
import { AppStateType } from 'store';
import { setSnackbarDisplayed } from 'store/snackbarContent';
import { GlobalStyleProps, RouteInfoType } from 'types/types';

const drawerWidth = 260 as const;
const defaultAutoHideDuration = 3000 as const;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled(StyledPaper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardPropsType = {
  routes: Array<RouteInfoType>;
  width: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
};

const CustomizedSnackbar: React.FC<{}> = () => {
  const {
    currentContent: content,
    closeEvent,
    displayed,
  } = useSelector((state: AppStateType) => state.snackbarContent);
  const dispatch = useDispatch();
  const [delayDisplayTimeout, setDelayDisplayTimeout] = useState<number | undefined>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (closeEvent?.content === content?.content) {
      setOpen(false);
      delayDisplayTimeout && clearTimeout(delayDisplayTimeout);
      setDelayDisplayTimeout(undefined);
    }
  }, [closeEvent, content, delayDisplayTimeout]);

  useEffect(() => {
    if (content && !displayed) {
      dispatch(setSnackbarDisplayed(true));
      if (content?.delay) {
        setDelayDisplayTimeout(window.setTimeout(() => setOpen(true), content.delay));
      } else {
        setOpen(true);
        setDelayDisplayTimeout(undefined);
      }
    }
  }, [content, dispatch, displayed]);

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={content?.type === 'error' ? null : defaultAutoHideDuration}
        onClose={handleClose}
      >
        {content !== undefined ? (
          <Alert elevation={6} variant="filled" onClose={handleClose} severity={content?.type}>
            {content?.content}
          </Alert>
        ) : undefined}
      </Snackbar>
    </div>
  );
};

const Dashboard: React.FC<DashboardPropsType> = ({ children, routes, width }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.startsWith('/performance/calibration')) {
      markPageStart('/performance/calibration', 'load');
    } else if (pathname.startsWith('/performance/overview')) {
      markPageStart('/performance/overview', 'load');
    }
  }, [pathname]);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar routes={routes} PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </Drawer>
      <AppContent>
        <MainContent p={isWidthUp('lg', width) ? 10 : 5}>{children}</MainContent>
      </AppContent>
      <Settings />
      <CustomizedSnackbar />
      <BackTopButton />
    </Root>
  );
};

export default withWidth()(Dashboard);
