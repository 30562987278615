import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReviewCycle } from 'external/hr_system/proto/review_cycle_pb';

import { mapBy } from 'common/utils/mapData';
import reviewCyclesApi from '../../api/reviewCyclesApi';

interface EmployeeMapState {
  reviewCyclesById: Record<number, ReviewCycle.AsObject>;
  loaded: boolean;
  filteredIds: number[];
}

const initialState: EmployeeMapState = {
  loaded: false,
  reviewCyclesById: {},
  filteredIds: [],
};

export const fetchReviewCycles = createAsyncThunk('reviewCycles/fetchAsMap', async (): Promise<
  Record<number, ReviewCycle.AsObject>
> => {
  // return { 233: { name: '2020-09', stage: ReviewStage.Enum.CALIBRATION, stagesTimelineList: [], startDate: '2020-01-01', id: 233 } };
  const response = await reviewCyclesApi.fetchReviewCycles();
  return mapBy(response.reviewCyclesList, 'id');
});

const reviewCycleMap = createSlice({
  name: 'reviewCycleMap',
  initialState,
  reducers: {
    filteredIdsChanged(state, action: PayloadAction<number[]>) {
      state.filteredIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReviewCycles.fulfilled, (state, action) => {
      state.reviewCyclesById = action.payload;
      state.loaded = true;
    });
  },
});

export const { filteredIdsChanged } = reviewCycleMap.actions;

export default reviewCycleMap.reducer;
