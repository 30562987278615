import { JsonAbleData } from 'common/types';

export const getJsonObjectFromStorage = (storage: Storage, key: string) => {
  const value = storage.getItem(key);
  if (value && value.length > 0) {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.error(`error occurs when parse JSON string ${value}:`, error);
    }
  }
  return null;
};

export const setJsonObjectToStorage = (storage: Storage, key: string, value: JsonAbleData) => {
  storage.setItem(key, JSON.stringify(value));
};
