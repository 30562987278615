import { Team } from 'external/hr_system/proto/entities_pb';
import {
  CreateOrUpdateTeamRequest,
  CreateOrUpdateTeamResponse,
  DeleteResponse,
  GetTeamsResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';

class TeamApi extends BaseApi {
  private endpointPrefix = '/teams';
  private idBasedMapCacher: IdBasedMapCacher<Team.AsObject, GetTeamsResponse.AsObject>;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetTeamsResponse.AsObject>(() => this.list());
    this.idBasedMapCacher = new IdBasedMapCacher<Team.AsObject, GetTeamsResponse.AsObject>(
      apiCacher,
      (response) => response.teamsList,
    );
  }

  public async listAsCachedMap(force = false): Promise<Record<number, Team.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async list(): Promise<GetTeamsResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetTeamsResponse.toObject(false, GetTeamsResponse.deserializeBinary(buffer));
  }

  public async add(name: string): Promise<CreateOrUpdateTeamResponse.AsObject> {
    const request = new CreateOrUpdateTeamRequest();
    const team = new Team();
    team.setName(name);
    request.setTeam(team);

    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateTeamResponse.toObject(
      false,
      CreateOrUpdateTeamResponse.deserializeBinary(buffer),
    );
  }

  public async update({
    name,
    id,
  }: {
    name: string;
    id: number;
  }): Promise<CreateOrUpdateTeamResponse.AsObject> {
    const request = new CreateOrUpdateTeamRequest();
    const team = new Team();
    team.setName(name);
    team.setId(id);
    request.setTeam(team);

    const response = await this.put(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateTeamResponse.toObject(
      false,
      CreateOrUpdateTeamResponse.deserializeBinary(buffer),
    );
  }

  public async deleteOne(id: number): Promise<DeleteResponse.AsObject> {
    const response = await super.delete(this.endpointPrefix + '/' + id, {});
    const buffer = await getBufferArrayFromResponse(response);
    return DeleteResponse.toObject(false, DeleteResponse.deserializeBinary(buffer));
  }
}

export default new TeamApi();
