import {
  CreateOrUpdatePerfRecordsRequest,
  CreateOrUpdatePerfRecordsResponse,
  ListPerfRecordsResponse,
} from 'external/hr_system/proto/perf_tool_api_pb';

import { getBufferArrayFromResponse, toClientJson, toServerJson } from 'common/utils/json';
import BaseApi from './baseApi';

class PerfRecordsApi extends BaseApi {
  private endpointPrefix = '/perf/records';

  public async createOrUpdatePerfRecordsJson(
    request: CreateOrUpdatePerfRecordsRequest.AsObject,
  ): Promise<CreateOrUpdatePerfRecordsResponse.AsObject> {
    const response = await this.post(this.endpointPrefix, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async getPerfRecords(params?: {
    reviewCycleId?: number;
    userId?: number;
  }): Promise<ListPerfRecordsResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, toServerJson(params));
    const buffer = await getBufferArrayFromResponse(response);
    return ListPerfRecordsResponse.toObject(
      false,
      ListPerfRecordsResponse.deserializeBinary(buffer),
    );
  }

  public async getPerfRecordsByUserIds(
    reviewCycleId: number,
    userIds: number[],
  ): Promise<ListPerfRecordsResponse.AsObject> {
    const response = await this.get(
      this.endpointPrefix,
      toServerJson({ reviewCycleId, userIds: userIds.join(',') }),
    );
    const buffer = await getBufferArrayFromResponse(response);
    return ListPerfRecordsResponse.toObject(
      false,
      ListPerfRecordsResponse.deserializeBinary(buffer),
    );
  }
}

const perfRecordsApi = new PerfRecordsApi();
export default perfRecordsApi;
