import { JobType } from 'external/hr_system/proto/entities_pb';
import {
  CreateOrUpdateJobTypeRequest,
  CreateOrUpdateJobTypeResponse,
  DeleteResponse,
  GetJobTypesResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';

class JobTypeApi extends BaseApi {
  private endpointPrefix = '/job_types';
  private idBasedMapCacher: IdBasedMapCacher<JobType.AsObject, GetJobTypesResponse.AsObject>;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetJobTypesResponse.AsObject>(() => this.list());
    this.idBasedMapCacher = new IdBasedMapCacher<JobType.AsObject, GetJobTypesResponse.AsObject>(
      apiCacher,
      (response) => response.jobTypesList,
    );
  }

  public async listAsCachedMap(force = false): Promise<Record<number, JobType.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async list(): Promise<GetJobTypesResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetJobTypesResponse.toObject(false, GetJobTypesResponse.deserializeBinary(buffer));
  }

  public async add(name: string): Promise<CreateOrUpdateJobTypeResponse.AsObject> {
    const request = new CreateOrUpdateJobTypeRequest();
    const jobType = new JobType();
    jobType.setName(name);
    request.setJobType(jobType);

    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateJobTypeResponse.toObject(
      false,
      CreateOrUpdateJobTypeResponse.deserializeBinary(buffer),
    );
  }

  public async update({
    name,
    id,
  }: {
    name: string;
    id: number;
  }): Promise<CreateOrUpdateJobTypeResponse.AsObject> {
    const request = new CreateOrUpdateJobTypeRequest();
    const jobType = new JobType();
    jobType.setName(name);
    jobType.setId(id);
    request.setJobType(jobType);

    const response = await this.put(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateJobTypeResponse.toObject(
      false,
      CreateOrUpdateJobTypeResponse.deserializeBinary(buffer),
    );
  }

  public async deleteOne(id: number): Promise<DeleteResponse.AsObject> {
    const response = await super.delete(this.endpointPrefix + '/' + id, {});
    const buffer = await getBufferArrayFromResponse(response);
    return DeleteResponse.toObject(false, DeleteResponse.deserializeBinary(buffer));
  }
}

export default new JobTypeApi();
