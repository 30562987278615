import {
  GetUserOrgChartsRequest,
  GetUserOrgChartsResponse,
  ReportRelation,
} from 'external/hr_system/proto/org_chart_api_pb';
import { RelationType, ReportType } from 'external/hr_system/proto/report_type_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import BaseApi from './baseApi';

class OrgChartApi extends BaseApi {
  private endpointPrefix = '/org_charts';

  public async fetchUserOrgCharts(
    params?: GetUserOrgChartsRequest.AsObject,
  ): Promise<GetUserOrgChartsResponse.AsObject> {
    const request = new GetUserOrgChartsRequest();
    if (params) {
      params.baseUserId && request.setBaseUserId(params.baseUserId.toString());
      params.reportRelationsList &&
        request.setReportRelationsList(
          params.reportRelationsList.map((r) => {
            const relation = new ReportRelation();
            relation.setIsDirect(r.isDirect || false);
            relation.setRelationType(r.relationType || RelationType.Enum.UNKNOWN);
            relation.setReportType(r.reportType || ReportType.Enum.UNKNOWN);
            return relation;
          }),
        );
    }
    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    const buffer = await getBufferArrayFromResponse(response);
    return GetUserOrgChartsResponse.toObject(
      false,
      GetUserOrgChartsResponse.deserializeBinary(buffer),
    );
  }
}

export default new OrgChartApi();
