import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';

import MigrationNotice from 'components/MigrationNotice';
import Routes from 'routes/Routes';
import { AppStateType } from 'store';
import { ThemeState } from 'store/theme';
import maTheme from 'theme';

function App({ theme }: { theme: ThemeState }) {
  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | HR System" defaultTitle="HR System - Pony.ai" />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <MigrationNotice />
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default connect((store: AppStateType) => ({ theme: store.theme }))(App);
