import React from 'react';
import { UserCheck } from 'react-feather';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BarChartIcon from '@material-ui/icons/BarChart';
import BuildIcon from '@material-ui/icons/Build';

import async from '../components/Async';

// Auth components
const Welcome = async(() => import('../pages/auth/Welcome'));

// Org-Chart
const TreeView = async(() => import('../pages/org-chart/tree-view/TreeView'));
const EmployeeList = async(() => import('../pages/org-chart/EmployeeList'));
const EmployeeDetails = async(() => import('../pages/org-chart/EmployeeDetails'));
const OrgChartAdmin = async(() => import('../pages/org-chart/admin/OrgChartAdmin'));

// Org-Chart Admin Pages
const AuditList = async(() => import('../pages/org-chart/admin/AuditList'));
const BusinessUnitList = async(() => import('../pages/org-chart/admin/BusinessUnitList'));
const CostCenterList = async(() => import('../pages/org-chart/admin/CostCenterList'));
const DepartmentList = async(() => import('../pages/org-chart/admin/DepartmentList'));
const EmploymentTypeList = async(() => import('../pages/org-chart/admin/EmploymentTypeList'));
const JobFunctionList = async(() => import('../pages/org-chart/admin/JobFunctionList'));
const JobTypeList = async(() => import('../pages/org-chart/admin/JobTypeList'));
const SiteList = async(() => import('../pages/org-chart/admin/SiteList'));
const TeamList = async(() => import('../pages/org-chart/admin/TeamList'));
const PositionList = async(() => import('../pages/org-chart/admin/PositionList'));
const PermissionList = async(() => import('../pages/org-chart/admin/PermissionList'));

// Performance pages
const ReviewCycleList = async(() => import('../pages/performance/reviewCycle/ReviewCycleList'));
const AnalyticsPage = async(() => import('../pages/performance/analytics'));
const PromoNomineeList = async(() => import('../pages/performance/promotion/NomineeList'));
const SelfAssessmentPage = async(
  () => import('../pages/performance/assessment/SelfAssessmentPage'),
);
const ViewOthersAssessmentPage = async(
  () => import('../pages/performance/assessment/ViewOtherAssessmentPage'),
);
const PeerReviewPage = async(() => import('../pages/performance/assessment/PeerReviewPage'));
const ManagerReviewPage = async(() => import('../pages/performance/assessment/ManagerReviewPage'));
const InvitedManagerReviewPage = async(
  () => import('../pages/performance/assessment/InvitedManagerReviewPage'),
);
const PanelResolutionPage = async(() => import('../pages/performance/promotion/PanelResolution'));
const OverviewPage = async(() => import('../pages/performance/assessment/OverviewPage'));

const PDFTest = async(() => import('../pages/test/pdf/index'));

// HC Planning pages
const HcDetails = async(() => import('../pages/hc-planning/HcDetails'));
const HcList = async(() => import('../pages/hc-planning/HcList'));

const invalidRoutes = {
  id: 'Invalid',
  path: '*',
  component: Welcome,
  children: null,
};

const welcomeRoutes = {
  id: 'Welcome',
  path: '/welcome',
  component: Welcome,
  children: null,
};

const organizationRoutes = {
  id: 'Organization',
  path: '/organization',
  icon: <AccountTreeIcon />,
  children: [
    {
      path: '/organization/tree-view',
      name: 'Org-Chart',
      component: TreeView,
    },
    {
      path: '/organization/employees',
      name: 'Employees',
      component: EmployeeList,
    },
    {
      path: '/organization/employees/:id',
      name: 'Employee Details',
      component: EmployeeDetails,
      hidden: true,
    },
  ],
  component: null,
};

const performanceRoutes = {
  id: 'Performance',
  path: '/performance',
  icon: <UserCheck />,
  children: [
    {
      path: '/performance/overview',
      name: 'Current Review Cycle',
      component: OverviewPage,
    },
    {
      path: '/performance/overview/reviewCycle=:reviewCycleId',
      name: 'Current Review Cycle',
      component: OverviewPage,
      hidden: true,
    },
    {
      path: '/performance/overview/companyEmail=:companyEmail/',
      name: 'Current Review Cycle',
      component: OverviewPage,
      hidden: true,
    },
    {
      path: '/performance/assessment',
      name: 'Assessment & Review',
      component: SelfAssessmentPage,
      hidden: true,
    },
    {
      path: '/performance/assessment/reviewCycle=:reviewCycleId',
      name: 'Assessment & Review',
      component: SelfAssessmentPage,
      hidden: true,
    },
    {
      path: '/performance/calibration',
      name: 'Calibration',
      component: AnalyticsPage,
    },
    {
      path: '/performance/promo-nominee',
      name: 'Promo Nominee',
      component: PromoNomineeList,
    },
    {
      path: '/performance/review-cycles',
      name: 'All Review Cycles',
      component: ReviewCycleList,
    },
    {
      path: '/performance/peerReview/:revieweeId',
      name: 'Peer review',
      component: PeerReviewPage,
      hidden: true,
    },
    {
      path: '/performance/peerReview/:revieweeId/reviewCycle=:reviewCycleId',
      name: 'Peer review',
      component: PeerReviewPage,
      hidden: true,
    },
    {
      path: '/performance/managerReview/:revieweeId',
      name: 'Manager review',
      component: ManagerReviewPage,
      hidden: true,
    },
    {
      path: '/performance/managerReview/:revieweeId/reviewCycle=:reviewCycleId',
      name: 'Manager review',
      component: ManagerReviewPage,
      hidden: true,
    },
    {
      path: '/performance/invitedManagerReview/:revieweeId',
      name: 'Invited manager review',
      component: InvitedManagerReviewPage,
      hidden: true,
    },
    {
      path: '/performance/invitedManagerReview/:revieweeId/reviewCycle=:reviewCycleId',
      name: 'Invited manager review',
      component: InvitedManagerReviewPage,
      hidden: true,
    },
    {
      path: '/performance/panelResolution/:revieweeId/reviewCycle=:reviewCycleId',
      name: 'Panel resolution',
      component: PanelResolutionPage,
      hidden: true,
    },
    {
      path: '/performance/assessment/:revieweeId/reviewCycle=:reviewCycleId',
      name: 'Assessment & Review',
      component: ViewOthersAssessmentPage,
      hidden: true,
    },
  ],
  component: null,
};

const administrationRoutes = {
  id: 'Administration',
  path: '/administration',
  icon: <BuildIcon />,
  children: [
    {
      path: '/administration/tool-configurations',
      name: 'Tool Configurations',
      component: OrgChartAdmin,
    },
    {
      path: '/administration/tool-configurations/departments',
      name: 'Departments',
      component: DepartmentList,
      hidden: true,
    },
    {
      path: '/administration/tool-configurations/employment-types',
      name: 'Employment Types',
      component: EmploymentTypeList,
      hidden: true,
    },
    {
      path: '/administration/tool-configurations/job-functions',
      name: 'Job Functions',
      component: JobFunctionList,
      hidden: true,
    },
    {
      path: '/administration/tool-configurations/job-types',
      name: 'Job Types',
      component: JobTypeList,
      hidden: true,
    },
    {
      path: '/administration/tool-configurations/sites',
      name: 'Sites',
      component: SiteList,
      hidden: true,
    },
    {
      path: '/administration/tool-configurations/teams',
      name: 'Teams',
      component: TeamList,
      hidden: true,
    },
    {
      path: '/administration/tool-configurations/positions',
      name: 'Postions',
      component: PositionList,
      hidden: true,
    },
    {
      path: '/administration/tool-configurations/business-units',
      name: 'Business Units',
      component: BusinessUnitList,
      hidden: true,
    },
    {
      path: '/administration/tool-configurations/cost-centers',
      name: 'Cost Centers',
      component: CostCenterList,
      hidden: true,
    },
    {
      path: '/administration/permissions',
      name: 'Permissions',
      component: PermissionList,
    },
    {
      path: '/administration/edit-histories',
      name: 'Edit Histories',
      component: AuditList,
    },
  ],
  component: null,
};

const pdfTestRoute = {
  id: 'pdf',
  path: '/pdf',
  icon: <BuildIcon />,
  component: PDFTest,
  children: null,
};

const hcPlanningRoutes = {
  id: 'HC Planning',
  path: '/hc-planning',
  icon: <BarChartIcon />,
  children: [
    {
      path: '/hc-planning/overview/:id',
      name: 'HC Details',
      component: HcDetails,
      hidden: true,
    },
    {
      path: '/hc-planning/overview',
      name: 'Overview',
      component: HcList,
      hidden: false,
    },
  ],
  component: null,
};

const prodEnv = process.env.REACT_APP_ENV === 'production';

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  organizationRoutes,
  performanceRoutes,
  administrationRoutes,
  hcPlanningRoutes,
  ...((!prodEnv && [pdfTestRoute]) || []),
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  organizationRoutes,
  performanceRoutes,
  administrationRoutes,
  hcPlanningRoutes,
];

// Routes without authenticate
export const authLayoutRoutes = [invalidRoutes, welcomeRoutes];
