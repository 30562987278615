// source: proto/review_question.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

import * as proto_review_type_pb from 'hr_web/proto/review_type_pb';
goog.object.extend(proto, proto_review_type_pb);
import * as proto_user_role_pb from 'hr_web/proto/user_role_pb';
goog.object.extend(proto, proto_user_role_pb);
goog.exportSymbol('proto.hr_system.ReviewQuestion', null, global);
goog.exportSymbol('proto.hr_system.ReviewQuestion.QuestionFunction', null, global);
goog.exportSymbol('proto.hr_system.ReviewQuestion.QuestionType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ReviewQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.ReviewQuestion.repeatedFields_, null);
};
goog.inherits(proto.hr_system.ReviewQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ReviewQuestion.displayName = 'proto.hr_system.ReviewQuestion';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.ReviewQuestion.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ReviewQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ReviewQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ReviewQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reviewCycleId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    question: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    reviewType: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    questionFunction: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    questionType: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    ratingOptions: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    questionIndex: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    visibleUserRolesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ReviewQuestion}
 */
proto.hr_system.ReviewQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ReviewQuestion;
  return proto.hr_system.ReviewQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ReviewQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ReviewQuestion}
 */
proto.hr_system.ReviewQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = /** @type {!proto.hr_system.ReviewType.Enum} */ (reader.readEnum());
      msg.setReviewType(value);
      break;
    case 9:
      var value = /** @type {!proto.hr_system.ReviewQuestion.QuestionFunction} */ (reader.readEnum());
      msg.setQuestionFunction(value);
      break;
    case 5:
      var value = /** @type {!proto.hr_system.ReviewQuestion.QuestionType} */ (reader.readEnum());
      msg.setQuestionType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRatingOptions(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionIndex(value);
      break;
    case 8:
      var value = /** @type {!proto.hr_system.UserRole.Enum} */ (reader.readEnum());
      msg.addVisibleUserRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ReviewQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ReviewQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ReviewQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewQuestion.QuestionFunction} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewQuestion.QuestionType} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getVisibleUserRolesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.hr_system.ReviewQuestion.QuestionType = {
  DEFAULT: 0,
  OVERALL: 1
};

/**
 * @enum {number}
 */
proto.hr_system.ReviewQuestion.QuestionFunction = {
  UNKNOWN: 0,
  PERFORMANCE: 1,
  PROMOTION: 2
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.ReviewQuestion.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewQuestion.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 review_cycle_id = 2;
 * @return {number}
 */
proto.hr_system.ReviewQuestion.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewQuestion.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.hr_system.ReviewQuestion.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setQuestion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearQuestion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewQuestion.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReviewType.Enum review_type = 4;
 * @return {!proto.hr_system.ReviewType.Enum}
 */
proto.hr_system.ReviewQuestion.prototype.getReviewType = function() {
  return /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.hr_system.ReviewType.Enum} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setReviewType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearReviewType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewQuestion.prototype.hasReviewType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional QuestionFunction question_function = 9;
 * @return {!proto.hr_system.ReviewQuestion.QuestionFunction}
 */
proto.hr_system.ReviewQuestion.prototype.getQuestionFunction = function() {
  return /** @type {!proto.hr_system.ReviewQuestion.QuestionFunction} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.hr_system.ReviewQuestion.QuestionFunction} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setQuestionFunction = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearQuestionFunction = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewQuestion.prototype.hasQuestionFunction = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional QuestionType question_type = 5;
 * @return {!proto.hr_system.ReviewQuestion.QuestionType}
 */
proto.hr_system.ReviewQuestion.prototype.getQuestionType = function() {
  return /** @type {!proto.hr_system.ReviewQuestion.QuestionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.hr_system.ReviewQuestion.QuestionType} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setQuestionType = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearQuestionType = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewQuestion.prototype.hasQuestionType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string rating_options = 6;
 * @return {string}
 */
proto.hr_system.ReviewQuestion.prototype.getRatingOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setRatingOptions = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearRatingOptions = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewQuestion.prototype.hasRatingOptions = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 question_index = 7;
 * @return {number}
 */
proto.hr_system.ReviewQuestion.prototype.getQuestionIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setQuestionIndex = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearQuestionIndex = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewQuestion.prototype.hasQuestionIndex = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated UserRole.Enum visible_user_roles = 8;
 * @return {!Array<!proto.hr_system.UserRole.Enum>}
 */
proto.hr_system.ReviewQuestion.prototype.getVisibleUserRolesList = function() {
  return /** @type {!Array<!proto.hr_system.UserRole.Enum>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.hr_system.UserRole.Enum>} value
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.setVisibleUserRolesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.hr_system.UserRole.Enum} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.addVisibleUserRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.ReviewQuestion} returns this
 */
proto.hr_system.ReviewQuestion.prototype.clearVisibleUserRolesList = function() {
  return this.setVisibleUserRolesList([]);
};


export const { ReviewQuestion } = proto.hr_system
