import { createSelector } from 'reselect';

import { AppStateType } from 'store';

const getReviewCyclesById = (state: AppStateType) =>
  state.reviewCycles.reviewCyclesMap.reviewCyclesById;

export const selectReviewCycleList = createSelector(
  [getReviewCyclesById, (state: AppStateType) => state.reviewCycles.reviewCyclesMap.filteredIds],
  (reviewCyclesById, filteredIds) =>
    filteredIds.length > 0
      ? filteredIds.map((id) => reviewCyclesById[id])
      : Object.values(reviewCyclesById),
);
