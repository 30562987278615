import { Site } from 'external/hr_system/proto/entities_pb';
import {
  CreateOrUpdateSiteRequest,
  CreateOrUpdateSiteResponse,
  DeleteResponse,
  GetSitesResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';

class SiteApi extends BaseApi {
  private endpointPrefix = '/sites';
  private idBasedMapCacher: IdBasedMapCacher<Site.AsObject, GetSitesResponse.AsObject>;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetSitesResponse.AsObject>(() => this.list());
    this.idBasedMapCacher = new IdBasedMapCacher<Site.AsObject, GetSitesResponse.AsObject>(
      apiCacher,
      (response) => response.sitesList,
    );
  }

  public async listAsCachedMap(force = false): Promise<Record<number, Site.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async list(): Promise<GetSitesResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetSitesResponse.toObject(false, GetSitesResponse.deserializeBinary(buffer));
  }

  public async add(name: string): Promise<CreateOrUpdateSiteResponse.AsObject> {
    const request = new CreateOrUpdateSiteRequest();
    const site = new Site();
    site.setName(name);
    request.setSite(site);

    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateSiteResponse.toObject(
      false,
      CreateOrUpdateSiteResponse.deserializeBinary(buffer),
    );
  }

  public async update({
    name,
    id,
  }: {
    name: string;
    id: number;
  }): Promise<CreateOrUpdateSiteResponse.AsObject> {
    const request = new CreateOrUpdateSiteRequest();
    const site = new Site();
    site.setName(name);
    site.setId(id);
    request.setSite(site);

    const response = await this.put(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateSiteResponse.toObject(
      false,
      CreateOrUpdateSiteResponse.deserializeBinary(buffer),
    );
  }

  public async deleteOne(id: number): Promise<DeleteResponse.AsObject> {
    const response = await super.delete(this.endpointPrefix + '/' + id, {});
    const buffer = await getBufferArrayFromResponse(response);
    return DeleteResponse.toObject(false, DeleteResponse.deserializeBinary(buffer));
  }
}

export default new SiteApi();
