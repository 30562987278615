import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Department } from 'external/hr_system/proto/entities_pb';

import departmentApi from 'api/departmentApi';

interface DepartmentsState {
  departmentsById: Record<number, Department.AsObject>;
  error?: string;
}

export const fetchDepartments = createAsyncThunk('departments/fetchAsMap', (force: boolean) =>
  departmentApi.listAsCachedMap(force),
);

export const addDepartment = createAsyncThunk(
  'departments/add',
  async (name: string): Promise<Department.AsObject> => {
    const response = await departmentApi.add(name);
    return constructDepartment(name, response.departmentId);
  },
);

export const updateDepartment = createAsyncThunk(
  'departments/update',
  async (params: { name: string; id: number; parentId?: number }) => {
    const response = await departmentApi.update(params);
    return constructDepartment(params.name, response.departmentId);
  },
);

export const deleteDepartment = createAsyncThunk('departments/delete', async (id: number) => {
  await departmentApi.deleteOne(id);
  return id;
});

const constructDepartment = (name: string, id?: number) => {
  const result = new Department();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting departmentId but it is undefined.');
  }
};

const departments = createSlice({
  name: 'departments',
  initialState: {
    departmentsById: {},
  } as DepartmentsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.departmentsById = action.payload;
      })
      .addCase(addDepartment.fulfilled, (state, action) => {
        const department = action.payload;
        if (department.id) {
          state.departmentsById[department.id] = department;
        }
      })
      .addCase(addDepartment.rejected, (state, action) => {
        state.error = `Add department failed ${action.error?.message}`;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        const department = action.payload;
        if (department.id) {
          state.departmentsById[department.id] = department;
        }
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.error = `Update department failed ${action.error?.message}`;
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        delete state.departmentsById[action.payload];
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.error = `Delete department failed ${action.error?.message}`;
      });
  },
});

export default departments.reducer;
