import { RelatedUserNode, UserNode } from 'external/hr_system/proto/org_chart_api_pb';
import { GetUserOrgChartsResponse } from 'external/hr_system/proto/org_chart_api_pb';
import { RelationType, ReportType } from 'external/hr_system/proto/report_type_pb';

import { ManageLine, UserChartNode, UserInfo } from './userCharts';

export const updateChart = (
  response: Required<GetUserOrgChartsResponse.AsObject>,
  chartsById: Record<number, UserChartNode>,
): Record<number, UserChartNode> => {
  const { baseUser, relatedUserNodesList } = response;
  if (!isUserInfo(baseUser)) {
    return chartsById;
  }
  const id = baseUser.userId;

  const baseNode = getOrInitChartNode(id, baseUser, chartsById);
  const directReportsList = reduceRelatedUserNodesToUserNodes(
    relatedUserNodesList,
    (node) =>
      node.reportRelation?.reportType === ReportType.Enum.DIRECT &&
      node.reportRelation.relationType === RelationType.Enum.REPORT,
  );
  const directManagersList = reduceRelatedUserNodesToUserNodes(
    relatedUserNodesList,
    (node) =>
      node.reportRelation?.reportType === ReportType.Enum.DIRECT &&
      node.reportRelation.relationType === RelationType.Enum.MANAGER,
  );

  const reportersById = mapByUserId(directReportsList);
  baseUser.directReportUserIdsList.forEach((id) => {
    getOrInitChartNode(id, reportersById[id], chartsById);
    if (!reportersById[id]) {
      console.warn(`Miss UserNode for employee with id ${id}`);
    }
  });
  baseNode.reporters = baseUser.directReportUserIdsList
    .map((id) => chartsById[id])
    .filter((reporter) => !!reporter.baseInfo);

  const managersById = mapByUserId(directManagersList);
  const { managers: mainManagers, otherManagerLine } = updateManageLine(
    id,
    managersById,
    chartsById,
  );
  chartsById[id].managers = mainManagers;
  chartsById[id].otherManagerLine = otherManagerLine;

  return chartsById;
};

const updateManageLine = (
  id: number,
  managersById: Record<number, UserInfo>,
  chart: Record<number, UserChartNode>,
): ManageLine => {
  const directManagers = chart[id].baseInfo.directManagerUserIdsList;
  let mainManagers: ManageLine['managers'] = [];
  let otherManagerLine: ManageLine['otherManagerLine'] = [];
  if (directManagers.length) {
    const managerNodes = directManagers.map((managerId) => {
      const managerNode = getOrInitChartNode(
        managerId,
        managersById[managerId] || { userId: managerId },
        chart,
      );
      if (!managerNode.managers) {
        updateManageLine(managerId, managersById, chart);
      }
      return chart[managerId];
    });

    const [deepestNode, ...leftNodes] = managerNodes.sort((a, b) => {
      return (b.managers?.length || 0) - (a.managers?.length || 0);
    });

    mainManagers = [deepestNode.id, ...(deepestNode.managers as number[])];
    otherManagerLine = leftNodes
      .reduce((acc, cur) => {
        const mainWayIndex = mainManagers.findIndex((id) => id === cur.id);
        if (mainWayIndex > -1) {
          acc.push([id, ...mainManagers.slice(0, mainWayIndex + 1)]);
        } else {
          acc.push([id, cur.id]);
        }
        return acc;
      }, [] as Array<number[]>)
      .concat(deepestNode.otherManagerLine as Array<number[]>);
  }

  chart[id].managers = mainManagers;
  chart[id].otherManagerLine = otherManagerLine;
  return { managers: mainManagers, otherManagerLine };
};

const getOrInitChartNode = (
  id: number,
  userNode: UserInfo,
  chart: Record<number, UserChartNode>,
): UserChartNode => {
  if (!chart[id]) {
    chart[id] = { id, baseInfo: userNode };
  } else {
    chart[id].baseInfo = userNode;
  }
  return chart[id];
};

const mapByUserId = (arr: UserNode.AsObject[]): Record<number, UserInfo> => {
  return arr.reduce((cur, acc) => {
    if (isUserInfo(acc)) {
      cur[acc.userId] = acc;
    }
    return cur;
  }, {} as Record<number, UserInfo>);
};

const isUserInfo = (o: UserNode.AsObject): o is UserInfo => {
  return o.userId !== undefined;
};

const reduceRelatedUserNodesToUserNodes = (
  list: RelatedUserNode.AsObject[],
  filter: (node: RelatedUserNode.AsObject) => boolean,
): UserNode.AsObject[] => {
  return list
    .filter(filter)
    .map((node) => node.userNodesList)
    .reduce((acc, cur) => acc.concat(cur), []);
};
