import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  IconButton as MuiIconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreVert } from '@material-ui/icons';
import styled from 'styled-components';

import { AppStateType } from 'store';
import { logout, refreshLogin } from 'store/auth/index';

const useStyle = makeStyles({
  root: {
    height: 72,
    padding: 12,
    flexShrink: 0,
    background: '#1B2430',
    borderRadius: 0,
    borderBottom: 'solid 1px rgba(255,255,255,0.1)',
    display: 'flex',
  },
  avatar: {
    background: '#3F51B5',
    color: '#FFFFFF',
    fontSize: 16,
    lineHeight: 1.5,
    margin: 12,
  },
  infoWrapper: {
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  name: {
    fontSize: 16,
    lineHeight: '24px',
  },
  department: {
    color: '#98A3B2',
    fontSize: 12,
    lineHeight: '20px',
  },
  menuBtn: {
    color: '#98A3B2',
    width: 40,
    margin: 'auto',
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
  },
});

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

interface UserCenterProps {}

const UserCenter: React.FunctionComponent<UserCenterProps> = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { session, isPending } = useSelector((state: AppStateType) => state.auth);
  const user = session?.user;

  React.useEffect(() => {
    if (user) {
      const timeout = window.setTimeout(() => dispatch(refreshLogin()), 8 * 60 * 1000); // 8 minutes
      return () => clearTimeout(timeout);
    }
  }, [dispatch, user]);

  if (isPending) {
    return null;
  }

  if (user) {
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Container className={classes.infoWrapper}>
            <Typography className={classes.name}>{user?.email}</Typography>
          </Container>
          <IconButton className={classes.menuBtn} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert />
          </IconButton>
        </Paper>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          keepMounted
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              dispatch(logout());
              setAnchorEl(null);
            }}
          >
            Sign out
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
  return null;
};

export default UserCenter;
