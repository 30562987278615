import { combineReducers } from '@reduxjs/toolkit';

import businessUnits from './businessUnits';
import costCenters from './costCenters';
import departments from './departments';
import employmentTypes from './employmentTypes';
import jobFunctions from './jobFunctions';
import jobTypes from './jobTypes';
import positions from './positions';
import sites from './sites';
import teams from './teams';

const entities = combineReducers({
  businessUnits,
  costCenters,
  departments,
  employmentTypes,
  jobFunctions,
  jobTypes,
  sites,
  teams,
  positions,
});

export default entities;

export * from './selectors';
