import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Drawer, Fab as MuiFab, ListItem, Paper as MuiPaper, Typography } from '@material-ui/core';
import { Palette as PaletteIcon } from '@material-ui/icons';
import { spacing } from '@material-ui/system';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { setTheme } from 'store/theme';

const Paper = styled(MuiPaper)(spacing);

const Demo = styled(Paper)`
  cursor: pointer;
  text-align: center;
  box-shadow: none;
`;

// Temporarily hides the Fab icon for theme settings by changing 'right' to 'left' below.
// TODO(jiangwei): move to a less obstructing place, such as the top-right corner.
const Fab = styled(MuiFab)`
  position: fixed;
  left: ${(props) => props.theme.spacing(8)}px;
  bottom: ${(props) => props.theme.spacing(8)}px;
  z-index: 1;
`;

const Wrapper = styled.div`
  width: 240px;
  overflow-x: hidden;
`;

const Screenshot = styled.img`
  max-width: 100%;
  height: auto;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  display: block;
`;

const Heading = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.palette.common.black};
  background: ${(props) => props.theme.palette.common.white};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`;

type DemosProps = {
  dispatch: Dispatch;
};

const Demos = function ({ dispatch }: DemosProps) {
  return (
    <Wrapper>
      <Heading button={true}>Select a demo</Heading>
      <Demo my={2} mx={4} onClick={() => dispatch(setTheme(0))}>
        <Screenshot alt="Dark" src="/static/img/screenshots/dark-small.png" />
        <Typography variant="subtitle1" gutterBottom>
          Dark
        </Typography>
      </Demo>
      <Demo my={2} mx={4} onClick={() => dispatch(setTheme(1))}>
        <Screenshot alt="Light" src="/static/img/screenshots/light-small.png" />
        <Typography variant="subtitle1" gutterBottom>
          Light
        </Typography>
      </Demo>
      <Demo my={2} mx={4} onClick={() => dispatch(setTheme(2))}>
        <Screenshot alt="Blue" src="/static/img/screenshots/blue-small.png" />
        <Typography variant="subtitle1" gutterBottom>
          Blue
        </Typography>
      </Demo>
      <Demo my={2} mx={4} onClick={() => dispatch(setTheme(3))}>
        <Screenshot alt="Green" src="/static/img/screenshots/green-small.png" />
        <Typography variant="subtitle1" gutterBottom>
          Green
        </Typography>
      </Demo>
      <Demo my={2} mx={4} onClick={() => dispatch(setTheme(4))}>
        <Screenshot alt="Indigo" src="/static/img/screenshots/indigo-small.png" />
        <Typography variant="subtitle1" gutterBottom>
          Indigo
        </Typography>
      </Demo>
      <Demo my={2} mx={4} onClick={() => dispatch(setTheme(5))}>
        <Screenshot alt="Teal" src="/static/img/screenshots/teal-small.png" />
        <Typography variant="subtitle1" gutterBottom>
          Teal
        </Typography>
      </Demo>
    </Wrapper>
  );
};

const ConnectedDemos = connect()(Demos);

function Settings() {
  const [state, setState] = useState({
    isOpen: false,
  });

  const toggleDrawer = (open: boolean) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <React.Fragment>
      <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)}>
        <PaletteIcon />
      </Fab>
      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <ConnectedDemos />
      </Drawer>
    </React.Fragment>
  );
}

export default Settings;
