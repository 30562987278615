import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PerfRecord } from 'external/hr_system/proto/perf_record_pb';

import perfRecordsApi from 'api/perfRecordsApi';

interface UserPerfRecordState {
  userPerfRecords: PerfRecord.AsObject[];
  error?: string;
  loading: boolean;
}

const initialState: UserPerfRecordState = {
  userPerfRecords: [],
  error: undefined,
  loading: false,
};

export const fetchPerfRecords = createAsyncThunk(
  'userPerfRecords/fetch',
  async (params: { userId?: number; reviewCycleId?: number }) => {
    const response = await perfRecordsApi.getPerfRecords(params);
    return response.perfRecordsList;
  },
);

const userPerfRecords = createSlice({
  name: 'userPerfRecords',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPerfRecords.pending, (state) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(fetchPerfRecords.fulfilled, (state, action) => {
        state.userPerfRecords = action.payload;
        state.loading = false;
      })
      .addCase(fetchPerfRecords.rejected, (state, action) => {
        state.error = `Get perf record failed ${action.error?.message}`;
        state.loading = false;
      });
  },
});

export default userPerfRecords.reducer;
