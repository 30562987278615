/* eslint-disable import/prefer-default-export */
import { Action } from 'redux';

import { GroupBasedUserPermission } from 'external/hr_system/proto/permission_pb';

export interface User {
  email: string;
  ssoUserId: string;
  avatarUrl?: string;
}

export interface Session {
  user: User;
}

export interface AuthState {
  isPending?: boolean;
  session?: { user: User };
  permissions?: Array<GroupBasedUserPermission.AsObject>;
  isManager?: boolean;
}

export enum AuthActionType {
  LOGIN_START = 'AUTH.LOGIN_START',
  LOGIN_COMPLETE = 'AUTH.LOGIN_COMPLETE',
  LOGOUT_COMPLETE = 'AUTH.LOGOUT_COMPLETE',
  FETCH_PERMISSIONS_COMPLETE = 'AUTH.FETCH_PERMISSIONS_COMPLETE',
  UPDATE_IS_MANAGER = 'AUTH.UPDATE_IS_MANAGER',
}

interface LogionStartAction extends Action<AuthActionType.LOGIN_START> {}

interface LogionCompleteAction extends Action<AuthActionType.LOGIN_COMPLETE> {
  session: { user: User };
}

interface LogoutCompleteAction extends Action<AuthActionType.LOGOUT_COMPLETE> {}

interface FetchPermissionsCompleteAction extends Action<AuthActionType.FETCH_PERMISSIONS_COMPLETE> {
  permissions: Array<GroupBasedUserPermission.AsObject>;
}

interface UpdateIsManagerAction extends Action<AuthActionType.UPDATE_IS_MANAGER> {
  isManager: boolean;
}

export type AuthAction =
  | LogionCompleteAction
  | LogionStartAction
  | LogoutCompleteAction
  | FetchPermissionsCompleteAction
  | UpdateIsManagerAction;
