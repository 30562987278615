// source: proto/review_collection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

import * as proto_review_stage_pb from 'hr_web/proto/review_stage_pb';
goog.object.extend(proto, proto_review_stage_pb);
import * as proto_review_type_pb from 'hr_web/proto/review_type_pb';
goog.object.extend(proto, proto_review_type_pb);
goog.exportSymbol('proto.hr_system.ReviewCollection', null, global);
goog.exportSymbol('proto.hr_system.ReviewCollection.Review', null, global);
goog.exportSymbol('proto.hr_system.ReviewStatus', null, global);
goog.exportSymbol('proto.hr_system.ReviewStatus.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ReviewStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.ReviewStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ReviewStatus.displayName = 'proto.hr_system.ReviewStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ReviewCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.ReviewCollection.repeatedFields_, null);
};
goog.inherits(proto.hr_system.ReviewCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ReviewCollection.displayName = 'proto.hr_system.ReviewCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ReviewCollection.Review = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.ReviewCollection.Review, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ReviewCollection.Review.displayName = 'proto.hr_system.ReviewCollection.Review';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ReviewStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ReviewStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ReviewStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ReviewStatus}
 */
proto.hr_system.ReviewStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ReviewStatus;
  return proto.hr_system.ReviewStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ReviewStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ReviewStatus}
 */
proto.hr_system.ReviewStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ReviewStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ReviewStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ReviewStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.hr_system.ReviewStatus.Enum = {
  UNKNOWN: 0,
  PENDING: 1,
  SUBMITTED: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.ReviewCollection.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ReviewCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ReviewCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ReviewCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reviewCycleId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    revieweeId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    reviewerId: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    requesterId: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    reviewType: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    isSubmitted: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
    isReleased: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f,
    isDeclined: (f = jspb.Message.getBooleanField(msg, 9)) == null ? undefined : f,
    declineReason: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    reviewsList: jspb.Message.toObjectList(msg.getReviewsList(),
    proto.hr_system.ReviewCollection.Review.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ReviewCollection}
 */
proto.hr_system.ReviewCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ReviewCollection;
  return proto.hr_system.ReviewCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ReviewCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ReviewCollection}
 */
proto.hr_system.ReviewCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevieweeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewerId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequesterId(value);
      break;
    case 6:
      var value = /** @type {!proto.hr_system.ReviewType.Enum} */ (reader.readEnum());
      msg.setReviewType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSubmitted(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReleased(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeclined(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeclineReason(value);
      break;
    case 10:
      var value = new proto.hr_system.ReviewCollection.Review;
      reader.readMessage(value,proto.hr_system.ReviewCollection.Review.deserializeBinaryFromReader);
      msg.addReviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ReviewCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ReviewCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ReviewCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.hr_system.ReviewCollection.Review.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ReviewCollection.Review.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ReviewCollection.Review.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ReviewCollection.Review} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewCollection.Review.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reviewCollectionId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    reviewQuestionId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    answer: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    rating: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    stageUpdated: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    promoStageUpdated: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    status: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    timeUpdated: (f = jspb.Message.getOptionalFloatingPointField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ReviewCollection.Review}
 */
proto.hr_system.ReviewCollection.Review.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ReviewCollection.Review;
  return proto.hr_system.ReviewCollection.Review.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ReviewCollection.Review} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ReviewCollection.Review}
 */
proto.hr_system.ReviewCollection.Review.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCollectionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewQuestionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRating(value);
      break;
    case 6:
      var value = /** @type {!proto.hr_system.ReviewStage.Enum} */ (reader.readEnum());
      msg.setStageUpdated(value);
      break;
    case 9:
      var value = /** @type {!proto.hr_system.ReviewStage.Enum} */ (reader.readEnum());
      msg.setPromoStageUpdated(value);
      break;
    case 7:
      var value = /** @type {!proto.hr_system.ReviewStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ReviewCollection.Review.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ReviewCollection.Review.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ReviewCollection.Review} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewCollection.Review.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewStage.Enum} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewStage.Enum} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewStatus.Enum} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeFloat(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.ReviewCollection.Review.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 review_collection_id = 2;
 * @return {number}
 */
proto.hr_system.ReviewCollection.Review.prototype.getReviewCollectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setReviewCollectionId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearReviewCollectionId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasReviewCollectionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 review_question_id = 3;
 * @return {number}
 */
proto.hr_system.ReviewCollection.Review.prototype.getReviewQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setReviewQuestionId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearReviewQuestionId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasReviewQuestionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string answer = 4;
 * @return {string}
 */
proto.hr_system.ReviewCollection.Review.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setAnswer = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearAnswer = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string rating = 5;
 * @return {string}
 */
proto.hr_system.ReviewCollection.Review.prototype.getRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setRating = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearRating = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasRating = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ReviewStage.Enum stage_updated = 6;
 * @return {!proto.hr_system.ReviewStage.Enum}
 */
proto.hr_system.ReviewCollection.Review.prototype.getStageUpdated = function() {
  return /** @type {!proto.hr_system.ReviewStage.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.hr_system.ReviewStage.Enum} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setStageUpdated = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearStageUpdated = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasStageUpdated = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ReviewStage.Enum promo_stage_updated = 9;
 * @return {!proto.hr_system.ReviewStage.Enum}
 */
proto.hr_system.ReviewCollection.Review.prototype.getPromoStageUpdated = function() {
  return /** @type {!proto.hr_system.ReviewStage.Enum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.hr_system.ReviewStage.Enum} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setPromoStageUpdated = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearPromoStageUpdated = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasPromoStageUpdated = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ReviewStatus.Enum status = 7;
 * @return {!proto.hr_system.ReviewStatus.Enum}
 */
proto.hr_system.ReviewCollection.Review.prototype.getStatus = function() {
  return /** @type {!proto.hr_system.ReviewStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.hr_system.ReviewStatus.Enum} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional float time_updated = 8;
 * @return {number}
 */
proto.hr_system.ReviewCollection.Review.prototype.getTimeUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.setTimeUpdated = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection.Review} returns this
 */
proto.hr_system.ReviewCollection.Review.prototype.clearTimeUpdated = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.Review.prototype.hasTimeUpdated = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.ReviewCollection.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 review_cycle_id = 2;
 * @return {number}
 */
proto.hr_system.ReviewCollection.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 reviewee_id = 3;
 * @return {number}
 */
proto.hr_system.ReviewCollection.prototype.getRevieweeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setRevieweeId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearRevieweeId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasRevieweeId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 reviewer_id = 4;
 * @return {number}
 */
proto.hr_system.ReviewCollection.prototype.getReviewerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setReviewerId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearReviewerId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasReviewerId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 requester_id = 5;
 * @return {number}
 */
proto.hr_system.ReviewCollection.prototype.getRequesterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setRequesterId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearRequesterId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasRequesterId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ReviewType.Enum review_type = 6;
 * @return {!proto.hr_system.ReviewType.Enum}
 */
proto.hr_system.ReviewCollection.prototype.getReviewType = function() {
  return /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.hr_system.ReviewType.Enum} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setReviewType = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearReviewType = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasReviewType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_submitted = 7;
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.getIsSubmitted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setIsSubmitted = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearIsSubmitted = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasIsSubmitted = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_released = 8;
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.getIsReleased = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setIsReleased = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearIsReleased = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasIsReleased = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_declined = 9;
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.getIsDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setIsDeclined = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearIsDeclined = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasIsDeclined = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string decline_reason = 11;
 * @return {string}
 */
proto.hr_system.ReviewCollection.prototype.getDeclineReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.setDeclineReason = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearDeclineReason = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReviewCollection.prototype.hasDeclineReason = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated Review reviews = 10;
 * @return {!Array<!proto.hr_system.ReviewCollection.Review>}
 */
proto.hr_system.ReviewCollection.prototype.getReviewsList = function() {
  return /** @type{!Array<!proto.hr_system.ReviewCollection.Review>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.ReviewCollection.Review, 10));
};


/**
 * @param {!Array<!proto.hr_system.ReviewCollection.Review>} value
 * @return {!proto.hr_system.ReviewCollection} returns this
*/
proto.hr_system.ReviewCollection.prototype.setReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.hr_system.ReviewCollection.Review=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.ReviewCollection.Review}
 */
proto.hr_system.ReviewCollection.prototype.addReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.hr_system.ReviewCollection.Review, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.ReviewCollection} returns this
 */
proto.hr_system.ReviewCollection.prototype.clearReviewsList = function() {
  return this.setReviewsList([]);
};


export const { ReviewCollection, ReviewStatus } = proto.hr_system
