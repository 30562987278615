// source: proto/permission.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.hr_system.CreateOrUpdateUserPermissionsRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateUserPermissionsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetAllUserPermissionsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetUserPermissionsResponse', null, global);
goog.exportSymbol('proto.hr_system.GroupBasedUserPermission', null, global);
goog.exportSymbol('proto.hr_system.GroupBasedUserPermission.Permission', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GroupBasedUserPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GroupBasedUserPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GroupBasedUserPermission.displayName = 'proto.hr_system.GroupBasedUserPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUserPermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUserPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserPermissionsResponse.displayName = 'proto.hr_system.GetUserPermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.CreateOrUpdateUserPermissionsRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateUserPermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateUserPermissionsRequest.displayName = 'proto.hr_system.CreateOrUpdateUserPermissionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateUserPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateUserPermissionsResponse.displayName = 'proto.hr_system.CreateOrUpdateUserPermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetAllUserPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetAllUserPermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetAllUserPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetAllUserPermissionsResponse.displayName = 'proto.hr_system.GetAllUserPermissionsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GroupBasedUserPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GroupBasedUserPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GroupBasedUserPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GroupBasedUserPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    permission: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    userId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    siteId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    companyEmail: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    businessUnitId: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GroupBasedUserPermission}
 */
proto.hr_system.GroupBasedUserPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GroupBasedUserPermission;
  return proto.hr_system.GroupBasedUserPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GroupBasedUserPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GroupBasedUserPermission}
 */
proto.hr_system.GroupBasedUserPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.hr_system.GroupBasedUserPermission.Permission} */ (reader.readEnum());
      msg.setPermission(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyEmail(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessUnitId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GroupBasedUserPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GroupBasedUserPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GroupBasedUserPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GroupBasedUserPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.hr_system.GroupBasedUserPermission.Permission} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.hr_system.GroupBasedUserPermission.Permission = {
  DEFAULT: 0,
  ORG_CHART_VIEW_ALL: 1,
  ORG_CHART_MANAGE_ALL: 2,
  ORG_CHART_SELF_MANAGE_SUB_REPORT_CHAIN: 7,
  ORG_CHART_SITE_VIEW_ALL: 8,
  ORG_CHART_SITE_MANAGE_ALL: 9,
  ORG_CHART_SITE_MANAGE_REPORT_CHAIN: 10,
  PERF_MANAGE_ALL: 4,
  PERF_GROUP_VIEW: 5,
  PERF_GROUP_MANAGE: 6,
  ORG_CHART_FINANCE: 11,
  HC_PLANNING_CONFIGURATION_MANAGE: 12,
  HC_PLANNING_SITE_MANAGE: 16,
  HC_PLANNING_SITE_VIEW_ALL: 13,
  HC_PLANNING_SITE_VIEW_OPEN: 15,
  HC_PLANNING_REQUEST: 14,
  PROMO_MANAGE_ALL: 17
};

/**
 * optional Permission permission = 1;
 * @return {!proto.hr_system.GroupBasedUserPermission.Permission}
 */
proto.hr_system.GroupBasedUserPermission.prototype.getPermission = function() {
  return /** @type {!proto.hr_system.GroupBasedUserPermission.Permission} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.hr_system.GroupBasedUserPermission.Permission} value
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.setPermission = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.clearPermission = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GroupBasedUserPermission.prototype.hasPermission = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.hr_system.GroupBasedUserPermission.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GroupBasedUserPermission.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 site_id = 3;
 * @return {number}
 */
proto.hr_system.GroupBasedUserPermission.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.setSiteId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.clearSiteId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GroupBasedUserPermission.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string company_email = 4;
 * @return {string}
 */
proto.hr_system.GroupBasedUserPermission.prototype.getCompanyEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.setCompanyEmail = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.clearCompanyEmail = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GroupBasedUserPermission.prototype.hasCompanyEmail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 business_unit_id = 5;
 * @return {number}
 */
proto.hr_system.GroupBasedUserPermission.prototype.getBusinessUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.setBusinessUnitId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GroupBasedUserPermission} returns this
 */
proto.hr_system.GroupBasedUserPermission.prototype.clearBusinessUnitId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GroupBasedUserPermission.prototype.hasBusinessUnitId = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUserPermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.hr_system.GroupBasedUserPermission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserPermissionsResponse}
 */
proto.hr_system.GetUserPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserPermissionsResponse;
  return proto.hr_system.GetUserPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserPermissionsResponse}
 */
proto.hr_system.GetUserPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.GroupBasedUserPermission;
      reader.readMessage(value,proto.hr_system.GroupBasedUserPermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hr_system.GroupBasedUserPermission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupBasedUserPermission permissions = 1;
 * @return {!Array<!proto.hr_system.GroupBasedUserPermission>}
 */
proto.hr_system.GetUserPermissionsResponse.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.hr_system.GroupBasedUserPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.GroupBasedUserPermission, 1));
};


/**
 * @param {!Array<!proto.hr_system.GroupBasedUserPermission>} value
 * @return {!proto.hr_system.GetUserPermissionsResponse} returns this
*/
proto.hr_system.GetUserPermissionsResponse.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.GroupBasedUserPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GroupBasedUserPermission}
 */
proto.hr_system.GetUserPermissionsResponse.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.GroupBasedUserPermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserPermissionsResponse} returns this
 */
proto.hr_system.GetUserPermissionsResponse.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateUserPermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateUserPermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.hr_system.GroupBasedUserPermission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateUserPermissionsRequest}
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateUserPermissionsRequest;
  return proto.hr_system.CreateOrUpdateUserPermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateUserPermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateUserPermissionsRequest}
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.GroupBasedUserPermission;
      reader.readMessage(value,proto.hr_system.GroupBasedUserPermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateUserPermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateUserPermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hr_system.GroupBasedUserPermission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupBasedUserPermission permissions = 1;
 * @return {!Array<!proto.hr_system.GroupBasedUserPermission>}
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.hr_system.GroupBasedUserPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.GroupBasedUserPermission, 1));
};


/**
 * @param {!Array<!proto.hr_system.GroupBasedUserPermission>} value
 * @return {!proto.hr_system.CreateOrUpdateUserPermissionsRequest} returns this
*/
proto.hr_system.CreateOrUpdateUserPermissionsRequest.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.GroupBasedUserPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GroupBasedUserPermission}
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.GroupBasedUserPermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.CreateOrUpdateUserPermissionsRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserPermissionsRequest.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateUserPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateUserPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateUserPermissionsResponse}
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateUserPermissionsResponse;
  return proto.hr_system.CreateOrUpdateUserPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateUserPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateUserPermissionsResponse}
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateUserPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateUserPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateUserPermissionsResponse} returns this
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateUserPermissionsResponse} returns this
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateUserPermissionsResponse.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetAllUserPermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetAllUserPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetAllUserPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetAllUserPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetAllUserPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.hr_system.GroupBasedUserPermission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetAllUserPermissionsResponse}
 */
proto.hr_system.GetAllUserPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetAllUserPermissionsResponse;
  return proto.hr_system.GetAllUserPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetAllUserPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetAllUserPermissionsResponse}
 */
proto.hr_system.GetAllUserPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.GroupBasedUserPermission;
      reader.readMessage(value,proto.hr_system.GroupBasedUserPermission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetAllUserPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetAllUserPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetAllUserPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetAllUserPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hr_system.GroupBasedUserPermission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupBasedUserPermission permissions = 1;
 * @return {!Array<!proto.hr_system.GroupBasedUserPermission>}
 */
proto.hr_system.GetAllUserPermissionsResponse.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.hr_system.GroupBasedUserPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.GroupBasedUserPermission, 1));
};


/**
 * @param {!Array<!proto.hr_system.GroupBasedUserPermission>} value
 * @return {!proto.hr_system.GetAllUserPermissionsResponse} returns this
*/
proto.hr_system.GetAllUserPermissionsResponse.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.GroupBasedUserPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GroupBasedUserPermission}
 */
proto.hr_system.GetAllUserPermissionsResponse.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.GroupBasedUserPermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetAllUserPermissionsResponse} returns this
 */
proto.hr_system.GetAllUserPermissionsResponse.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


export const { CreateOrUpdateUserPermissionsRequest, CreateOrUpdateUserPermissionsResponse, GetAllUserPermissionsResponse, GetUserPermissionsResponse, GroupBasedUserPermission } = proto.hr_system
