import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AppStateType } from 'store';
import { AuthState } from 'store/auth/types';

interface Props extends React.HTMLAttributes<HTMLElement>, RouteProps {
  component: React.ComponentType;
  path: string;
  children?: React.ReactNode;
  layout: React.ElementType;
}

const PrivateRoute: React.FunctionComponent<Props & AuthState> = ({
  component: Component,
  layout: Layout,
  path,
  location,
  session,
  ...rest
}) => (
  <Route exact path={path}>
    {session ? (
      <Layout>
        <Component {...rest} />
      </Layout>
    ) : (
      <Redirect
        to={{
          pathname: '/welcome',
          state: {
            previousLocation: location && location.pathname + location.search,
          },
        }}
      />
    )}
  </Route>
);

const mapStateToProps = (state: AppStateType): AuthState => ({
  session: state.auth.session,
});

export default connect(mapStateToProps)(PrivateRoute);
