import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { EmploymentType } from 'external/hr_system/proto/entities_pb';

import employmentTypeApi from 'api/employmentTypeApi';

interface EmploymentTypesState {
  employmentTypesById: Record<number, EmploymentType.AsObject>;
  error?: string;
}

export const fetchEmploymentTypes = createAsyncThunk(
  'employmentTypes/fetchAsMap',
  (force: boolean) => employmentTypeApi.listAsCachedMap(force),
);

export const addEmploymentType = createAsyncThunk(
  'employmentTypes/add',
  async (name: string): Promise<EmploymentType.AsObject> => {
    const response = await employmentTypeApi.add(name);
    return constructEmploymentType(name, response.employmentTypeId);
  },
);

export const updateEmploymentType = createAsyncThunk(
  'employmentTypes/update',
  async (params: { name: string; id: number }) => {
    const response = await employmentTypeApi.update(params);
    return constructEmploymentType(params.name, response.employmentTypeId);
  },
);

export const deleteEmploymentType = createAsyncThunk(
  'employmentTypes/delete',
  async (id: number) => {
    await employmentTypeApi.deleteOne(id);
    return id;
  },
);

const constructEmploymentType = (name: string, id?: number) => {
  const result = new EmploymentType();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting employmentTypeId but it is undefined.');
  }
};

const employmentTypes = createSlice({
  name: 'employmentTypes',
  initialState: {
    employmentTypesById: {},
  } as EmploymentTypesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmploymentTypes.fulfilled, (state, action) => {
        state.employmentTypesById = action.payload;
      })
      .addCase(addEmploymentType.fulfilled, (state, action) => {
        const employmentType = action.payload;
        if (employmentType.id) {
          state.employmentTypesById[employmentType.id] = employmentType;
        }
        state.error = undefined;
      })
      .addCase(addEmploymentType.rejected, (state, action) => {
        state.error = `Add employment type failed ${action.error?.message}`;
      })
      .addCase(updateEmploymentType.fulfilled, (state, action) => {
        const employmentType = action.payload;
        if (employmentType.id) {
          state.employmentTypesById[employmentType.id] = employmentType;
        }
        state.error = undefined;
      })
      .addCase(updateEmploymentType.rejected, (state, action) => {
        state.error = `Update employment type failed ${action.error?.message}`;
      })
      .addCase(deleteEmploymentType.fulfilled, (state, action) => {
        delete state.employmentTypesById[action.payload];
        state.error = undefined;
      })
      .addCase(deleteEmploymentType.rejected, (state, action) => {
        state.error = `Delete employment type failed ${action.error?.message}`;
      });
  },
});

export default employmentTypes.reducer;
