import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Title } from 'external/hr_system/proto/entities_pb';

import positionApi from 'api/positionApi';

interface PositionsState {
  positionsById: Record<number, Title.AsObject>;
  error?: string;
}

export const fetchPositions = createAsyncThunk('positions/fetchAsMap', (force: boolean) =>
  positionApi.listAsCachedMap(force),
);

export const addPosition = createAsyncThunk(
  'positions/add',
  async (name: string): Promise<Title.AsObject> => {
    const response = await positionApi.add(name);
    return constructPosition(name, response.titleId);
  },
);

export const updatePosition = createAsyncThunk(
  'positions/update',
  async (params: { name: string; id: number }) => {
    const response = await positionApi.update(params);
    return constructPosition(params.name, response.titleId);
  },
);

export const deletePosition = createAsyncThunk('positions/delete', async (id: number) => {
  await positionApi.deleteOne(id);
  return id;
});

const constructPosition = (name: string, id?: number) => {
  const result = new Title();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting positionId but it is undefined.');
  }
};

const positions = createSlice({
  name: 'positions',
  initialState: {
    positionsById: {},
  } as PositionsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPositions.fulfilled, (state, action) => {
        state.positionsById = action.payload;
      })
      .addCase(addPosition.fulfilled, (state, action) => {
        const position = action.payload;
        if (position.id) {
          state.positionsById[position.id] = position;
        }
        state.error = undefined;
      })
      .addCase(addPosition.rejected, (state, action) => {
        state.error = `Add position failed ${action.error?.message}`;
      })
      .addCase(updatePosition.fulfilled, (state, action) => {
        const position = action.payload;
        if (position.id) {
          state.positionsById[position.id] = position;
        }
        state.error = undefined;
      })
      .addCase(updatePosition.rejected, (state, action) => {
        state.error = `Update position failed ${action.error?.message}`;
      })
      .addCase(deletePosition.fulfilled, (state, action) => {
        delete state.positionsById[action.payload];
        state.error = undefined;
      })
      .addCase(deletePosition.rejected, (state, action) => {
        state.error = `Delete position failed ${action.error?.message}`;
      });
  },
});

export default positions.reducer;
