import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CostCenter } from 'external/hr_system/proto/entities_pb';

import costCenterApi from 'api/costCenterApi';

interface CostCentersState {
  costCentersById: Record<number, CostCenter.AsObject>;
  error?: string;
}

export const fetchCostCenters = createAsyncThunk('costCenters/fetchAsMap', (force: boolean) =>
  costCenterApi.listAsCachedMap(force),
);

export const addCostCenter = createAsyncThunk(
  'costCenters/add',
  async (name: string): Promise<CostCenter.AsObject> => {
    const response = await costCenterApi.add(name);
    return constructCostCenter(name, response.costCenterId);
  },
);

export const updateCostCenter = createAsyncThunk(
  'costCenters/update',
  async (params: { name: string; id: number }) => {
    const response = await costCenterApi.update(params);
    return constructCostCenter(params.name, response.costCenterId);
  },
);

export const deleteCostCenter = createAsyncThunk('costCenters/delete', async (id: number) => {
  await costCenterApi.deleteOne(id);
  return id;
});

const constructCostCenter = (name: string, id?: number) => {
  const result = new CostCenter();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting costCenterId but it is undefined.');
  }
};

const costCenters = createSlice({
  name: 'costCenters',
  initialState: {
    costCentersById: {},
  } as CostCentersState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCostCenters.fulfilled, (state, action) => {
        state.costCentersById = action.payload;
      })
      .addCase(addCostCenter.fulfilled, (state, action) => {
        const costCenter = action.payload;
        if (costCenter.id) {
          state.costCentersById[costCenter.id] = costCenter;
        }
        state.error = undefined;
      })
      .addCase(addCostCenter.rejected, (state, action) => {
        state.error = `Add cost center failed ${action.error?.message}`;
      })
      .addCase(updateCostCenter.fulfilled, (state, action) => {
        const costCenter = action.payload;
        if (costCenter.id) {
          state.costCentersById[costCenter.id] = costCenter;
        }
        state.error = undefined;
      })
      .addCase(updateCostCenter.rejected, (state, action) => {
        state.error = `Update cost center failed ${action.error?.message}`;
      })
      .addCase(deleteCostCenter.fulfilled, (state, action) => {
        delete state.costCentersById[action.payload];
        state.error = undefined;
      })
      .addCase(deleteCostCenter.rejected, (state, action) => {
        state.error = `Delete cost center failed ${action.error?.message}`;
      });
  },
});

export default costCenters.reducer;
