import moment from 'moment';

/**
 * get the IANA time zone, like 'Asia/Shanghai', 'America/Phoenix'
 */
export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * get the locale string, like 'zh-CN', 'en-us'
 */
export const getLocale = () => Intl.DateTimeFormat().resolvedOptions().locale;

/**
 * get the difference in minutes between the time on the local and Universal Coordinated Time (UTC).
 */
export const getUTCOffset = () => new Date().getTimezoneOffset();

/**
 * get the time zone name in English, like 'China Standard Time', 'GMT+8'
 * @param format 'long' or 'short', 'long' return full name like 'China Standard Time', 'short' return GMT name like 'GMT+8'
 */
export const getTimeZoneName = (format: 'long' | 'short' = 'short') => {
  const s = new Date().toLocaleString('en-us', { timeZoneName: format, day: '2-digit' });
  return s.slice(s.indexOf(' ') + 1);
};

/**
 * get the GMT format time zone, like 'GMT+08:00', 'GMT-07:00'
 */
export const getGMTTimeZone = () => {
  const offset = getUTCOffset();
  const sign = offset > 0 ? '-' : '+';
  const offsetByHour = Math.abs(offset) / 60;
  const hours = Math.trunc(offsetByHour);
  const minutes = (offsetByHour - hours) * 60;
  return `GMT${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

/**
 * get datetime format of moment based on locale
 * `lll` (MMM DD, YYYY h:mm A) for en-us and YYYY/MM/DD HH:mm for any others
 */
export const getDateTimeFormatFromLocale = (locale: string = getLocale()) => {
  if (/^en-us$/i.test(locale)) {
    return 'lll';
  }
  return 'YYYY/MM/DD HH:mm';
};

/**
 * format like `Aug 17, 2021 5:21 PM (GMT+8)` for en-us locale,
 * and 2021/08/17 17:21 (GMT-7) for any others locale
 * @param date any avalible date input for moment
 */
export const getDisplayDateTimeWithTimeZone = (date: moment.MomentInput) => {
  return `${moment(date).format(getDateTimeFormatFromLocale())} (${getTimeZoneName()})`;
};

export const timeZoneInAsia = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone.includes('Asia');
};
