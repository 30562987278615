import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  GetUserOrgChartsRequest,
  GetUserOrgChartsResponse,
  UserNode,
} from 'external/hr_system/proto/org_chart_api_pb';
import { RelationType, ReportType } from 'external/hr_system/proto/report_type_pb';

import orgChartApi from 'api/orgChartApi';
import { updateChart } from './utils';

export type ManageLine = {
  managers: Array<number>;
  otherManagerLine: Array<number[]>;
};

export type UserInfo = UserNode.AsObject & Required<Pick<UserNode.AsObject, 'userId'>>;

export type UserChartNode = Partial<ManageLine> & {
  id: number;
  baseInfo: UserInfo;
  reporters?: UserChartNode[];
};

interface UserChartsState {
  baseId: number;
  userChartNodeById: Record<number, UserChartNode>;
  error?: string;
}

export const fetchUserCharts = createAsyncThunk('userCharts/fetch', (id?: number) => {
  const params: GetUserOrgChartsRequest.AsObject = {
    reportRelationsList: [
      {
        relationType: RelationType.Enum.MANAGER,
        reportType: ReportType.Enum.DIRECT,
        isDirect: false,
      },
      {
        relationType: RelationType.Enum.REPORT,
        reportType: ReportType.Enum.DIRECT,
        isDirect: true,
      },
    ],
  };
  if (id !== undefined) {
    params.baseUserId = id.toString();
  }
  return orgChartApi.fetchUserOrgCharts(params);
});

export const setBaseUserId = createAction<number>('userCharts/setBaseUserId');

const userCharts = createSlice({
  name: 'userCharts',
  initialState: {
    userChartNodeById: {},
  } as UserChartsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCharts.fulfilled, (state, action) => {
        if (action.payload.baseUser && action.payload.baseUser.userId !== undefined) {
          state.baseId = action.payload.baseUser.userId;
          state.userChartNodeById = updateChart(
            action.payload as Required<GetUserOrgChartsResponse.AsObject>,
            state.userChartNodeById,
          );
        }
      })
      .addCase(fetchUserCharts.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(setBaseUserId, (state, action) => {
        state.baseId = action.payload;
      });
  },
});

export default userCharts.reducer;
