import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Team } from 'external/hr_system/proto/entities_pb';

import teamApi from 'api/teamApi';

interface TeamsState {
  teamsById: Record<number, Team.AsObject>;
  error?: string;
}

export const fetchTeams = createAsyncThunk('teams/fetchAsMap', (force: boolean) =>
  teamApi.listAsCachedMap(force),
);

export const addTeam = createAsyncThunk(
  'teams/add',
  async (name: string): Promise<Team.AsObject> => {
    const response = await teamApi.add(name);
    return constructTeam(name, response.teamId);
  },
);

export const updateTeam = createAsyncThunk(
  'teams/update',
  async (params: { name: string; id: number }) => {
    const response = await teamApi.update(params);
    return constructTeam(params.name, response.teamId);
  },
);

export const deleteTeam = createAsyncThunk('teams/delete', async (id: number) => {
  await teamApi.deleteOne(id);
  return id;
});

const constructTeam = (name: string, id?: number) => {
  const result = new Team();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting teamId but it is undefined.');
  }
};

const teams = createSlice({
  name: 'teams',
  initialState: {
    teamsById: {},
  } as TeamsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.teamsById = action.payload;
      })
      .addCase(addTeam.fulfilled, (state, action) => {
        const team = action.payload;
        if (team.id) {
          state.teamsById[team.id] = team;
        }
        state.error = undefined;
      })
      .addCase(addTeam.rejected, (state, action) => {
        state.error = `Add team failed ${action.error?.message}`;
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        const team = action.payload;
        if (team.id) {
          state.teamsById[team.id] = team;
        }
        state.error = undefined;
      })
      .addCase(updateTeam.rejected, (state, action) => {
        state.error = `Update team failed ${action.error?.message}`;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        delete state.teamsById[action.payload];
        state.error = undefined;
      })
      .addCase(deleteTeam.rejected, (state, action) => {
        state.error = `Delete team failed ${action.error?.message}`;
      });
  },
});

export default teams.reducer;
