import React, { useState } from 'react';
import { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Chip,
  Collapse,
  Divider,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { rgba } from 'polished';
import { darken } from 'polished';
import styled from 'styled-components';

import promoPanelApi from 'api/promoPanelApi';
import { errorHandler } from 'common/utils/errorHandler';
import UserCenter from 'pages/auth/UserCenter';
import Feedback from './Feedback';
import { NavLink } from './styledComponents';
import { sidebarRoutes as routes } from '../routes/index';
import {
  getCurrentUserIsManager,
  selectCanAccessHcPlanning,
  selectCanManagePromoTool,
  selectCanViewPerf,
  selectIsOrgChartAdmin,
  selectIsOrgChartSuperAdmin,
} from '../store/auth';
import { ChildElementType, RouteInfoType } from '../types/types';

import '../vendor/perfect-scrollbar.css';

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    background: #1b2430;
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)<{ button?: boolean }>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(0)}px;
  cursor: default;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }

  & .divider {
    height: 18px;
    background-color: #98a3b2;
    margin: auto 10px;
  }
  & .title {
    font-size: 16px;
    text-transform: uppercase;
  }
  & .test-tag {
    background: #6ad9e4;
    border-radius: 2px;
    text-transform: uppercase;
    display: flex;

    color: #1b2430;
    margin-left: 8px;
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
    padding: 2px;
  }
`;

type CategoryType = {
  activeClassName?: string;
  button?: boolean;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const Category = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)<{
  activeClassName: string;
  component: typeof NavLink;
  exact: boolean;
  to: string;
}>`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

type SidebarCategoryPropsType = {
  name: string;
  icon: JSX.Element;
  classes?: string;
  isOpen?: boolean;
  isCollapsible: boolean;
  badge?: string | number;
  activeClassName?: string;
  button: true;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  name,
  icon,
  isOpen,
  isCollapsible,
  badge,
  ...rest
}) => {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsible ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  );
};

type SidebarLinkPropsType = {
  name: string;
  to: string;
  badge?: string | number;
  icon?: JSX.Element;
};

const SidebarLink: React.FC<SidebarLinkPropsType> = ({ name, to, badge }) => {
  return (
    <Link button dense component={NavLink} exact to={to} activeClassName="active">
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  );
};

type SidebarPropsType = {
  classes?: string;
  staticContext: string;
  location: {
    pathname: string;
  };
  routes: Array<RouteInfoType>;
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: () => void;
};

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  classes: _classes,
  staticContext: _staticContext,
  location,
  ...rest
}) => {
  type tplotOptions = {
    [key: number]: boolean;
  };
  const initOpenRoutes = (): tplotOptions => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route: RouteInfoType, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === '/';

      _routes = Object.assign({}, _routes, { [index]: isActive || isOpen || isHome });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
  const canViewPerf = useSelector(selectCanViewPerf);
  const isOrgChartSuperAdmin = useSelector(selectIsOrgChartSuperAdmin);
  const isOrgChartAdmin = useSelector(selectIsOrgChartAdmin);
  const canAccessHcPlanning = useSelector(selectCanAccessHcPlanning);
  const canManagePromoTool = useSelector(selectCanManagePromoTool);
  const isManager = useSelector(getCurrentUserIsManager);
  const [isPromoCommittee, setIsPromoCommittee] = useState<boolean>(false);

  useEffect(() => {
    let cancel = false;

    (async () => {
      const response = await promoPanelApi.isPromoCommittee().catch(errorHandler);
      if (!cancel) {
        setIsPromoCommittee((response && response.isPromoCommittee) || false);
      }
    })();

    return () => {
      cancel = true;
    };
  }, []);

  const toggle = (index: number) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false })),
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };

  const prodEnv = process.env.REACT_APP_ENV === 'production';
  const isVisibleWithPermission = (routeInfo: RouteInfoType): boolean => {
    if (routeInfo.id === 'Administration' && !isOrgChartAdmin) {
      return false;
    }
    if (routeInfo.id === 'HC Planning' && !canAccessHcPlanning) {
      return false;
    }
    return true;
  };

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand button>
        <a href="/">
          <img src="/static/img/logo/sidebar.svg" alt="Pony.ai" />
        </a>
        <Divider orientation="vertical" className="divider" />
        <span className="title">HR SYSTEM</span>
        {!prodEnv && <div className="test-tag">TEST</div>}
      </Brand>
      <UserCenter />
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category: RouteInfoType, index) =>
              !isVisibleWithPermission(category) ? null : (
                <React.Fragment key={index}>
                  {category.header ? <SidebarSection>{category.header}</SidebarSection> : null}
                  {category.children && category.icon ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={!openRoutes[index]}
                        isCollapsible={true}
                        name={category.id}
                        icon={category.icon}
                        button={true}
                        onClick={() => toggle(index)}
                      />

                      <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
                        {category.children.map((route: ChildElementType, index: number) => {
                          let showLink = true;
                          if (route.path === '/performance/calibration') {
                            showLink = canViewPerf || isManager;
                          } else if (route.path.includes('/administration')) {
                            showLink = isOrgChartAdmin;
                          } else if (route.path.includes('/administration/edit-histories')) {
                            showLink = isOrgChartSuperAdmin;
                          } else if (route.path === '/performance/promo-nominee') {
                            showLink = canManagePromoTool || isPromoCommittee;
                          }

                          return showLink && !route.hidden ? (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                            />
                          ) : null;
                        })}
                      </Collapse>
                    </React.Fragment>
                  ) : category.icon ? (
                    <SidebarCategory
                      isCollapsible={false}
                      name={category.id}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      button
                      badge={category.badge}
                    />
                  ) : null}
                </React.Fragment>
              ),
            )}
          </Items>
        </List>
      </Scrollbar>
      <Feedback />
    </Drawer>
  );
};

export default withRouter(Sidebar);
