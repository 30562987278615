import React from 'react';
import { Link } from '@material-ui/core';

import useStyle from './style';

export type FeedbackProps = {};

const feedbackSlackLink = 'https://ponyai.slack.com/archives/C024E0PHUBH' as const;

const Feedback = () => {
  const classes = useStyle();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Link
          href={feedbackSlackLink}
          underline="none"
          target="_blank"
          className={classes.feedback}
        >
          <span>Give Feedback</span>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default Feedback;
