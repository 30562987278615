import { JobFunction } from 'external/hr_system/proto/entities_pb';
import {
  CreateOrUpdateJobFunctionRequest,
  CreateOrUpdateJobFunctionResponse,
  DeleteResponse,
  GetJobFunctionsResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';

class JobFunctionApi extends BaseApi {
  private endpointPrefix = '/job_functions';
  private idBasedMapCacher: IdBasedMapCacher<
    JobFunction.AsObject,
    GetJobFunctionsResponse.AsObject
  >;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetJobFunctionsResponse.AsObject>(() => this.list());
    this.idBasedMapCacher = new IdBasedMapCacher<
      JobFunction.AsObject,
      GetJobFunctionsResponse.AsObject
    >(apiCacher, (response) => response.jobFunctionsList);
  }

  public async listAsCachedMap(force = false): Promise<Record<number, JobFunction.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async list(): Promise<GetJobFunctionsResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetJobFunctionsResponse.toObject(
      false,
      GetJobFunctionsResponse.deserializeBinary(buffer),
    );
  }

  public async add(name: string): Promise<CreateOrUpdateJobFunctionResponse.AsObject> {
    const request = new CreateOrUpdateJobFunctionRequest();
    const jobFunction = new JobFunction();
    jobFunction.setName(name);
    request.setJobFunction(jobFunction);

    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateJobFunctionResponse.toObject(
      false,
      CreateOrUpdateJobFunctionResponse.deserializeBinary(buffer),
    );
  }

  public async update({
    name,
    id,
  }: {
    name: string;
    id: number;
  }): Promise<CreateOrUpdateJobFunctionResponse.AsObject> {
    const request = new CreateOrUpdateJobFunctionRequest();
    const jobFunction = new JobFunction();
    jobFunction.setName(name);
    jobFunction.setId(id);
    request.setJobFunction(jobFunction);

    const response = await this.put(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateJobFunctionResponse.toObject(
      false,
      CreateOrUpdateJobFunctionResponse.deserializeBinary(buffer),
    );
  }

  public async deleteOne(id: number): Promise<DeleteResponse.AsObject> {
    const response = await super.delete(this.endpointPrefix + '/' + id, {});
    const buffer = await getBufferArrayFromResponse(response);
    return DeleteResponse.toObject(false, DeleteResponse.deserializeBinary(buffer));
  }
}

export default new JobFunctionApi();
