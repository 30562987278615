import { Action, DataPoint, Location } from 'external/hr_system/proto/monitor_pb';

import { toClientJson, toServerJson } from 'common/utils/json';
import BaseApi from './baseApi';

export type ActionEnum = Action.EnumMap[keyof Action.EnumMap];
export type LocationEnum = Location.EnumMap[keyof Location.EnumMap];

class MonitorApi extends BaseApi {
  private static endpointPrefix = '/monitor';

  public async addPoints(dataPoints: DataPoint.AsObject[]) {
    const response = await this.post(MonitorApi.endpointPrefix, {
      body: JSON.stringify(toServerJson({ dataPoints })),
      headers: {
        'Content-Type': 'application/json',
      },
      // allow the request to outlive the page, so we can get the monitor data even after user close the tab
      keepalive: true,
    });
    return toClientJson(await response.json());
  }
}

const monitorApi = new MonitorApi(undefined, false);

export default monitorApi;
