import React from 'react';
import { LinkProps, NavLink as RouterNavLink } from 'react-router-dom';
import {
  Avatar as MuiAvatar,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  @media screen and (max-width: 1600px) {
    max-width: 1000px;
  }
  @media screen and (min-width: 1601px) {
    max-width: 1200px;
  }
  width: 100%;
  margin: 0 auto;
`;

export const StyledDivider = styled(MuiDivider)(spacing);

export const StyledBreadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const StyledCard = styled(MuiCard)(spacing);

export const StyledPaper = styled(MuiPaper)(spacing);

export const StyledAvatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

export const Spacer = styled.div`
  flex: 1 1 ${(props) => props.style?.flexBasis || '100%'};
`;

export const ToolbarTitle = styled.div`
  min-width: 150px;
`;

export const NameCell = styled.div`
  display: flex;
  align-items: center;
`;

export const NavLink = React.forwardRef<LinkProps, any>((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));
