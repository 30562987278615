import { createAction, createReducer } from '@reduxjs/toolkit';

export interface ThemeState {
  currentTheme: number;
}

const initialState: ThemeState = {
  currentTheme: 0,
};

export const setTheme = createAction<number>('theme/updated');

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setTheme, (state, action) => {
    state.currentTheme = action.payload;
  });
});

export default reducer;
