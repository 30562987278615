import { createAction, createReducer } from '@reduxjs/toolkit';

export type SnackbarContent = {
  type: 'success' | 'info' | 'warning' | 'error';
  content: string;
  delay?: number;
};

export interface SnackbarContentState {
  currentContent?: SnackbarContent;
  // used for sharing displayed info among pages to avoid duplicated popping up of same snackbar
  displayed?: boolean;
  // content to avoid closing newly popped snackbar wrongly
  closeEvent?: { content: string };
}

const initialState: SnackbarContentState = {
  currentContent: undefined,
};

export const setSnackbarContent = createAction<SnackbarContent>('snackbarContent/set');
export const setSnackbarDisplayed = createAction<boolean>('snackbarContent/setSnackbarDisplayed');
export const closeSnackbar = createAction<{ content: string }>('snackbarContent/close');

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSnackbarContent, (state, action) => {
      state.currentContent = action.payload;
      state.closeEvent = undefined;
      state.displayed = false;
    })
    .addCase(setSnackbarDisplayed, (state, action) => {
      state.displayed = action.payload;
    })
    .addCase(closeSnackbar, (state, action) => {
      state.closeEvent = action.payload;
    });
});

export default reducer;
