import * as React from 'react';

import { errorHandler } from 'common/utils/errorHandler';
import Loader from './Loader';

const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

type componentType = React.ComponentClass<any> | null;

interface IAsyncState {
  component: componentType;
}

const componentLoadFailedStorageKey = 'componentLoadFailed';

export default function asyncComponent(importComponent: any) {
  class AsyncComponent extends React.Component<any, IAsyncState> {
    constructor(props: any) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      await sleep(150);

      const response = await importComponent().catch(() => {
        const lastLoadFailedTime = window.sessionStorage.getItem(componentLoadFailedStorageKey);
        if (
          !lastLoadFailedTime ||
          new Date().getTime() - Number.parseInt(lastLoadFailedTime, 10) > 1 * 60 * 1000 // 1 min
        ) {
          window.sessionStorage.setItem(
            componentLoadFailedStorageKey,
            new Date().getTime().toString(),
          );
          window.location.reload();
        } else {
          errorHandler(
            "Load page failed, please refresh page latter or connect HR System team via the 'Give Feedback' in the lower left corner",
          );
        }
      });

      if (response && response.default) {
        this.setState({
          component: response.default,
        });
      }
    }

    render() {
      const C: componentType = this.state.component;

      return C ? <C {...this.props} /> : <Loader />;
    }
  }

  return AsyncComponent;
}
