import {
  CheckIsManagerResponse,
  CreateOrUpdateReviewCollectionRequest,
  CreateOrUpdateReviewCollectionResponse,
  DeleteReviewCollectionRequest,
  GetReviewCollectionsResponse,
  ReleaseReviewCollectionsRequest,
  ReleaseReviewCollectionsResponse,
  UploadReviewCollectionsRequest,
  UploadReviewCollectionsResponse,
} from 'external/hr_system/proto/perf_tool_api_pb';
import { ReviewCollection, ReviewStatus } from 'external/hr_system/proto/review_collection_pb';
import { ReviewType } from 'external/hr_system/proto/review_type_pb';

import { BasicObject } from 'common/types';
import { getBufferArrayFromResponse, toClientJson, toServerJson } from 'common/utils/json';
import BaseApi from './baseApi';

// TODO remove mock data
class ReviewCollectionsApi extends BaseApi {
  private endpointPrefix = '/perf/review_collections';

  public async getReviewCollections(
    reviewCycleId: number,
    revieweeId?: number,
    reviewerId?: number,
    reviewType?: ReviewType.EnumMap[keyof ReviewType.EnumMap],
    excludeReviews?: boolean,
  ): Promise<GetReviewCollectionsResponse.AsObject> {
    const params: BasicObject = { reviewCycleId };
    if (revieweeId !== undefined) {
      params['revieweeId'] = revieweeId;
    }
    if (reviewerId !== undefined) {
      params['reviewerId'] = reviewerId;
    }
    if (reviewType !== undefined) {
      params['reviewType'] = reviewType;
    }
    if (excludeReviews !== undefined) {
      params['excludeReviews'] = excludeReviews ? 1 : 0;
    }
    const response = await this.get(this.endpointPrefix, toServerJson(params));
    const buffer = await getBufferArrayFromResponse(response);
    return GetReviewCollectionsResponse.toObject(
      false,
      GetReviewCollectionsResponse.deserializeBinary(buffer),
    );
  }

  public reviewCollectionObjectToPb(reviewCollection: ReviewCollection.AsObject): ReviewCollection {
    const reviewCollectionPb = new ReviewCollection();
    if (reviewCollection.id !== undefined) {
      reviewCollectionPb.setId(reviewCollection.id);
    }
    if (reviewCollection.isDeclined !== undefined) {
      reviewCollectionPb.setIsDeclined(reviewCollection.isDeclined);
    }
    if (reviewCollection.isReleased !== undefined) {
      reviewCollectionPb.setIsReleased(reviewCollection.isReleased);
    }
    if (reviewCollection.isSubmitted !== undefined) {
      reviewCollectionPb.setIsSubmitted(reviewCollection.isSubmitted);
    }
    if (reviewCollection.requesterId !== undefined) {
      reviewCollectionPb.setRequesterId(reviewCollection.requesterId);
    }
    if (reviewCollection.reviewCycleId !== undefined) {
      reviewCollectionPb.setReviewCycleId(reviewCollection.reviewCycleId);
    }
    if (reviewCollection.revieweeId !== undefined) {
      reviewCollectionPb.setRevieweeId(reviewCollection.revieweeId);
    }
    if (reviewCollection.reviewerId !== undefined) {
      reviewCollectionPb.setReviewerId(reviewCollection.reviewerId);
    }
    if (reviewCollection.reviewsList !== undefined) {
      reviewCollectionPb.setReviewsList(
        reviewCollection.reviewsList.map((review) => {
          const reviewPb = new ReviewCollection.Review();
          if (review.reviewQuestionId !== undefined) {
            reviewPb.setReviewQuestionId(review.reviewQuestionId);
          }
          if (review.answer !== undefined) {
            reviewPb.setAnswer(review.answer);
          }
          if (review.id !== undefined) {
            reviewPb.setId(review.id);
          }
          if (review.rating !== undefined) {
            reviewPb.setRating(review.rating);
          }
          if (review.reviewCollectionId !== undefined) {
            reviewPb.setReviewCollectionId(review.reviewCollectionId);
          }
          if (review.stageUpdated !== undefined) {
            reviewPb.setStageUpdated(review.stageUpdated);
          }
          return reviewPb;
        }),
      );
    }
    if (reviewCollection.reviewType !== undefined) {
      reviewCollectionPb.setReviewType(reviewCollection.reviewType);
    }
    return reviewCollectionPb;
  }

  public async createReviewCollection(
    reviewCollection: ReviewCollection.AsObject,
  ): Promise<CreateOrUpdateReviewCollectionResponse.AsObject> {
    const request: CreateOrUpdateReviewCollectionRequest.AsObject = {
      reviewCollection,
    };

    const response = await this.post(this.endpointPrefix, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async updateReviewCollection(
    reviewCollection: ReviewCollection.AsObject,
  ): Promise<CreateOrUpdateReviewCollectionResponse.AsObject> {
    const request: CreateOrUpdateReviewCollectionRequest.AsObject = {
      reviewCollection,
    };

    const response = await this.put(this.endpointPrefix, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async deleteReviewCollection(
    reviewCollection: ReviewCollection.AsObject,
  ): Promise<CreateOrUpdateReviewCollectionResponse.AsObject> {
    const request: DeleteReviewCollectionRequest.AsObject = {
      reviewCollectionId: reviewCollection.id,
    };
    const response = await this.delete(this.endpointPrefix, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async getSavedReviewCollection(
    reviewCycleId: number,
    revieweeId?: number,
    reviewerId?: number,
    reviewType?: ReviewType.EnumMap[keyof ReviewType.EnumMap],
  ): Promise<GetReviewCollectionsResponse.AsObject> {
    const params: BasicObject = { reviewCycleId };
    if (revieweeId !== undefined) {
      params['revieweeId'] = revieweeId;
    }
    if (reviewerId !== undefined) {
      params['reviewerId'] = reviewerId;
    }
    if (reviewType !== undefined) {
      params['reviewType'] = reviewType;
    }
    params['reviewStatus'] = ReviewStatus.Enum.PENDING;

    const response = await this.get(this.endpointPrefix, toServerJson(params));
    const buffer = await getBufferArrayFromResponse(response);
    return GetReviewCollectionsResponse.toObject(
      false,
      GetReviewCollectionsResponse.deserializeBinary(buffer),
    );
  }

  public async saveReviewCollection(
    reviewCollection: ReviewCollection.AsObject,
  ): Promise<CreateOrUpdateReviewCollectionResponse.AsObject> {
    const request: CreateOrUpdateReviewCollectionRequest.AsObject = {
      reviewCollection,
    };

    const response = await this.put(this.endpointPrefix + '/save', {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async submitReviewCollection(
    reviewCollection: ReviewCollection.AsObject,
  ): Promise<CreateOrUpdateReviewCollectionResponse.AsObject> {
    const request: CreateOrUpdateReviewCollectionRequest.AsObject = {
      reviewCollection,
    };

    const response = await this.put(this.endpointPrefix + '/submit', {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async updateReleaseStatus(
    reviewCycleId: number,
    revieweeId: number,
    isReleased: boolean = true,
    type: 'perf' | 'promo',
  ): Promise<ReleaseReviewCollectionsResponse.AsObject> {
    const request: ReleaseReviewCollectionsRequest.AsObject = {
      reviewCycleId: reviewCycleId,
      revieweeId: revieweeId,
      isReleased: isReleased,
      forPerf: type === 'perf',
      forPromo: type === 'promo',
    };

    const response = await this.put(this.endpointPrefix + '/release', {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async checkIsManager(): Promise<CheckIsManagerResponse.AsObject> {
    const response = await this.get(this.endpointPrefix + '/check_is_manager');
    const buffer = await getBufferArrayFromResponse(response);
    return CheckIsManagerResponse.toObject(false, CheckIsManagerResponse.deserializeBinary(buffer));
  }

  public async uploadReviewCollectionsJson(
    request: UploadReviewCollectionsRequest.AsObject,
  ): Promise<UploadReviewCollectionsResponse.AsObject> {
    const response = await this.post(this.endpointPrefix + '/upload', {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }
}

export default new ReviewCollectionsApi();
