export class ApiCacher<T> {
  private apiCreator: () => Promise<T>;
  private promise: Promise<T> | undefined;

  constructor(apiCreator: () => Promise<T>) {
    this.apiCreator = apiCreator;
    this.promise = undefined;
  }

  fetch(force = false): Promise<T> {
    if (this.promise && !force) {
      return this.promise;
    }
    this.promise = this.apiCreator();
    return this.promise;
  }
}

export class IdBasedMapCacher<T extends { id?: number }, R> {
  private apiCacher: ApiCacher<R>;
  private converter: (arg0: R) => Array<T>;

  constructor(apiCacher: ApiCacher<R>, converter: (arg0: R) => Array<T>) {
    this.apiCacher = apiCacher;
    this.converter = converter;
  }

  async fetch(force = false): Promise<Record<number, T>> {
    return this.apiCacher
      .fetch(force)
      .then((response) => this.converter(response))
      .then((list) =>
        list.reduce((result, object) => {
          if (object.id !== undefined) {
            result[object.id] = object;
          }
          return result;
        }, {} as Record<number, T>),
      );
  }
}
