import { Reducer } from 'redux';

import { AuthAction, AuthActionType, AuthState } from 'store/auth/types';

const initialState: AuthState = {
  permissions: [],
  isPending: false,
  isManager: false,
};

export const authReducer: Reducer<AuthState, AuthAction> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionType.FETCH_PERMISSIONS_COMPLETE:
      return {
        ...state,
        permissions: action.permissions,
      };
    case AuthActionType.LOGIN_START:
      return {
        ...state,
        isPending: true,
      };
    case AuthActionType.LOGIN_COMPLETE:
      return {
        ...state,
        session: action.session,
        isPending: false,
      };
    case AuthActionType.LOGOUT_COMPLETE:
      return {
        ...state,
        session: undefined,
        isPending: false,
      };
    case AuthActionType.UPDATE_IS_MANAGER:
      return {
        ...state,
        isManager: action.isManager,
      };

    default:
      return state;
  }
};

export default { authReducer };
