// source: proto/monitor.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.hr_system.monitor.Action', null, global);
goog.exportSymbol('proto.hr_system.monitor.Action.Enum', null, global);
goog.exportSymbol('proto.hr_system.monitor.DataPoint', null, global);
goog.exportSymbol('proto.hr_system.monitor.Location', null, global);
goog.exportSymbol('proto.hr_system.monitor.Location.Enum', null, global);
goog.exportSymbol('proto.hr_system.monitor.MetaData', null, global);
goog.exportSymbol('proto.hr_system.monitor.MonitorRequest', null, global);
goog.exportSymbol('proto.hr_system.monitor.MonitorResponse', null, global);
goog.exportSymbol('proto.hr_system.monitor.TableConfig', null, global);
goog.exportSymbol('proto.hr_system.monitor.TableConfig.Filter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.monitor.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.monitor.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.monitor.Action.displayName = 'proto.hr_system.monitor.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.monitor.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.monitor.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.monitor.Location.displayName = 'proto.hr_system.monitor.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.monitor.TableConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.monitor.TableConfig.repeatedFields_, null);
};
goog.inherits(proto.hr_system.monitor.TableConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.monitor.TableConfig.displayName = 'proto.hr_system.monitor.TableConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.monitor.MetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.monitor.MetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.monitor.MetaData.displayName = 'proto.hr_system.monitor.MetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.monitor.DataPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.monitor.DataPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.monitor.DataPoint.displayName = 'proto.hr_system.monitor.DataPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.monitor.MonitorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.monitor.MonitorRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.monitor.MonitorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.monitor.MonitorRequest.displayName = 'proto.hr_system.monitor.MonitorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.monitor.MonitorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.monitor.MonitorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.monitor.MonitorResponse.displayName = 'proto.hr_system.monitor.MonitorResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.monitor.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.monitor.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.monitor.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.Action.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.monitor.Action}
 */
proto.hr_system.monitor.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.monitor.Action;
  return proto.hr_system.monitor.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.monitor.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.monitor.Action}
 */
proto.hr_system.monitor.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.monitor.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.monitor.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.monitor.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.hr_system.monitor.Action.Enum = {
  UNKNOWN: 0,
  LOAD: 1,
  RELOAD: 2,
  GET: 3,
  POST: 4,
  PUT: 5,
  DELETE: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.monitor.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.monitor.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.monitor.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.Location.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.monitor.Location}
 */
proto.hr_system.monitor.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.monitor.Location;
  return proto.hr_system.monitor.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.monitor.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.monitor.Location}
 */
proto.hr_system.monitor.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.monitor.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.monitor.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.monitor.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.hr_system.monitor.Location.Enum = {
  UNKNOWN: 0,
  CN: 1,
  US: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.monitor.TableConfig.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.monitor.TableConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.monitor.TableConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.monitor.TableConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.TableConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    pageNumber: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    filtersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.monitor.TableConfig}
 */
proto.hr_system.monitor.TableConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.monitor.TableConfig;
  return proto.hr_system.monitor.TableConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.monitor.TableConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.monitor.TableConfig}
 */
proto.hr_system.monitor.TableConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.hr_system.monitor.TableConfig.Filter} */ (reader.readEnum());
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.monitor.TableConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.monitor.TableConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.monitor.TableConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.TableConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.hr_system.monitor.TableConfig.Filter = {
  UNKNOWN: 0,
  REVIEW_CYCLE: 1,
  SITE: 2,
  DEPARTMENT: 3,
  LEVEL: 4,
  MANAGER: 5,
  USER: 6,
  RATING: 7,
  NOMINATED: 8
};

/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.hr_system.monitor.TableConfig.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.monitor.TableConfig} returns this
 */
proto.hr_system.monitor.TableConfig.prototype.setPageSize = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.TableConfig} returns this
 */
proto.hr_system.monitor.TableConfig.prototype.clearPageSize = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.TableConfig.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.hr_system.monitor.TableConfig.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.monitor.TableConfig} returns this
 */
proto.hr_system.monitor.TableConfig.prototype.setPageNumber = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.TableConfig} returns this
 */
proto.hr_system.monitor.TableConfig.prototype.clearPageNumber = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.TableConfig.prototype.hasPageNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Filter filters = 3;
 * @return {!Array<!proto.hr_system.monitor.TableConfig.Filter>}
 */
proto.hr_system.monitor.TableConfig.prototype.getFiltersList = function() {
  return /** @type {!Array<!proto.hr_system.monitor.TableConfig.Filter>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.hr_system.monitor.TableConfig.Filter>} value
 * @return {!proto.hr_system.monitor.TableConfig} returns this
 */
proto.hr_system.monitor.TableConfig.prototype.setFiltersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.hr_system.monitor.TableConfig.Filter} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.monitor.TableConfig} returns this
 */
proto.hr_system.monitor.TableConfig.prototype.addFilters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.monitor.TableConfig} returns this
 */
proto.hr_system.monitor.TableConfig.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.monitor.MetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.monitor.MetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.monitor.MetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.MetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    tableConfig: (f = msg.getTableConfig()) && proto.hr_system.monitor.TableConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.monitor.MetaData}
 */
proto.hr_system.monitor.MetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.monitor.MetaData;
  return proto.hr_system.monitor.MetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.monitor.MetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.monitor.MetaData}
 */
proto.hr_system.monitor.MetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 2:
      var value = new proto.hr_system.monitor.TableConfig;
      reader.readMessage(value,proto.hr_system.monitor.TableConfig.deserializeBinaryFromReader);
      msg.setTableConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.monitor.MetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.monitor.MetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.monitor.MetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.MetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTableConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hr_system.monitor.TableConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.monitor.MetaData.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.monitor.MetaData} returns this
 */
proto.hr_system.monitor.MetaData.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.MetaData} returns this
 */
proto.hr_system.monitor.MetaData.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.MetaData.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TableConfig table_config = 2;
 * @return {?proto.hr_system.monitor.TableConfig}
 */
proto.hr_system.monitor.MetaData.prototype.getTableConfig = function() {
  return /** @type{?proto.hr_system.monitor.TableConfig} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.monitor.TableConfig, 2));
};


/**
 * @param {?proto.hr_system.monitor.TableConfig|undefined} value
 * @return {!proto.hr_system.monitor.MetaData} returns this
*/
proto.hr_system.monitor.MetaData.prototype.setTableConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.monitor.MetaData} returns this
 */
proto.hr_system.monitor.MetaData.prototype.clearTableConfig = function() {
  return this.setTableConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.MetaData.prototype.hasTableConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.monitor.DataPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.monitor.DataPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.monitor.DataPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.DataPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampInMs: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    location: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    page: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    api: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    action: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    durationInMs: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    metaData: (f = msg.getMetaData()) && proto.hr_system.monitor.MetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.monitor.DataPoint}
 */
proto.hr_system.monitor.DataPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.monitor.DataPoint;
  return proto.hr_system.monitor.DataPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.monitor.DataPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.monitor.DataPoint}
 */
proto.hr_system.monitor.DataPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestampInMs(value);
      break;
    case 2:
      var value = /** @type {!proto.hr_system.monitor.Location.Enum} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setApi(value);
      break;
    case 4:
      var value = /** @type {!proto.hr_system.monitor.Action.Enum} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationInMs(value);
      break;
    case 6:
      var value = new proto.hr_system.monitor.MetaData;
      reader.readMessage(value,proto.hr_system.monitor.MetaData.deserializeBinaryFromReader);
      msg.setMetaData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.monitor.DataPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.monitor.DataPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.monitor.DataPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.DataPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!proto.hr_system.monitor.Location.Enum} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {!proto.hr_system.monitor.Action.Enum} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMetaData();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.hr_system.monitor.MetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string timestamp_in_ms = 1;
 * @return {string}
 */
proto.hr_system.monitor.DataPoint.prototype.getTimestampInMs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.setTimestampInMs = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.clearTimestampInMs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.DataPoint.prototype.hasTimestampInMs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location.Enum location = 2;
 * @return {!proto.hr_system.monitor.Location.Enum}
 */
proto.hr_system.monitor.DataPoint.prototype.getLocation = function() {
  return /** @type {!proto.hr_system.monitor.Location.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.hr_system.monitor.Location.Enum} value
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.setLocation = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.clearLocation = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.DataPoint.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string page = 3;
 * @return {string}
 */
proto.hr_system.monitor.DataPoint.prototype.getPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.setPage = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.clearPage = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.DataPoint.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string api = 7;
 * @return {string}
 */
proto.hr_system.monitor.DataPoint.prototype.getApi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.setApi = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.clearApi = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.DataPoint.prototype.hasApi = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Action.Enum action = 4;
 * @return {!proto.hr_system.monitor.Action.Enum}
 */
proto.hr_system.monitor.DataPoint.prototype.getAction = function() {
  return /** @type {!proto.hr_system.monitor.Action.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.hr_system.monitor.Action.Enum} value
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.setAction = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.clearAction = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.DataPoint.prototype.hasAction = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 duration_in_ms = 5;
 * @return {number}
 */
proto.hr_system.monitor.DataPoint.prototype.getDurationInMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.setDurationInMs = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.clearDurationInMs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.DataPoint.prototype.hasDurationInMs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MetaData meta_data = 6;
 * @return {?proto.hr_system.monitor.MetaData}
 */
proto.hr_system.monitor.DataPoint.prototype.getMetaData = function() {
  return /** @type{?proto.hr_system.monitor.MetaData} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.monitor.MetaData, 6));
};


/**
 * @param {?proto.hr_system.monitor.MetaData|undefined} value
 * @return {!proto.hr_system.monitor.DataPoint} returns this
*/
proto.hr_system.monitor.DataPoint.prototype.setMetaData = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.monitor.DataPoint} returns this
 */
proto.hr_system.monitor.DataPoint.prototype.clearMetaData = function() {
  return this.setMetaData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.DataPoint.prototype.hasMetaData = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.monitor.MonitorRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.monitor.MonitorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.monitor.MonitorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.monitor.MonitorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.MonitorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataPointsList: jspb.Message.toObjectList(msg.getDataPointsList(),
    proto.hr_system.monitor.DataPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.monitor.MonitorRequest}
 */
proto.hr_system.monitor.MonitorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.monitor.MonitorRequest;
  return proto.hr_system.monitor.MonitorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.monitor.MonitorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.monitor.MonitorRequest}
 */
proto.hr_system.monitor.MonitorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.monitor.DataPoint;
      reader.readMessage(value,proto.hr_system.monitor.DataPoint.deserializeBinaryFromReader);
      msg.addDataPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.monitor.MonitorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.monitor.MonitorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.monitor.MonitorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.MonitorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hr_system.monitor.DataPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DataPoint data_points = 1;
 * @return {!Array<!proto.hr_system.monitor.DataPoint>}
 */
proto.hr_system.monitor.MonitorRequest.prototype.getDataPointsList = function() {
  return /** @type{!Array<!proto.hr_system.monitor.DataPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.monitor.DataPoint, 1));
};


/**
 * @param {!Array<!proto.hr_system.monitor.DataPoint>} value
 * @return {!proto.hr_system.monitor.MonitorRequest} returns this
*/
proto.hr_system.monitor.MonitorRequest.prototype.setDataPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.monitor.DataPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.monitor.DataPoint}
 */
proto.hr_system.monitor.MonitorRequest.prototype.addDataPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.monitor.DataPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.monitor.MonitorRequest} returns this
 */
proto.hr_system.monitor.MonitorRequest.prototype.clearDataPointsList = function() {
  return this.setDataPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.monitor.MonitorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.monitor.MonitorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.monitor.MonitorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.MonitorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataPointsCount: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.monitor.MonitorResponse}
 */
proto.hr_system.monitor.MonitorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.monitor.MonitorResponse;
  return proto.hr_system.monitor.MonitorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.monitor.MonitorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.monitor.MonitorResponse}
 */
proto.hr_system.monitor.MonitorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDataPointsCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.monitor.MonitorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.monitor.MonitorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.monitor.MonitorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.monitor.MonitorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 data_points_count = 1;
 * @return {number}
 */
proto.hr_system.monitor.MonitorResponse.prototype.getDataPointsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.monitor.MonitorResponse} returns this
 */
proto.hr_system.monitor.MonitorResponse.prototype.setDataPointsCount = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.monitor.MonitorResponse} returns this
 */
proto.hr_system.monitor.MonitorResponse.prototype.clearDataPointsCount = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.monitor.MonitorResponse.prototype.hasDataPointsCount = function() {
  return jspb.Message.getField(this, 1) != null;
};


export const { Action, DataPoint, Location, MetaData, MonitorRequest, MonitorResponse, TableConfig } = proto.hr_system.monitor
