import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth';
import charts from './charts';
import employees from './employees';
import entities from './entities';
import { fileUpload } from './fileUpload';
import perfRecords from './perfRecord';
import reviewCycles from './reviewCycles';
import snackbarContent from './snackbarContent';
import theme from './theme';

export const rootReducer = combineReducers({
  theme,
  employees,
  entities,
  auth: authReducer,
  reviewCycles,
  snackbarContent,
  fileUpload: fileUpload,
  charts,
  perfRecords,
});

export type AppStateType = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
