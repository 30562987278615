// source: proto/perf_tool_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

import * as proto_perf_record_pb from 'hr_web/proto/perf_record_pb';
goog.object.extend(proto, proto_perf_record_pb);
import * as proto_review_collection_pb from 'hr_web/proto/review_collection_pb';
goog.object.extend(proto, proto_review_collection_pb);
import * as proto_review_cycle_pb from 'hr_web/proto/review_cycle_pb';
goog.object.extend(proto, proto_review_cycle_pb);
import * as proto_review_question_pb from 'hr_web/proto/review_question_pb';
goog.object.extend(proto, proto_review_question_pb);
import * as proto_review_type_pb from 'hr_web/proto/review_type_pb';
goog.object.extend(proto, proto_review_type_pb);
goog.exportSymbol('proto.hr_system.CheckIsManagerRequest', null, global);
goog.exportSymbol('proto.hr_system.CheckIsManagerResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdatePerfRecordsRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdatePerfRecordsResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateReviewCollectionRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateReviewCollectionResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateReviewCycleRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateReviewCycleResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateReviewQuestionRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateReviewQuestionResponse', null, global);
goog.exportSymbol('proto.hr_system.CreatePerfAnalyticsCsvRequest', null, global);
goog.exportSymbol('proto.hr_system.DeleteReviewCollectionRequest', null, global);
goog.exportSymbol('proto.hr_system.DeleteReviewCollectionResponse', null, global);
goog.exportSymbol('proto.hr_system.DeleteReviewQuestionRequest', null, global);
goog.exportSymbol('proto.hr_system.DeleteReviewQuestionResponse', null, global);
goog.exportSymbol('proto.hr_system.GetPerfAnalyticsRequest', null, global);
goog.exportSymbol('proto.hr_system.GetPerfAnalyticsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo', null, global);
goog.exportSymbol('proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics', null, global);
goog.exportSymbol('proto.hr_system.GetPerfRecordsRequest', null, global);
goog.exportSymbol('proto.hr_system.GetReviewCollectionDeclineReasonRequest', null, global);
goog.exportSymbol('proto.hr_system.GetReviewCollectionDeclineReasonResponse', null, global);
goog.exportSymbol('proto.hr_system.GetReviewCollectionsRequest', null, global);
goog.exportSymbol('proto.hr_system.GetReviewCollectionsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetReviewCycleResponse', null, global);
goog.exportSymbol('proto.hr_system.GetReviewCyclesResponse', null, global);
goog.exportSymbol('proto.hr_system.GetReviewQuestionResponse', null, global);
goog.exportSymbol('proto.hr_system.GetReviewQuestionsRequest', null, global);
goog.exportSymbol('proto.hr_system.GetReviewQuestionsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetUserSnapshotRequest', null, global);
goog.exportSymbol('proto.hr_system.GetUserSnapshotResponse', null, global);
goog.exportSymbol('proto.hr_system.ListPerfRecordsResponse', null, global);
goog.exportSymbol('proto.hr_system.ReleaseReviewCollectionsRequest', null, global);
goog.exportSymbol('proto.hr_system.ReleaseReviewCollectionsResponse', null, global);
goog.exportSymbol('proto.hr_system.UpdateNominationRequest', null, global);
goog.exportSymbol('proto.hr_system.UpdateNominationResponse', null, global);
goog.exportSymbol('proto.hr_system.UploadReviewCollectionsRequest', null, global);
goog.exportSymbol('proto.hr_system.UploadReviewCollectionsResponse', null, global);
goog.exportSymbol('proto.hr_system.UploadedReview', null, global);
goog.exportSymbol('proto.hr_system.UploadedReviewCollection', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewCollectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetReviewCollectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewCollectionsRequest.displayName = 'proto.hr_system.GetReviewCollectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetReviewCollectionDeclineReasonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewCollectionDeclineReasonRequest.displayName = 'proto.hr_system.GetReviewCollectionDeclineReasonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetReviewCollectionDeclineReasonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewCollectionDeclineReasonResponse.displayName = 'proto.hr_system.GetReviewCollectionDeclineReasonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewCollectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetReviewCollectionsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetReviewCollectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewCollectionsResponse.displayName = 'proto.hr_system.GetReviewCollectionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ReleaseReviewCollectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.ReleaseReviewCollectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ReleaseReviewCollectionsRequest.displayName = 'proto.hr_system.ReleaseReviewCollectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ReleaseReviewCollectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.ReleaseReviewCollectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ReleaseReviewCollectionsResponse.displayName = 'proto.hr_system.ReleaseReviewCollectionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateReviewCollectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateReviewCollectionRequest.displayName = 'proto.hr_system.CreateOrUpdateReviewCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateReviewCollectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateReviewCollectionResponse.displayName = 'proto.hr_system.CreateOrUpdateReviewCollectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.DeleteReviewCollectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.DeleteReviewCollectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.DeleteReviewCollectionRequest.displayName = 'proto.hr_system.DeleteReviewCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.DeleteReviewCollectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.DeleteReviewCollectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.DeleteReviewCollectionResponse.displayName = 'proto.hr_system.DeleteReviewCollectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewCyclesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetReviewCyclesResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetReviewCyclesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewCyclesResponse.displayName = 'proto.hr_system.GetReviewCyclesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewCycleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetReviewCycleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewCycleResponse.displayName = 'proto.hr_system.GetReviewCycleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateReviewCycleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateReviewCycleRequest.displayName = 'proto.hr_system.CreateOrUpdateReviewCycleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateReviewCycleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateReviewCycleResponse.displayName = 'proto.hr_system.CreateOrUpdateReviewCycleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewQuestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetReviewQuestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewQuestionsRequest.displayName = 'proto.hr_system.GetReviewQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewQuestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetReviewQuestionsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetReviewQuestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewQuestionsResponse.displayName = 'proto.hr_system.GetReviewQuestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetReviewQuestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetReviewQuestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetReviewQuestionResponse.displayName = 'proto.hr_system.GetReviewQuestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.DeleteReviewQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.DeleteReviewQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.DeleteReviewQuestionRequest.displayName = 'proto.hr_system.DeleteReviewQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.DeleteReviewQuestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.DeleteReviewQuestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.DeleteReviewQuestionResponse.displayName = 'proto.hr_system.DeleteReviewQuestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateReviewQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateReviewQuestionRequest.displayName = 'proto.hr_system.CreateOrUpdateReviewQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateReviewQuestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateReviewQuestionResponse.displayName = 'proto.hr_system.CreateOrUpdateReviewQuestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CheckIsManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CheckIsManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CheckIsManagerRequest.displayName = 'proto.hr_system.CheckIsManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CheckIsManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CheckIsManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CheckIsManagerResponse.displayName = 'proto.hr_system.CheckIsManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPerfAnalyticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetPerfAnalyticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPerfAnalyticsRequest.displayName = 'proto.hr_system.GetPerfAnalyticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPerfAnalyticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetPerfAnalyticsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetPerfAnalyticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPerfAnalyticsResponse.displayName = 'proto.hr_system.GetPerfAnalyticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.displayName = 'proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.displayName = 'proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreatePerfAnalyticsCsvRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreatePerfAnalyticsCsvRequest.displayName = 'proto.hr_system.CreatePerfAnalyticsCsvRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserSnapshotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetUserSnapshotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserSnapshotRequest.displayName = 'proto.hr_system.GetUserSnapshotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserSnapshotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUserSnapshotResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUserSnapshotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserSnapshotResponse.displayName = 'proto.hr_system.GetUserSnapshotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.CreateOrUpdatePerfRecordsRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.CreateOrUpdatePerfRecordsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdatePerfRecordsRequest.displayName = 'proto.hr_system.CreateOrUpdatePerfRecordsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdatePerfRecordsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdatePerfRecordsResponse.displayName = 'proto.hr_system.CreateOrUpdatePerfRecordsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPerfRecordsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetPerfRecordsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPerfRecordsRequest.displayName = 'proto.hr_system.GetPerfRecordsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ListPerfRecordsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.ListPerfRecordsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.ListPerfRecordsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ListPerfRecordsResponse.displayName = 'proto.hr_system.ListPerfRecordsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UpdateNominationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.UpdateNominationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UpdateNominationRequest.displayName = 'proto.hr_system.UpdateNominationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UpdateNominationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.UpdateNominationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UpdateNominationResponse.displayName = 'proto.hr_system.UpdateNominationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UploadedReview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.UploadedReview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UploadedReview.displayName = 'proto.hr_system.UploadedReview';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UploadedReviewCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.UploadedReviewCollection.repeatedFields_, null);
};
goog.inherits(proto.hr_system.UploadedReviewCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UploadedReviewCollection.displayName = 'proto.hr_system.UploadedReviewCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UploadReviewCollectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.UploadReviewCollectionsRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.UploadReviewCollectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UploadReviewCollectionsRequest.displayName = 'proto.hr_system.UploadReviewCollectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UploadReviewCollectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.UploadReviewCollectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UploadReviewCollectionsResponse.displayName = 'proto.hr_system.UploadReviewCollectionsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewCollectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewCollectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCollectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    revieweeId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    reviewerId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    reviewType: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    reviewStatus: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    excludeReviews: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewCollectionsRequest}
 */
proto.hr_system.GetReviewCollectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewCollectionsRequest;
  return proto.hr_system.GetReviewCollectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewCollectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewCollectionsRequest}
 */
proto.hr_system.GetReviewCollectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevieweeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewerId(value);
      break;
    case 4:
      var value = /** @type {!proto.hr_system.ReviewType.Enum} */ (reader.readEnum());
      msg.setReviewType(value);
      break;
    case 5:
      var value = /** @type {!proto.hr_system.ReviewStatus.Enum} */ (reader.readEnum());
      msg.setReviewStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExcludeReviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewCollectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewCollectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCollectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewStatus.Enum} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 reviewee_id = 2;
 * @return {number}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.getRevieweeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.setRevieweeId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.clearRevieweeId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.hasRevieweeId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 reviewer_id = 3;
 * @return {number}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.getReviewerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.setReviewerId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.clearReviewerId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.hasReviewerId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReviewType.Enum review_type = 4;
 * @return {!proto.hr_system.ReviewType.Enum}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.getReviewType = function() {
  return /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.hr_system.ReviewType.Enum} value
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.setReviewType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.clearReviewType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.hasReviewType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ReviewStatus.Enum review_status = 5;
 * @return {!proto.hr_system.ReviewStatus.Enum}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.getReviewStatus = function() {
  return /** @type {!proto.hr_system.ReviewStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.hr_system.ReviewStatus.Enum} value
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.setReviewStatus = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.clearReviewStatus = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.hasReviewStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 exclude_reviews = 6;
 * @return {number}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.getExcludeReviews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.setExcludeReviews = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewCollectionsRequest} returns this
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.clearExcludeReviews = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCollectionsRequest.prototype.hasExcludeReviews = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewCollectionDeclineReasonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewCollectionDeclineReasonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCollectionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewCollectionDeclineReasonRequest}
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewCollectionDeclineReasonRequest;
  return proto.hr_system.GetReviewCollectionDeclineReasonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewCollectionDeclineReasonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewCollectionDeclineReasonRequest}
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCollectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewCollectionDeclineReasonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewCollectionDeclineReasonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_collection_id = 1;
 * @return {number}
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.prototype.getReviewCollectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetReviewCollectionDeclineReasonRequest} returns this
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.prototype.setReviewCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewCollectionDeclineReasonRequest} returns this
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.prototype.clearReviewCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCollectionDeclineReasonRequest.prototype.hasReviewCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewCollectionDeclineReasonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewCollectionDeclineReasonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    declineReason: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewCollectionDeclineReasonResponse}
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewCollectionDeclineReasonResponse;
  return proto.hr_system.GetReviewCollectionDeclineReasonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewCollectionDeclineReasonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewCollectionDeclineReasonResponse}
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeclineReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewCollectionDeclineReasonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewCollectionDeclineReasonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string decline_reason = 1;
 * @return {string}
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.prototype.getDeclineReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetReviewCollectionDeclineReasonResponse} returns this
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.prototype.setDeclineReason = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewCollectionDeclineReasonResponse} returns this
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.prototype.clearDeclineReason = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCollectionDeclineReasonResponse.prototype.hasDeclineReason = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetReviewCollectionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewCollectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewCollectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewCollectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCollectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCollectionsList: jspb.Message.toObjectList(msg.getReviewCollectionsList(),
    proto_review_collection_pb.ReviewCollection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewCollectionsResponse}
 */
proto.hr_system.GetReviewCollectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewCollectionsResponse;
  return proto.hr_system.GetReviewCollectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewCollectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewCollectionsResponse}
 */
proto.hr_system.GetReviewCollectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_review_collection_pb.ReviewCollection;
      reader.readMessage(value,proto_review_collection_pb.ReviewCollection.deserializeBinaryFromReader);
      msg.addReviewCollections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewCollectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewCollectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewCollectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCollectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewCollectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_review_collection_pb.ReviewCollection.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewCollection review_collections = 1;
 * @return {!Array<!proto.hr_system.ReviewCollection>}
 */
proto.hr_system.GetReviewCollectionsResponse.prototype.getReviewCollectionsList = function() {
  return /** @type{!Array<!proto.hr_system.ReviewCollection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_review_collection_pb.ReviewCollection, 1));
};


/**
 * @param {!Array<!proto.hr_system.ReviewCollection>} value
 * @return {!proto.hr_system.GetReviewCollectionsResponse} returns this
*/
proto.hr_system.GetReviewCollectionsResponse.prototype.setReviewCollectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.ReviewCollection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.ReviewCollection}
 */
proto.hr_system.GetReviewCollectionsResponse.prototype.addReviewCollections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.ReviewCollection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetReviewCollectionsResponse} returns this
 */
proto.hr_system.GetReviewCollectionsResponse.prototype.clearReviewCollectionsList = function() {
  return this.setReviewCollectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ReleaseReviewCollectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ReleaseReviewCollectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReleaseReviewCollectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    revieweeId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    isReleased: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
    forPerf: (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f,
    forPromo: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ReleaseReviewCollectionsRequest;
  return proto.hr_system.ReleaseReviewCollectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ReleaseReviewCollectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevieweeId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReleased(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForPerf(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForPromo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ReleaseReviewCollectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ReleaseReviewCollectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReleaseReviewCollectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 reviewee_id = 2;
 * @return {number}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.getRevieweeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.setRevieweeId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.clearRevieweeId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.hasRevieweeId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_released = 3;
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.getIsReleased = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.setIsReleased = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.clearIsReleased = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.hasIsReleased = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool for_perf = 4;
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.getForPerf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.setForPerf = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.clearForPerf = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.hasForPerf = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool for_promo = 5;
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.getForPromo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.setForPromo = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReleaseReviewCollectionsRequest} returns this
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.clearForPromo = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsRequest.prototype.hasForPromo = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ReleaseReviewCollectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ReleaseReviewCollectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ReleaseReviewCollectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReleaseReviewCollectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    revieweeId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ReleaseReviewCollectionsResponse}
 */
proto.hr_system.ReleaseReviewCollectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ReleaseReviewCollectionsResponse;
  return proto.hr_system.ReleaseReviewCollectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ReleaseReviewCollectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ReleaseReviewCollectionsResponse}
 */
proto.hr_system.ReleaseReviewCollectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevieweeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ReleaseReviewCollectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ReleaseReviewCollectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ReleaseReviewCollectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReleaseReviewCollectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 reviewee_id = 1;
 * @return {number}
 */
proto.hr_system.ReleaseReviewCollectionsResponse.prototype.getRevieweeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.ReleaseReviewCollectionsResponse} returns this
 */
proto.hr_system.ReleaseReviewCollectionsResponse.prototype.setRevieweeId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReleaseReviewCollectionsResponse} returns this
 */
proto.hr_system.ReleaseReviewCollectionsResponse.prototype.clearRevieweeId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReleaseReviewCollectionsResponse.prototype.hasRevieweeId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateReviewCollectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateReviewCollectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCollection: (f = msg.getReviewCollection()) && proto_review_collection_pb.ReviewCollection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateReviewCollectionRequest}
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateReviewCollectionRequest;
  return proto.hr_system.CreateOrUpdateReviewCollectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateReviewCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateReviewCollectionRequest}
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_review_collection_pb.ReviewCollection;
      reader.readMessage(value,proto_review_collection_pb.ReviewCollection.deserializeBinaryFromReader);
      msg.setReviewCollection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateReviewCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateReviewCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewCollection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_review_collection_pb.ReviewCollection.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewCollection review_collection = 1;
 * @return {?proto.hr_system.ReviewCollection}
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.prototype.getReviewCollection = function() {
  return /** @type{?proto.hr_system.ReviewCollection} */ (
    jspb.Message.getWrapperField(this, proto_review_collection_pb.ReviewCollection, 1));
};


/**
 * @param {?proto.hr_system.ReviewCollection|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateReviewCollectionRequest} returns this
*/
proto.hr_system.CreateOrUpdateReviewCollectionRequest.prototype.setReviewCollection = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateReviewCollectionRequest} returns this
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.prototype.clearReviewCollection = function() {
  return this.setReviewCollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateReviewCollectionRequest.prototype.hasReviewCollection = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateReviewCollectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateReviewCollectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCollectionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateReviewCollectionResponse}
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateReviewCollectionResponse;
  return proto.hr_system.CreateOrUpdateReviewCollectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateReviewCollectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateReviewCollectionResponse}
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCollectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateReviewCollectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateReviewCollectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_collection_id = 1;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.prototype.getReviewCollectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateReviewCollectionResponse} returns this
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.prototype.setReviewCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateReviewCollectionResponse} returns this
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.prototype.clearReviewCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateReviewCollectionResponse.prototype.hasReviewCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.DeleteReviewCollectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.DeleteReviewCollectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.DeleteReviewCollectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteReviewCollectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCollectionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.DeleteReviewCollectionRequest}
 */
proto.hr_system.DeleteReviewCollectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.DeleteReviewCollectionRequest;
  return proto.hr_system.DeleteReviewCollectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.DeleteReviewCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.DeleteReviewCollectionRequest}
 */
proto.hr_system.DeleteReviewCollectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCollectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.DeleteReviewCollectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.DeleteReviewCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.DeleteReviewCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteReviewCollectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_collection_id = 1;
 * @return {number}
 */
proto.hr_system.DeleteReviewCollectionRequest.prototype.getReviewCollectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.DeleteReviewCollectionRequest} returns this
 */
proto.hr_system.DeleteReviewCollectionRequest.prototype.setReviewCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.DeleteReviewCollectionRequest} returns this
 */
proto.hr_system.DeleteReviewCollectionRequest.prototype.clearReviewCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.DeleteReviewCollectionRequest.prototype.hasReviewCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.DeleteReviewCollectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.DeleteReviewCollectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.DeleteReviewCollectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteReviewCollectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCollectionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.DeleteReviewCollectionResponse}
 */
proto.hr_system.DeleteReviewCollectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.DeleteReviewCollectionResponse;
  return proto.hr_system.DeleteReviewCollectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.DeleteReviewCollectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.DeleteReviewCollectionResponse}
 */
proto.hr_system.DeleteReviewCollectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCollectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.DeleteReviewCollectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.DeleteReviewCollectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.DeleteReviewCollectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteReviewCollectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_collection_id = 1;
 * @return {number}
 */
proto.hr_system.DeleteReviewCollectionResponse.prototype.getReviewCollectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.DeleteReviewCollectionResponse} returns this
 */
proto.hr_system.DeleteReviewCollectionResponse.prototype.setReviewCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.DeleteReviewCollectionResponse} returns this
 */
proto.hr_system.DeleteReviewCollectionResponse.prototype.clearReviewCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.DeleteReviewCollectionResponse.prototype.hasReviewCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetReviewCyclesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewCyclesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewCyclesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewCyclesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCyclesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCyclesList: jspb.Message.toObjectList(msg.getReviewCyclesList(),
    proto_review_cycle_pb.ReviewCycle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewCyclesResponse}
 */
proto.hr_system.GetReviewCyclesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewCyclesResponse;
  return proto.hr_system.GetReviewCyclesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewCyclesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewCyclesResponse}
 */
proto.hr_system.GetReviewCyclesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_review_cycle_pb.ReviewCycle;
      reader.readMessage(value,proto_review_cycle_pb.ReviewCycle.deserializeBinaryFromReader);
      msg.addReviewCycles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewCyclesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewCyclesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewCyclesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCyclesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewCyclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_review_cycle_pb.ReviewCycle.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewCycle review_cycles = 1;
 * @return {!Array<!proto.hr_system.ReviewCycle>}
 */
proto.hr_system.GetReviewCyclesResponse.prototype.getReviewCyclesList = function() {
  return /** @type{!Array<!proto.hr_system.ReviewCycle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_review_cycle_pb.ReviewCycle, 1));
};


/**
 * @param {!Array<!proto.hr_system.ReviewCycle>} value
 * @return {!proto.hr_system.GetReviewCyclesResponse} returns this
*/
proto.hr_system.GetReviewCyclesResponse.prototype.setReviewCyclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.ReviewCycle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.ReviewCycle}
 */
proto.hr_system.GetReviewCyclesResponse.prototype.addReviewCycles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.ReviewCycle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetReviewCyclesResponse} returns this
 */
proto.hr_system.GetReviewCyclesResponse.prototype.clearReviewCyclesList = function() {
  return this.setReviewCyclesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewCycleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewCycleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewCycleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCycleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycle: (f = msg.getReviewCycle()) && proto_review_cycle_pb.ReviewCycle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewCycleResponse}
 */
proto.hr_system.GetReviewCycleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewCycleResponse;
  return proto.hr_system.GetReviewCycleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewCycleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewCycleResponse}
 */
proto.hr_system.GetReviewCycleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_review_cycle_pb.ReviewCycle;
      reader.readMessage(value,proto_review_cycle_pb.ReviewCycle.deserializeBinaryFromReader);
      msg.setReviewCycle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewCycleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewCycleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewCycleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewCycleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewCycle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_review_cycle_pb.ReviewCycle.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewCycle review_cycle = 1;
 * @return {?proto.hr_system.ReviewCycle}
 */
proto.hr_system.GetReviewCycleResponse.prototype.getReviewCycle = function() {
  return /** @type{?proto.hr_system.ReviewCycle} */ (
    jspb.Message.getWrapperField(this, proto_review_cycle_pb.ReviewCycle, 1));
};


/**
 * @param {?proto.hr_system.ReviewCycle|undefined} value
 * @return {!proto.hr_system.GetReviewCycleResponse} returns this
*/
proto.hr_system.GetReviewCycleResponse.prototype.setReviewCycle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetReviewCycleResponse} returns this
 */
proto.hr_system.GetReviewCycleResponse.prototype.clearReviewCycle = function() {
  return this.setReviewCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewCycleResponse.prototype.hasReviewCycle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateReviewCycleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateReviewCycleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycle: (f = msg.getReviewCycle()) && proto_review_cycle_pb.ReviewCycle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateReviewCycleRequest}
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateReviewCycleRequest;
  return proto.hr_system.CreateOrUpdateReviewCycleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateReviewCycleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateReviewCycleRequest}
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_review_cycle_pb.ReviewCycle;
      reader.readMessage(value,proto_review_cycle_pb.ReviewCycle.deserializeBinaryFromReader);
      msg.setReviewCycle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateReviewCycleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateReviewCycleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewCycle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_review_cycle_pb.ReviewCycle.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewCycle review_cycle = 1;
 * @return {?proto.hr_system.ReviewCycle}
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.prototype.getReviewCycle = function() {
  return /** @type{?proto.hr_system.ReviewCycle} */ (
    jspb.Message.getWrapperField(this, proto_review_cycle_pb.ReviewCycle, 1));
};


/**
 * @param {?proto.hr_system.ReviewCycle|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateReviewCycleRequest} returns this
*/
proto.hr_system.CreateOrUpdateReviewCycleRequest.prototype.setReviewCycle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateReviewCycleRequest} returns this
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.prototype.clearReviewCycle = function() {
  return this.setReviewCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateReviewCycleRequest.prototype.hasReviewCycle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateReviewCycleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateReviewCycleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateReviewCycleResponse}
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateReviewCycleResponse;
  return proto.hr_system.CreateOrUpdateReviewCycleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateReviewCycleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateReviewCycleResponse}
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateReviewCycleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateReviewCycleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateReviewCycleResponse} returns this
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateReviewCycleResponse} returns this
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateReviewCycleResponse.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewQuestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewQuestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewQuestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reviewType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    reviewQuestionId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewQuestionsRequest}
 */
proto.hr_system.GetReviewQuestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewQuestionsRequest;
  return proto.hr_system.GetReviewQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewQuestionsRequest}
 */
proto.hr_system.GetReviewQuestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 2:
      var value = /** @type {!proto.hr_system.ReviewType.Enum} */ (reader.readEnum());
      msg.setReviewType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewQuestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetReviewQuestionsRequest} returns this
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewQuestionsRequest} returns this
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReviewType.Enum review_type = 2;
 * @return {!proto.hr_system.ReviewType.Enum}
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.getReviewType = function() {
  return /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.hr_system.ReviewType.Enum} value
 * @return {!proto.hr_system.GetReviewQuestionsRequest} returns this
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.setReviewType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewQuestionsRequest} returns this
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.clearReviewType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.hasReviewType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 review_question_id = 3;
 * @return {number}
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.getReviewQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetReviewQuestionsRequest} returns this
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.setReviewQuestionId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetReviewQuestionsRequest} returns this
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.clearReviewQuestionId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewQuestionsRequest.prototype.hasReviewQuestionId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetReviewQuestionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewQuestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewQuestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewQuestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewQuestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewQuestionsList: jspb.Message.toObjectList(msg.getReviewQuestionsList(),
    proto_review_question_pb.ReviewQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewQuestionsResponse}
 */
proto.hr_system.GetReviewQuestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewQuestionsResponse;
  return proto.hr_system.GetReviewQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewQuestionsResponse}
 */
proto.hr_system.GetReviewQuestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_review_question_pb.ReviewQuestion;
      reader.readMessage(value,proto_review_question_pb.ReviewQuestion.deserializeBinaryFromReader);
      msg.addReviewQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewQuestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewQuestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_review_question_pb.ReviewQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewQuestion review_questions = 1;
 * @return {!Array<!proto.hr_system.ReviewQuestion>}
 */
proto.hr_system.GetReviewQuestionsResponse.prototype.getReviewQuestionsList = function() {
  return /** @type{!Array<!proto.hr_system.ReviewQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_review_question_pb.ReviewQuestion, 1));
};


/**
 * @param {!Array<!proto.hr_system.ReviewQuestion>} value
 * @return {!proto.hr_system.GetReviewQuestionsResponse} returns this
*/
proto.hr_system.GetReviewQuestionsResponse.prototype.setReviewQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.ReviewQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.ReviewQuestion}
 */
proto.hr_system.GetReviewQuestionsResponse.prototype.addReviewQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.ReviewQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetReviewQuestionsResponse} returns this
 */
proto.hr_system.GetReviewQuestionsResponse.prototype.clearReviewQuestionsList = function() {
  return this.setReviewQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetReviewQuestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetReviewQuestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetReviewQuestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewQuestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewQuestion: (f = msg.getReviewQuestion()) && proto_review_question_pb.ReviewQuestion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetReviewQuestionResponse}
 */
proto.hr_system.GetReviewQuestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetReviewQuestionResponse;
  return proto.hr_system.GetReviewQuestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetReviewQuestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetReviewQuestionResponse}
 */
proto.hr_system.GetReviewQuestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_review_question_pb.ReviewQuestion;
      reader.readMessage(value,proto_review_question_pb.ReviewQuestion.deserializeBinaryFromReader);
      msg.setReviewQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetReviewQuestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetReviewQuestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetReviewQuestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetReviewQuestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_review_question_pb.ReviewQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewQuestion review_question = 1;
 * @return {?proto.hr_system.ReviewQuestion}
 */
proto.hr_system.GetReviewQuestionResponse.prototype.getReviewQuestion = function() {
  return /** @type{?proto.hr_system.ReviewQuestion} */ (
    jspb.Message.getWrapperField(this, proto_review_question_pb.ReviewQuestion, 1));
};


/**
 * @param {?proto.hr_system.ReviewQuestion|undefined} value
 * @return {!proto.hr_system.GetReviewQuestionResponse} returns this
*/
proto.hr_system.GetReviewQuestionResponse.prototype.setReviewQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetReviewQuestionResponse} returns this
 */
proto.hr_system.GetReviewQuestionResponse.prototype.clearReviewQuestion = function() {
  return this.setReviewQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetReviewQuestionResponse.prototype.hasReviewQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.DeleteReviewQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.DeleteReviewQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.DeleteReviewQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteReviewQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewQuestionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.DeleteReviewQuestionRequest}
 */
proto.hr_system.DeleteReviewQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.DeleteReviewQuestionRequest;
  return proto.hr_system.DeleteReviewQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.DeleteReviewQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.DeleteReviewQuestionRequest}
 */
proto.hr_system.DeleteReviewQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.DeleteReviewQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.DeleteReviewQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.DeleteReviewQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteReviewQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_question_id = 1;
 * @return {number}
 */
proto.hr_system.DeleteReviewQuestionRequest.prototype.getReviewQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.DeleteReviewQuestionRequest} returns this
 */
proto.hr_system.DeleteReviewQuestionRequest.prototype.setReviewQuestionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.DeleteReviewQuestionRequest} returns this
 */
proto.hr_system.DeleteReviewQuestionRequest.prototype.clearReviewQuestionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.DeleteReviewQuestionRequest.prototype.hasReviewQuestionId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.DeleteReviewQuestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.DeleteReviewQuestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.DeleteReviewQuestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteReviewQuestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewQuestionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.DeleteReviewQuestionResponse}
 */
proto.hr_system.DeleteReviewQuestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.DeleteReviewQuestionResponse;
  return proto.hr_system.DeleteReviewQuestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.DeleteReviewQuestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.DeleteReviewQuestionResponse}
 */
proto.hr_system.DeleteReviewQuestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.DeleteReviewQuestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.DeleteReviewQuestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.DeleteReviewQuestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteReviewQuestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_question_id = 1;
 * @return {number}
 */
proto.hr_system.DeleteReviewQuestionResponse.prototype.getReviewQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.DeleteReviewQuestionResponse} returns this
 */
proto.hr_system.DeleteReviewQuestionResponse.prototype.setReviewQuestionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.DeleteReviewQuestionResponse} returns this
 */
proto.hr_system.DeleteReviewQuestionResponse.prototype.clearReviewQuestionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.DeleteReviewQuestionResponse.prototype.hasReviewQuestionId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateReviewQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateReviewQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewQuestion: (f = msg.getReviewQuestion()) && proto_review_question_pb.ReviewQuestion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateReviewQuestionRequest}
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateReviewQuestionRequest;
  return proto.hr_system.CreateOrUpdateReviewQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateReviewQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateReviewQuestionRequest}
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_review_question_pb.ReviewQuestion;
      reader.readMessage(value,proto_review_question_pb.ReviewQuestion.deserializeBinaryFromReader);
      msg.setReviewQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateReviewQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateReviewQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_review_question_pb.ReviewQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewQuestion review_question = 1;
 * @return {?proto.hr_system.ReviewQuestion}
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.prototype.getReviewQuestion = function() {
  return /** @type{?proto.hr_system.ReviewQuestion} */ (
    jspb.Message.getWrapperField(this, proto_review_question_pb.ReviewQuestion, 1));
};


/**
 * @param {?proto.hr_system.ReviewQuestion|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateReviewQuestionRequest} returns this
*/
proto.hr_system.CreateOrUpdateReviewQuestionRequest.prototype.setReviewQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateReviewQuestionRequest} returns this
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.prototype.clearReviewQuestion = function() {
  return this.setReviewQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateReviewQuestionRequest.prototype.hasReviewQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateReviewQuestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateReviewQuestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewQuestionId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateReviewQuestionResponse}
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateReviewQuestionResponse;
  return proto.hr_system.CreateOrUpdateReviewQuestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateReviewQuestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateReviewQuestionResponse}
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateReviewQuestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateReviewQuestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_question_id = 1;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.prototype.getReviewQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateReviewQuestionResponse} returns this
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.prototype.setReviewQuestionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateReviewQuestionResponse} returns this
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.prototype.clearReviewQuestionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateReviewQuestionResponse.prototype.hasReviewQuestionId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CheckIsManagerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CheckIsManagerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CheckIsManagerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CheckIsManagerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reportId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    isDirect: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CheckIsManagerRequest}
 */
proto.hr_system.CheckIsManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CheckIsManagerRequest;
  return proto.hr_system.CheckIsManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CheckIsManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CheckIsManagerRequest}
 */
proto.hr_system.CheckIsManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReportId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDirect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CheckIsManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CheckIsManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CheckIsManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CheckIsManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.CheckIsManagerRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CheckIsManagerRequest} returns this
 */
proto.hr_system.CheckIsManagerRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CheckIsManagerRequest} returns this
 */
proto.hr_system.CheckIsManagerRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CheckIsManagerRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 report_id = 2;
 * @return {number}
 */
proto.hr_system.CheckIsManagerRequest.prototype.getReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CheckIsManagerRequest} returns this
 */
proto.hr_system.CheckIsManagerRequest.prototype.setReportId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CheckIsManagerRequest} returns this
 */
proto.hr_system.CheckIsManagerRequest.prototype.clearReportId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CheckIsManagerRequest.prototype.hasReportId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_direct = 3;
 * @return {boolean}
 */
proto.hr_system.CheckIsManagerRequest.prototype.getIsDirect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.CheckIsManagerRequest} returns this
 */
proto.hr_system.CheckIsManagerRequest.prototype.setIsDirect = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CheckIsManagerRequest} returns this
 */
proto.hr_system.CheckIsManagerRequest.prototype.clearIsDirect = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CheckIsManagerRequest.prototype.hasIsDirect = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CheckIsManagerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CheckIsManagerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CheckIsManagerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CheckIsManagerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isManager: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CheckIsManagerResponse}
 */
proto.hr_system.CheckIsManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CheckIsManagerResponse;
  return proto.hr_system.CheckIsManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CheckIsManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CheckIsManagerResponse}
 */
proto.hr_system.CheckIsManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CheckIsManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CheckIsManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CheckIsManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CheckIsManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_manager = 1;
 * @return {boolean}
 */
proto.hr_system.CheckIsManagerResponse.prototype.getIsManager = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.CheckIsManagerResponse} returns this
 */
proto.hr_system.CheckIsManagerResponse.prototype.setIsManager = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CheckIsManagerResponse} returns this
 */
proto.hr_system.CheckIsManagerResponse.prototype.clearIsManager = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CheckIsManagerResponse.prototype.hasIsManager = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPerfAnalyticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPerfAnalyticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfAnalyticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forStatistics: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    pageNumber: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    pageSize: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    site: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    department: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    levels: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    ratings: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    userId: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    managerUserId: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    managerEmail: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    nominatedOnly: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest}
 */
proto.hr_system.GetPerfAnalyticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPerfAnalyticsRequest;
  return proto.hr_system.GetPerfAnalyticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPerfAnalyticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest}
 */
proto.hr_system.GetPerfAnalyticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setForStatistics(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSite(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevels(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRatings(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setManagerUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerEmail(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNominatedOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPerfAnalyticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPerfAnalyticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfAnalyticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 for_statistics = 11;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getForStatistics = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setForStatistics = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearForStatistics = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasForStatistics = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 page_number = 7;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearPageNumber = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasPageNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 page_size = 8;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearPageSize = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string site = 2;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getSite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setSite = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearSite = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasSite = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string department = 3;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearDepartment = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string levels = 4;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getLevels = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setLevels = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearLevels = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasLevels = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string ratings = 12;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getRatings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setRatings = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearRatings = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasRatings = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 user_id = 9;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 manager_user_id = 10;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getManagerUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setManagerUserId = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearManagerUserId = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasManagerUserId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string manager_email = 5;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getManagerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setManagerEmail = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearManagerEmail = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasManagerEmail = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 nominated_only = 6;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.getNominatedOnly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.setNominatedOnly = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsRequest} returns this
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.clearNominatedOnly = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsRequest.prototype.hasNominatedOnly = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetPerfAnalyticsResponse.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPerfAnalyticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPerfAnalyticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfAnalyticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    perfAnalyticsUserInfosList: jspb.Message.toObjectList(msg.getPerfAnalyticsUserInfosList(),
    proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.toObject, includeInstance),
    totalCount: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    perfStatisticsList: jspb.Message.toObjectList(msg.getPerfStatisticsList(),
    proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse}
 */
proto.hr_system.GetPerfAnalyticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPerfAnalyticsResponse;
  return proto.hr_system.GetPerfAnalyticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPerfAnalyticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse}
 */
proto.hr_system.GetPerfAnalyticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo;
      reader.readMessage(value,proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.deserializeBinaryFromReader);
      msg.addPerfAnalyticsUserInfos(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 3:
      var value = new proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics;
      reader.readMessage(value,proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.deserializeBinaryFromReader);
      msg.addPerfStatistics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPerfAnalyticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPerfAnalyticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfAnalyticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerfAnalyticsUserInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPerfStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    employeeId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    companyEmail: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    firstName: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    middleName: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    lastName: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    legalName: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    displayName: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    level: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    site: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    departmentsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    rating: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    isNominated: (f = jspb.Message.getBooleanField(msg, 13)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo;
  return proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSite(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addDepartments(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRating(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNominated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional int64 user_id = 12;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string employee_id = 1;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getEmployeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setEmployeeId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearEmployeeId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasEmployeeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string company_email = 2;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getCompanyEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setCompanyEmail = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearCompanyEmail = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasCompanyEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string first_name = 8;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearFirstName = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string middle_name = 9;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setMiddleName = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearMiddleName = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasMiddleName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string last_name = 10;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setLastName = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearLastName = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string legal_name = 11;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setLegalName = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearLegalName = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasLegalName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setDisplayName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearDisplayName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string level = 4;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setLevel = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearLevel = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string site = 5;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getSite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setSite = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearSite = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasSite = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string departments = 6;
 * @return {!Array<string>}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getDepartmentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.addDepartments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};


/**
 * optional string rating = 7;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setRating = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearRating = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasRating = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_nominated = 13;
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.getIsNominated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.setIsNominated = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.clearIsNominated = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo.prototype.hasIsNominated = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    rating: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    count: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics;
  return proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRating(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string rating = 1;
 * @return {string}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.getRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.setRating = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.clearRating = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.hasRating = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.setCount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.clearCount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics.prototype.hasCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated PerfAnalyticsUserInfo perf_analytics_user_infos = 1;
 * @return {!Array<!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo>}
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.getPerfAnalyticsUserInfosList = function() {
  return /** @type{!Array<!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo, 1));
};


/**
 * @param {!Array<!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo>} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse} returns this
*/
proto.hr_system.GetPerfAnalyticsResponse.prototype.setPerfAnalyticsUserInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo}
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.addPerfAnalyticsUserInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.GetPerfAnalyticsResponse.PerfAnalyticsUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.clearPerfAnalyticsUserInfosList = function() {
  return this.setPerfAnalyticsUserInfosList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.clearTotalCount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.hasTotalCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated PerfStatistics perf_statistics = 3;
 * @return {!Array<!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics>}
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.getPerfStatisticsList = function() {
  return /** @type{!Array<!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics, 3));
};


/**
 * @param {!Array<!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics>} value
 * @return {!proto.hr_system.GetPerfAnalyticsResponse} returns this
*/
proto.hr_system.GetPerfAnalyticsResponse.prototype.setPerfStatisticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics}
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.addPerfStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hr_system.GetPerfAnalyticsResponse.PerfStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetPerfAnalyticsResponse} returns this
 */
proto.hr_system.GetPerfAnalyticsResponse.prototype.clearPerfStatisticsList = function() {
  return this.setPerfStatisticsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreatePerfAnalyticsCsvRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreatePerfAnalyticsCsvRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    companyEmail: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreatePerfAnalyticsCsvRequest}
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreatePerfAnalyticsCsvRequest;
  return proto.hr_system.CreatePerfAnalyticsCsvRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreatePerfAnalyticsCsvRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreatePerfAnalyticsCsvRequest}
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreatePerfAnalyticsCsvRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreatePerfAnalyticsCsvRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreatePerfAnalyticsCsvRequest} returns this
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreatePerfAnalyticsCsvRequest} returns this
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string company_email = 2;
 * @return {string}
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.getCompanyEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.CreatePerfAnalyticsCsvRequest} returns this
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.setCompanyEmail = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreatePerfAnalyticsCsvRequest} returns this
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.clearCompanyEmail = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreatePerfAnalyticsCsvRequest.prototype.hasCompanyEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserSnapshotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserSnapshotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserSnapshotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserSnapshotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reviewCycleId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserSnapshotRequest}
 */
proto.hr_system.GetUserSnapshotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserSnapshotRequest;
  return proto.hr_system.GetUserSnapshotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserSnapshotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserSnapshotRequest}
 */
proto.hr_system.GetUserSnapshotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserSnapshotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserSnapshotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserSnapshotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserSnapshotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.hr_system.GetUserSnapshotRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUserSnapshotRequest} returns this
 */
proto.hr_system.GetUserSnapshotRequest.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotRequest} returns this
 */
proto.hr_system.GetUserSnapshotRequest.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 review_cycle_id = 2;
 * @return {number}
 */
proto.hr_system.GetUserSnapshotRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUserSnapshotRequest} returns this
 */
proto.hr_system.GetUserSnapshotRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotRequest} returns this
 */
proto.hr_system.GetUserSnapshotRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUserSnapshotResponse.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserSnapshotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserSnapshotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserSnapshotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyEmail: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    firstName: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    middleName: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    lastName: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    legalName: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    displayName: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    startingDate: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    title: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    site: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    jobType: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    directManager: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    departmentsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserSnapshotResponse}
 */
proto.hr_system.GetUserSnapshotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserSnapshotResponse;
  return proto.hr_system.GetUserSnapshotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserSnapshotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserSnapshotResponse}
 */
proto.hr_system.GetUserSnapshotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartingDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSite(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectManager(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addDepartments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserSnapshotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserSnapshotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserSnapshotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
};


/**
 * optional string company_email = 2;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getCompanyEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setCompanyEmail = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearCompanyEmail = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasCompanyEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearFirstName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string middle_name = 4;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setMiddleName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearMiddleName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasMiddleName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setLastName = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearLastName = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string legal_name = 6;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setLegalName = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearLegalName = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasLegalName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string display_name = 7;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearDisplayName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string starting_date = 14;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getStartingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setStartingDate = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearStartingDate = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasStartingDate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string title = 9;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string site = 10;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getSite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setSite = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearSite = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string job_type = 15;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getJobType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setJobType = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearJobType = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasJobType = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string direct_manager = 11;
 * @return {string}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getDirectManager = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setDirectManager = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearDirectManager = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.hasDirectManager = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated string departments = 12;
 * @return {!Array<string>}
 */
proto.hr_system.GetUserSnapshotResponse.prototype.getDepartmentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.addDepartments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserSnapshotResponse} returns this
 */
proto.hr_system.GetUserSnapshotResponse.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdatePerfRecordsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdatePerfRecordsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    perfRecordsList: jspb.Message.toObjectList(msg.getPerfRecordsList(),
    proto_perf_record_pb.PerfRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdatePerfRecordsRequest}
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdatePerfRecordsRequest;
  return proto.hr_system.CreateOrUpdatePerfRecordsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdatePerfRecordsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdatePerfRecordsRequest}
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_perf_record_pb.PerfRecord;
      reader.readMessage(value,proto_perf_record_pb.PerfRecord.deserializeBinaryFromReader);
      msg.addPerfRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdatePerfRecordsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdatePerfRecordsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerfRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_perf_record_pb.PerfRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PerfRecord perf_records = 1;
 * @return {!Array<!proto.hr_system.PerfRecord>}
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.prototype.getPerfRecordsList = function() {
  return /** @type{!Array<!proto.hr_system.PerfRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_perf_record_pb.PerfRecord, 1));
};


/**
 * @param {!Array<!proto.hr_system.PerfRecord>} value
 * @return {!proto.hr_system.CreateOrUpdatePerfRecordsRequest} returns this
*/
proto.hr_system.CreateOrUpdatePerfRecordsRequest.prototype.setPerfRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.PerfRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.PerfRecord}
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.prototype.addPerfRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.PerfRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.CreateOrUpdatePerfRecordsRequest} returns this
 */
proto.hr_system.CreateOrUpdatePerfRecordsRequest.prototype.clearPerfRecordsList = function() {
  return this.setPerfRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdatePerfRecordsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdatePerfRecordsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsCount: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdatePerfRecordsResponse}
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdatePerfRecordsResponse;
  return proto.hr_system.CreateOrUpdatePerfRecordsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdatePerfRecordsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdatePerfRecordsResponse}
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecordsCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdatePerfRecordsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdatePerfRecordsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 records_count = 1;
 * @return {number}
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.prototype.getRecordsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdatePerfRecordsResponse} returns this
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.prototype.setRecordsCount = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdatePerfRecordsResponse} returns this
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.prototype.clearRecordsCount = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdatePerfRecordsResponse.prototype.hasRecordsCount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPerfRecordsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPerfRecordsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPerfRecordsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfRecordsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    userIds: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    reviewCycleId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPerfRecordsRequest}
 */
proto.hr_system.GetPerfRecordsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPerfRecordsRequest;
  return proto.hr_system.GetPerfRecordsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPerfRecordsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPerfRecordsRequest}
 */
proto.hr_system.GetPerfRecordsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPerfRecordsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPerfRecordsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPerfRecordsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPerfRecordsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.hr_system.GetPerfRecordsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfRecordsRequest} returns this
 */
proto.hr_system.GetPerfRecordsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfRecordsRequest} returns this
 */
proto.hr_system.GetPerfRecordsRequest.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfRecordsRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string user_ids = 3;
 * @return {string}
 */
proto.hr_system.GetPerfRecordsRequest.prototype.getUserIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPerfRecordsRequest} returns this
 */
proto.hr_system.GetPerfRecordsRequest.prototype.setUserIds = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfRecordsRequest} returns this
 */
proto.hr_system.GetPerfRecordsRequest.prototype.clearUserIds = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfRecordsRequest.prototype.hasUserIds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 review_cycle_id = 2;
 * @return {number}
 */
proto.hr_system.GetPerfRecordsRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPerfRecordsRequest} returns this
 */
proto.hr_system.GetPerfRecordsRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPerfRecordsRequest} returns this
 */
proto.hr_system.GetPerfRecordsRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPerfRecordsRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.ListPerfRecordsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ListPerfRecordsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ListPerfRecordsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ListPerfRecordsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ListPerfRecordsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    perfRecordsList: jspb.Message.toObjectList(msg.getPerfRecordsList(),
    proto_perf_record_pb.PerfRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ListPerfRecordsResponse}
 */
proto.hr_system.ListPerfRecordsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ListPerfRecordsResponse;
  return proto.hr_system.ListPerfRecordsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ListPerfRecordsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ListPerfRecordsResponse}
 */
proto.hr_system.ListPerfRecordsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_perf_record_pb.PerfRecord;
      reader.readMessage(value,proto_perf_record_pb.PerfRecord.deserializeBinaryFromReader);
      msg.addPerfRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ListPerfRecordsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ListPerfRecordsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ListPerfRecordsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ListPerfRecordsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerfRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_perf_record_pb.PerfRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PerfRecord perf_records = 1;
 * @return {!Array<!proto.hr_system.PerfRecord>}
 */
proto.hr_system.ListPerfRecordsResponse.prototype.getPerfRecordsList = function() {
  return /** @type{!Array<!proto.hr_system.PerfRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_perf_record_pb.PerfRecord, 1));
};


/**
 * @param {!Array<!proto.hr_system.PerfRecord>} value
 * @return {!proto.hr_system.ListPerfRecordsResponse} returns this
*/
proto.hr_system.ListPerfRecordsResponse.prototype.setPerfRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.PerfRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.PerfRecord}
 */
proto.hr_system.ListPerfRecordsResponse.prototype.addPerfRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.PerfRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.ListPerfRecordsResponse} returns this
 */
proto.hr_system.ListPerfRecordsResponse.prototype.clearPerfRecordsList = function() {
  return this.setPerfRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UpdateNominationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UpdateNominationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UpdateNominationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UpdateNominationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reviewCycleId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    isNominated: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UpdateNominationRequest}
 */
proto.hr_system.UpdateNominationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UpdateNominationRequest;
  return proto.hr_system.UpdateNominationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UpdateNominationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UpdateNominationRequest}
 */
proto.hr_system.UpdateNominationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNominated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UpdateNominationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UpdateNominationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UpdateNominationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UpdateNominationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.hr_system.UpdateNominationRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.UpdateNominationRequest} returns this
 */
proto.hr_system.UpdateNominationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UpdateNominationRequest} returns this
 */
proto.hr_system.UpdateNominationRequest.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UpdateNominationRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 review_cycle_id = 2;
 * @return {number}
 */
proto.hr_system.UpdateNominationRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.UpdateNominationRequest} returns this
 */
proto.hr_system.UpdateNominationRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UpdateNominationRequest} returns this
 */
proto.hr_system.UpdateNominationRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UpdateNominationRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_nominated = 3;
 * @return {boolean}
 */
proto.hr_system.UpdateNominationRequest.prototype.getIsNominated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.UpdateNominationRequest} returns this
 */
proto.hr_system.UpdateNominationRequest.prototype.setIsNominated = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UpdateNominationRequest} returns this
 */
proto.hr_system.UpdateNominationRequest.prototype.clearIsNominated = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UpdateNominationRequest.prototype.hasIsNominated = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UpdateNominationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UpdateNominationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UpdateNominationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UpdateNominationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    perfRecordId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UpdateNominationResponse}
 */
proto.hr_system.UpdateNominationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UpdateNominationResponse;
  return proto.hr_system.UpdateNominationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UpdateNominationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UpdateNominationResponse}
 */
proto.hr_system.UpdateNominationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerfRecordId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UpdateNominationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UpdateNominationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UpdateNominationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UpdateNominationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 perf_record_id = 1;
 * @return {number}
 */
proto.hr_system.UpdateNominationResponse.prototype.getPerfRecordId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.UpdateNominationResponse} returns this
 */
proto.hr_system.UpdateNominationResponse.prototype.setPerfRecordId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UpdateNominationResponse} returns this
 */
proto.hr_system.UpdateNominationResponse.prototype.clearPerfRecordId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UpdateNominationResponse.prototype.hasPerfRecordId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UploadedReview.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UploadedReview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UploadedReview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadedReview.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewQuestionIndex: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    answer: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UploadedReview}
 */
proto.hr_system.UploadedReview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UploadedReview;
  return proto.hr_system.UploadedReview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UploadedReview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UploadedReview}
 */
proto.hr_system.UploadedReview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewQuestionIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UploadedReview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UploadedReview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UploadedReview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadedReview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 review_question_index = 1;
 * @return {number}
 */
proto.hr_system.UploadedReview.prototype.getReviewQuestionIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.UploadedReview} returns this
 */
proto.hr_system.UploadedReview.prototype.setReviewQuestionIndex = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UploadedReview} returns this
 */
proto.hr_system.UploadedReview.prototype.clearReviewQuestionIndex = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UploadedReview.prototype.hasReviewQuestionIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string answer = 2;
 * @return {string}
 */
proto.hr_system.UploadedReview.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UploadedReview} returns this
 */
proto.hr_system.UploadedReview.prototype.setAnswer = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UploadedReview} returns this
 */
proto.hr_system.UploadedReview.prototype.clearAnswer = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UploadedReview.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.UploadedReviewCollection.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UploadedReviewCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UploadedReviewCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UploadedReviewCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadedReviewCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleName: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reviewerEmail: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    revieweeEmail: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    reviewType: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    uploadedReviewsList: jspb.Message.toObjectList(msg.getUploadedReviewsList(),
    proto.hr_system.UploadedReview.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UploadedReviewCollection}
 */
proto.hr_system.UploadedReviewCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UploadedReviewCollection;
  return proto.hr_system.UploadedReviewCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UploadedReviewCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UploadedReviewCollection}
 */
proto.hr_system.UploadedReviewCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewCycleName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewerEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevieweeEmail(value);
      break;
    case 4:
      var value = /** @type {!proto.hr_system.ReviewType.Enum} */ (reader.readEnum());
      msg.setReviewType(value);
      break;
    case 5:
      var value = new proto.hr_system.UploadedReview;
      reader.readMessage(value,proto.hr_system.UploadedReview.deserializeBinaryFromReader);
      msg.addUploadedReviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UploadedReviewCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UploadedReviewCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UploadedReviewCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadedReviewCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUploadedReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.hr_system.UploadedReview.serializeBinaryToWriter
    );
  }
};


/**
 * optional string review_cycle_name = 1;
 * @return {string}
 */
proto.hr_system.UploadedReviewCollection.prototype.getReviewCycleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.setReviewCycleName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.clearReviewCycleName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UploadedReviewCollection.prototype.hasReviewCycleName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string reviewer_email = 2;
 * @return {string}
 */
proto.hr_system.UploadedReviewCollection.prototype.getReviewerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.setReviewerEmail = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.clearReviewerEmail = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UploadedReviewCollection.prototype.hasReviewerEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string reviewee_email = 3;
 * @return {string}
 */
proto.hr_system.UploadedReviewCollection.prototype.getRevieweeEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.setRevieweeEmail = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.clearRevieweeEmail = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UploadedReviewCollection.prototype.hasRevieweeEmail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReviewType.Enum review_type = 4;
 * @return {!proto.hr_system.ReviewType.Enum}
 */
proto.hr_system.UploadedReviewCollection.prototype.getReviewType = function() {
  return /** @type {!proto.hr_system.ReviewType.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.hr_system.ReviewType.Enum} value
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.setReviewType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.clearReviewType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UploadedReviewCollection.prototype.hasReviewType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated UploadedReview uploaded_reviews = 5;
 * @return {!Array<!proto.hr_system.UploadedReview>}
 */
proto.hr_system.UploadedReviewCollection.prototype.getUploadedReviewsList = function() {
  return /** @type{!Array<!proto.hr_system.UploadedReview>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.UploadedReview, 5));
};


/**
 * @param {!Array<!proto.hr_system.UploadedReview>} value
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
*/
proto.hr_system.UploadedReviewCollection.prototype.setUploadedReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hr_system.UploadedReview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UploadedReview}
 */
proto.hr_system.UploadedReviewCollection.prototype.addUploadedReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hr_system.UploadedReview, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UploadedReviewCollection} returns this
 */
proto.hr_system.UploadedReviewCollection.prototype.clearUploadedReviewsList = function() {
  return this.setUploadedReviewsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.UploadReviewCollectionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UploadReviewCollectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UploadReviewCollectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UploadReviewCollectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadReviewCollectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadedReviewCollectionsList: jspb.Message.toObjectList(msg.getUploadedReviewCollectionsList(),
    proto.hr_system.UploadedReviewCollection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UploadReviewCollectionsRequest}
 */
proto.hr_system.UploadReviewCollectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UploadReviewCollectionsRequest;
  return proto.hr_system.UploadReviewCollectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UploadReviewCollectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UploadReviewCollectionsRequest}
 */
proto.hr_system.UploadReviewCollectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.UploadedReviewCollection;
      reader.readMessage(value,proto.hr_system.UploadedReviewCollection.deserializeBinaryFromReader);
      msg.addUploadedReviewCollections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UploadReviewCollectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UploadReviewCollectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UploadReviewCollectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadReviewCollectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadedReviewCollectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hr_system.UploadedReviewCollection.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UploadedReviewCollection uploaded_review_collections = 1;
 * @return {!Array<!proto.hr_system.UploadedReviewCollection>}
 */
proto.hr_system.UploadReviewCollectionsRequest.prototype.getUploadedReviewCollectionsList = function() {
  return /** @type{!Array<!proto.hr_system.UploadedReviewCollection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.UploadedReviewCollection, 1));
};


/**
 * @param {!Array<!proto.hr_system.UploadedReviewCollection>} value
 * @return {!proto.hr_system.UploadReviewCollectionsRequest} returns this
*/
proto.hr_system.UploadReviewCollectionsRequest.prototype.setUploadedReviewCollectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.UploadedReviewCollection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UploadedReviewCollection}
 */
proto.hr_system.UploadReviewCollectionsRequest.prototype.addUploadedReviewCollections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.UploadedReviewCollection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UploadReviewCollectionsRequest} returns this
 */
proto.hr_system.UploadReviewCollectionsRequest.prototype.clearUploadedReviewCollectionsList = function() {
  return this.setUploadedReviewCollectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UploadReviewCollectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UploadReviewCollectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UploadReviewCollectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadReviewCollectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadedCount: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UploadReviewCollectionsResponse}
 */
proto.hr_system.UploadReviewCollectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UploadReviewCollectionsResponse;
  return proto.hr_system.UploadReviewCollectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UploadReviewCollectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UploadReviewCollectionsResponse}
 */
proto.hr_system.UploadReviewCollectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUploadedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UploadReviewCollectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UploadReviewCollectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UploadReviewCollectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadReviewCollectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 uploaded_count = 1;
 * @return {number}
 */
proto.hr_system.UploadReviewCollectionsResponse.prototype.getUploadedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.UploadReviewCollectionsResponse} returns this
 */
proto.hr_system.UploadReviewCollectionsResponse.prototype.setUploadedCount = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UploadReviewCollectionsResponse} returns this
 */
proto.hr_system.UploadReviewCollectionsResponse.prototype.clearUploadedCount = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UploadReviewCollectionsResponse.prototype.hasUploadedCount = function() {
  return jspb.Message.getField(this, 1) != null;
};


export const { CheckIsManagerRequest, CheckIsManagerResponse, CreateOrUpdatePerfRecordsRequest, CreateOrUpdatePerfRecordsResponse, CreateOrUpdateReviewCollectionRequest, CreateOrUpdateReviewCollectionResponse, CreateOrUpdateReviewCycleRequest, CreateOrUpdateReviewCycleResponse, CreateOrUpdateReviewQuestionRequest, CreateOrUpdateReviewQuestionResponse, CreatePerfAnalyticsCsvRequest, DeleteReviewCollectionRequest, DeleteReviewCollectionResponse, DeleteReviewQuestionRequest, DeleteReviewQuestionResponse, GetPerfAnalyticsRequest, GetPerfAnalyticsResponse, GetPerfRecordsRequest, GetReviewCollectionDeclineReasonRequest, GetReviewCollectionDeclineReasonResponse, GetReviewCollectionsRequest, GetReviewCollectionsResponse, GetReviewCycleResponse, GetReviewCyclesResponse, GetReviewQuestionResponse, GetReviewQuestionsRequest, GetReviewQuestionsResponse, GetUserSnapshotRequest, GetUserSnapshotResponse, ListPerfRecordsResponse, ReleaseReviewCollectionsRequest, ReleaseReviewCollectionsResponse, UpdateNominationRequest, UpdateNominationResponse, UploadReviewCollectionsRequest, UploadReviewCollectionsResponse, UploadedReview, UploadedReviewCollection } = proto.hr_system
