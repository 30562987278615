// source: proto/org_chart_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

import * as proto_entities_pb from 'hr_web/proto/entities_pb';
goog.object.extend(proto, proto_entities_pb);
import * as proto_report_type_pb from 'hr_web/proto/report_type_pb';
goog.object.extend(proto, proto_report_type_pb);
goog.exportSymbol('proto.hr_system.BatchCreateOrUpdateUsersRequest', null, global);
goog.exportSymbol('proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser', null, global);
goog.exportSymbol('proto.hr_system.BatchCreateOrUpdateUsersResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateBusinessUnitRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateBusinessUnitResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateCostCenterRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateCostCenterResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateDepartmentRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateDepartmentResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateEmploymentTypeRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateEmploymentTypeResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateJobFunctionRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateJobFunctionResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateJobTypeRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateJobTypeResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateLevelRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateLevelResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateSiteRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateSiteResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateTeamRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateTeamResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateTitleRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateTitleResponse', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateUserRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdateUserResponse', null, global);
goog.exportSymbol('proto.hr_system.DeleteResponse', null, global);
goog.exportSymbol('proto.hr_system.EffectiveDateEntry', null, global);
goog.exportSymbol('proto.hr_system.EffectiveDateEntry.EffectedAttribute', null, global);
goog.exportSymbol('proto.hr_system.GetAuditRecordsRequest', null, global);
goog.exportSymbol('proto.hr_system.GetAuditRecordsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetBusinessUnitResponse', null, global);
goog.exportSymbol('proto.hr_system.GetBusinessUnitsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetCostCenterResponse', null, global);
goog.exportSymbol('proto.hr_system.GetCostCentersResponse', null, global);
goog.exportSymbol('proto.hr_system.GetDepartmentResponse', null, global);
goog.exportSymbol('proto.hr_system.GetDepartmentsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetEmploymentTypeResponse', null, global);
goog.exportSymbol('proto.hr_system.GetEmploymentTypesResponse', null, global);
goog.exportSymbol('proto.hr_system.GetJobFunctionResponse', null, global);
goog.exportSymbol('proto.hr_system.GetJobFunctionsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetJobTypeResponse', null, global);
goog.exportSymbol('proto.hr_system.GetJobTypesResponse', null, global);
goog.exportSymbol('proto.hr_system.GetLevelResponse', null, global);
goog.exportSymbol('proto.hr_system.GetLevelsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetSiteResponse', null, global);
goog.exportSymbol('proto.hr_system.GetSitesResponse', null, global);
goog.exportSymbol('proto.hr_system.GetTeamResponse', null, global);
goog.exportSymbol('proto.hr_system.GetTeamsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetTitleResponse', null, global);
goog.exportSymbol('proto.hr_system.GetTitlesResponse', null, global);
goog.exportSymbol('proto.hr_system.GetUserBasicInfoRequest', null, global);
goog.exportSymbol('proto.hr_system.GetUserBasicInfoResponse', null, global);
goog.exportSymbol('proto.hr_system.GetUserOrgChartsRequest', null, global);
goog.exportSymbol('proto.hr_system.GetUserOrgChartsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetUserResponse', null, global);
goog.exportSymbol('proto.hr_system.GetUserSummariesRequest', null, global);
goog.exportSymbol('proto.hr_system.GetUserSummariesResponse', null, global);
goog.exportSymbol('proto.hr_system.GetUsersRequest', null, global);
goog.exportSymbol('proto.hr_system.GetUsersResponse', null, global);
goog.exportSymbol('proto.hr_system.RelatedUserNode', null, global);
goog.exportSymbol('proto.hr_system.ReportRelation', null, global);
goog.exportSymbol('proto.hr_system.ResponseHeader', null, global);
goog.exportSymbol('proto.hr_system.UserNode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ResponseHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.ResponseHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ResponseHeader.displayName = 'proto.hr_system.ResponseHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.BatchCreateOrUpdateUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.BatchCreateOrUpdateUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.BatchCreateOrUpdateUsersRequest.displayName = 'proto.hr_system.BatchCreateOrUpdateUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.repeatedFields_, null);
};
goog.inherits(proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.displayName = 'proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.BatchCreateOrUpdateUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.BatchCreateOrUpdateUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.BatchCreateOrUpdateUsersResponse.displayName = 'proto.hr_system.BatchCreateOrUpdateUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.CreateOrUpdateUserRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateUserRequest.displayName = 'proto.hr_system.CreateOrUpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.EffectiveDateEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.EffectiveDateEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.EffectiveDateEntry.displayName = 'proto.hr_system.EffectiveDateEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateUserResponse.displayName = 'proto.hr_system.CreateOrUpdateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUsersRequest.displayName = 'proto.hr_system.GetUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUsersResponse.displayName = 'proto.hr_system.GetUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUserResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserResponse.displayName = 'proto.hr_system.GetUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserSummariesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetUserSummariesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserSummariesRequest.displayName = 'proto.hr_system.GetUserSummariesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserSummariesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUserSummariesResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUserSummariesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserSummariesResponse.displayName = 'proto.hr_system.GetUserSummariesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserBasicInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUserBasicInfoRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUserBasicInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserBasicInfoRequest.displayName = 'proto.hr_system.GetUserBasicInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserBasicInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUserBasicInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUserBasicInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserBasicInfoResponse.displayName = 'proto.hr_system.GetUserBasicInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetTeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetTeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetTeamResponse.displayName = 'proto.hr_system.GetTeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetTeamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetTeamsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetTeamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetTeamsResponse.displayName = 'proto.hr_system.GetTeamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateTeamRequest.displayName = 'proto.hr_system.CreateOrUpdateTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateTeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateTeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateTeamResponse.displayName = 'proto.hr_system.CreateOrUpdateTeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetDepartmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetDepartmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetDepartmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetDepartmentsResponse.displayName = 'proto.hr_system.GetDepartmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetDepartmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetDepartmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetDepartmentResponse.displayName = 'proto.hr_system.GetDepartmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateDepartmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateDepartmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateDepartmentRequest.displayName = 'proto.hr_system.CreateOrUpdateDepartmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateDepartmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateDepartmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateDepartmentResponse.displayName = 'proto.hr_system.CreateOrUpdateDepartmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetJobFunctionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetJobFunctionsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetJobFunctionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetJobFunctionsResponse.displayName = 'proto.hr_system.GetJobFunctionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetJobFunctionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetJobFunctionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetJobFunctionResponse.displayName = 'proto.hr_system.GetJobFunctionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateJobFunctionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateJobFunctionRequest.displayName = 'proto.hr_system.CreateOrUpdateJobFunctionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateJobFunctionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateJobFunctionResponse.displayName = 'proto.hr_system.CreateOrUpdateJobFunctionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetCostCentersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetCostCentersResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetCostCentersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetCostCentersResponse.displayName = 'proto.hr_system.GetCostCentersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetCostCenterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetCostCenterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetCostCenterResponse.displayName = 'proto.hr_system.GetCostCenterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateCostCenterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateCostCenterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateCostCenterRequest.displayName = 'proto.hr_system.CreateOrUpdateCostCenterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateCostCenterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateCostCenterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateCostCenterResponse.displayName = 'proto.hr_system.CreateOrUpdateCostCenterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetJobTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetJobTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetJobTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetJobTypesResponse.displayName = 'proto.hr_system.GetJobTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetJobTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetJobTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetJobTypeResponse.displayName = 'proto.hr_system.GetJobTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateJobTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateJobTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateJobTypeRequest.displayName = 'proto.hr_system.CreateOrUpdateJobTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateJobTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateJobTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateJobTypeResponse.displayName = 'proto.hr_system.CreateOrUpdateJobTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetBusinessUnitsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetBusinessUnitsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetBusinessUnitsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetBusinessUnitsResponse.displayName = 'proto.hr_system.GetBusinessUnitsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetBusinessUnitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetBusinessUnitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetBusinessUnitResponse.displayName = 'proto.hr_system.GetBusinessUnitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateBusinessUnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateBusinessUnitRequest.displayName = 'proto.hr_system.CreateOrUpdateBusinessUnitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateBusinessUnitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateBusinessUnitResponse.displayName = 'proto.hr_system.CreateOrUpdateBusinessUnitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetEmploymentTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetEmploymentTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetEmploymentTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetEmploymentTypesResponse.displayName = 'proto.hr_system.GetEmploymentTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetEmploymentTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetEmploymentTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetEmploymentTypeResponse.displayName = 'proto.hr_system.GetEmploymentTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateEmploymentTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateEmploymentTypeRequest.displayName = 'proto.hr_system.CreateOrUpdateEmploymentTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateEmploymentTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateEmploymentTypeResponse.displayName = 'proto.hr_system.CreateOrUpdateEmploymentTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetLevelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetLevelsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetLevelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetLevelsResponse.displayName = 'proto.hr_system.GetLevelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetLevelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetLevelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetLevelResponse.displayName = 'proto.hr_system.GetLevelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateLevelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateLevelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateLevelRequest.displayName = 'proto.hr_system.CreateOrUpdateLevelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateLevelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateLevelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateLevelResponse.displayName = 'proto.hr_system.CreateOrUpdateLevelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetSitesResponse.displayName = 'proto.hr_system.GetSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetSiteResponse.displayName = 'proto.hr_system.GetSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateSiteRequest.displayName = 'proto.hr_system.CreateOrUpdateSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateSiteResponse.displayName = 'proto.hr_system.CreateOrUpdateSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetTitlesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetTitlesResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetTitlesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetTitlesResponse.displayName = 'proto.hr_system.GetTitlesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetTitleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetTitleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetTitleResponse.displayName = 'proto.hr_system.GetTitleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateTitleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateTitleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateTitleRequest.displayName = 'proto.hr_system.CreateOrUpdateTitleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdateTitleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdateTitleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdateTitleResponse.displayName = 'proto.hr_system.CreateOrUpdateTitleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetAuditRecordsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetAuditRecordsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetAuditRecordsRequest.displayName = 'proto.hr_system.GetAuditRecordsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetAuditRecordsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetAuditRecordsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetAuditRecordsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetAuditRecordsResponse.displayName = 'proto.hr_system.GetAuditRecordsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.DeleteResponse.displayName = 'proto.hr_system.DeleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ReportRelation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.ReportRelation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ReportRelation.displayName = 'proto.hr_system.ReportRelation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UserNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.UserNode.repeatedFields_, null);
};
goog.inherits(proto.hr_system.UserNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UserNode.displayName = 'proto.hr_system.UserNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserOrgChartsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUserOrgChartsRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUserOrgChartsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserOrgChartsRequest.displayName = 'proto.hr_system.GetUserOrgChartsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.RelatedUserNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.RelatedUserNode.repeatedFields_, null);
};
goog.inherits(proto.hr_system.RelatedUserNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.RelatedUserNode.displayName = 'proto.hr_system.RelatedUserNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetUserOrgChartsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetUserOrgChartsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetUserOrgChartsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetUserOrgChartsResponse.displayName = 'proto.hr_system.GetUserOrgChartsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ResponseHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ResponseHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ResponseHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ResponseHeader.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ResponseHeader}
 */
proto.hr_system.ResponseHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ResponseHeader;
  return proto.hr_system.ResponseHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ResponseHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ResponseHeader}
 */
proto.hr_system.ResponseHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ResponseHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ResponseHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ResponseHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ResponseHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.BatchCreateOrUpdateUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createOrUpdateUsersList: jspb.Message.toObjectList(msg.getCreateOrUpdateUsersList(),
    proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.BatchCreateOrUpdateUsersRequest;
  return proto.hr_system.BatchCreateOrUpdateUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser;
      reader.readMessage(value,proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.deserializeBinaryFromReader);
      msg.addCreateOrUpdateUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.BatchCreateOrUpdateUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateOrUpdateUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    toCreate: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    toUpdateDepartments: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    toUpdateCostCenters: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
    toUpdateBusinessUnits: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f,
    toUpdateDirectManagers: (f = jspb.Message.getBooleanField(msg, 9)) == null ? undefined : f,
    toUpdateDottedManagers: (f = jspb.Message.getBooleanField(msg, 10)) == null ? undefined : f,
    user: (f = msg.getUser()) && proto_entities_pb.User.toObject(includeInstance, f),
    directManagerCompanyEmailsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    dottedManagerCompanyEmailsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    effectiveDateEntriesList: jspb.Message.toObjectList(msg.getEffectiveDateEntriesList(),
    proto.hr_system.EffectiveDateEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser;
  return proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToCreate(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToUpdateDepartments(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToUpdateCostCenters(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToUpdateBusinessUnits(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToUpdateDirectManagers(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToUpdateDottedManagers(value);
      break;
    case 3:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDirectManagerCompanyEmails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addDottedManagerCompanyEmails(value);
      break;
    case 6:
      var value = new proto.hr_system.EffectiveDateEntry;
      reader.readMessage(value,proto.hr_system.EffectiveDateEntry.deserializeBinaryFromReader);
      msg.addEffectiveDateEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getDirectManagerCompanyEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getDottedManagerCompanyEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getEffectiveDateEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.hr_system.EffectiveDateEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool to_create = 1;
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getToCreate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setToCreate = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearToCreate = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.hasToCreate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool to_update_departments = 2;
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getToUpdateDepartments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setToUpdateDepartments = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearToUpdateDepartments = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.hasToUpdateDepartments = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool to_update_cost_centers = 7;
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getToUpdateCostCenters = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setToUpdateCostCenters = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearToUpdateCostCenters = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.hasToUpdateCostCenters = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool to_update_business_units = 8;
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getToUpdateBusinessUnits = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setToUpdateBusinessUnits = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearToUpdateBusinessUnits = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.hasToUpdateBusinessUnits = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool to_update_direct_managers = 9;
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getToUpdateDirectManagers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setToUpdateDirectManagers = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearToUpdateDirectManagers = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.hasToUpdateDirectManagers = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool to_update_dotted_managers = 10;
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getToUpdateDottedManagers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setToUpdateDottedManagers = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearToUpdateDottedManagers = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.hasToUpdateDottedManagers = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional User user = 3;
 * @return {?proto.hr_system.User}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getUser = function() {
  return /** @type{?proto.hr_system.User} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.User, 3));
};


/**
 * @param {?proto.hr_system.User|undefined} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
*/
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string direct_manager_company_emails = 4;
 * @return {!Array<string>}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getDirectManagerCompanyEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setDirectManagerCompanyEmailsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.addDirectManagerCompanyEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearDirectManagerCompanyEmailsList = function() {
  return this.setDirectManagerCompanyEmailsList([]);
};


/**
 * repeated string dotted_manager_company_emails = 5;
 * @return {!Array<string>}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getDottedManagerCompanyEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setDottedManagerCompanyEmailsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.addDottedManagerCompanyEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearDottedManagerCompanyEmailsList = function() {
  return this.setDottedManagerCompanyEmailsList([]);
};


/**
 * repeated EffectiveDateEntry effective_date_entries = 6;
 * @return {!Array<!proto.hr_system.EffectiveDateEntry>}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.getEffectiveDateEntriesList = function() {
  return /** @type{!Array<!proto.hr_system.EffectiveDateEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.EffectiveDateEntry, 6));
};


/**
 * @param {!Array<!proto.hr_system.EffectiveDateEntry>} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
*/
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.setEffectiveDateEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.hr_system.EffectiveDateEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.EffectiveDateEntry}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.addEffectiveDateEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.hr_system.EffectiveDateEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser.prototype.clearEffectiveDateEntriesList = function() {
  return this.setEffectiveDateEntriesList([]);
};


/**
 * repeated CreateOrUpdateUser create_or_update_users = 1;
 * @return {!Array<!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser>}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.prototype.getCreateOrUpdateUsersList = function() {
  return /** @type{!Array<!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser, 1));
};


/**
 * @param {!Array<!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser>} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest} returns this
*/
proto.hr_system.BatchCreateOrUpdateUsersRequest.prototype.setCreateOrUpdateUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser}
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.prototype.addCreateOrUpdateUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.BatchCreateOrUpdateUsersRequest.CreateOrUpdateUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersRequest} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersRequest.prototype.clearCreateOrUpdateUsersList = function() {
  return this.setCreateOrUpdateUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.BatchCreateOrUpdateUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userCompanyEmailsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersResponse}
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.BatchCreateOrUpdateUsersResponse;
  return proto.hr_system.BatchCreateOrUpdateUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersResponse}
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserCompanyEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.BatchCreateOrUpdateUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.BatchCreateOrUpdateUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserCompanyEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string user_company_emails = 1;
 * @return {!Array<string>}
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.prototype.getUserCompanyEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersResponse} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.prototype.setUserCompanyEmailsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersResponse} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.prototype.addUserCompanyEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.BatchCreateOrUpdateUsersResponse} returns this
 */
proto.hr_system.BatchCreateOrUpdateUsersResponse.prototype.clearUserCompanyEmailsList = function() {
  return this.setUserCompanyEmailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.CreateOrUpdateUserRequest.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto_entities_pb.User.toObject(includeInstance, f),
    directManagerUserIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    directReportUserIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dottedManagerUserIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    dottedReportUserIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    effectiveDateEntriesList: jspb.Message.toObjectList(msg.getEffectiveDateEntriesList(),
    proto.hr_system.EffectiveDateEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateUserRequest}
 */
proto.hr_system.CreateOrUpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateUserRequest;
  return proto.hr_system.CreateOrUpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateUserRequest}
 */
proto.hr_system.CreateOrUpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addDirectManagerUserIds(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addDirectReportUserIds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addDottedManagerUserIds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addDottedReportUserIds(value);
      break;
    case 6:
      var value = new proto.hr_system.EffectiveDateEntry;
      reader.readMessage(value,proto.hr_system.EffectiveDateEntry.deserializeBinaryFromReader);
      msg.addEffectiveDateEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getDirectManagerUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      2,
      f
    );
  }
  f = message.getDirectReportUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      3,
      f
    );
  }
  f = message.getDottedManagerUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      4,
      f
    );
  }
  f = message.getDottedReportUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      5,
      f
    );
  }
  f = message.getEffectiveDateEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.hr_system.EffectiveDateEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.hr_system.User}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.getUser = function() {
  return /** @type{?proto.hr_system.User} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.User, 1));
};


/**
 * @param {?proto.hr_system.User|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
*/
proto.hr_system.CreateOrUpdateUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int64 direct_manager_user_ids = 2;
 * @return {!Array<number>}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.getDirectManagerUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.setDirectManagerUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.addDirectManagerUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.clearDirectManagerUserIdsList = function() {
  return this.setDirectManagerUserIdsList([]);
};


/**
 * repeated int64 direct_report_user_ids = 3;
 * @return {!Array<number>}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.getDirectReportUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.setDirectReportUserIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.addDirectReportUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.clearDirectReportUserIdsList = function() {
  return this.setDirectReportUserIdsList([]);
};


/**
 * repeated int64 dotted_manager_user_ids = 4;
 * @return {!Array<number>}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.getDottedManagerUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.setDottedManagerUserIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.addDottedManagerUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.clearDottedManagerUserIdsList = function() {
  return this.setDottedManagerUserIdsList([]);
};


/**
 * repeated int64 dotted_report_user_ids = 5;
 * @return {!Array<number>}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.getDottedReportUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.setDottedReportUserIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.addDottedReportUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.clearDottedReportUserIdsList = function() {
  return this.setDottedReportUserIdsList([]);
};


/**
 * repeated EffectiveDateEntry effective_date_entries = 6;
 * @return {!Array<!proto.hr_system.EffectiveDateEntry>}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.getEffectiveDateEntriesList = function() {
  return /** @type{!Array<!proto.hr_system.EffectiveDateEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.EffectiveDateEntry, 6));
};


/**
 * @param {!Array<!proto.hr_system.EffectiveDateEntry>} value
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
*/
proto.hr_system.CreateOrUpdateUserRequest.prototype.setEffectiveDateEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.hr_system.EffectiveDateEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.EffectiveDateEntry}
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.addEffectiveDateEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.hr_system.EffectiveDateEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.CreateOrUpdateUserRequest} returns this
 */
proto.hr_system.CreateOrUpdateUserRequest.prototype.clearEffectiveDateEntriesList = function() {
  return this.setEffectiveDateEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.EffectiveDateEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.EffectiveDateEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.EffectiveDateEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.EffectiveDateEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    effectedAttribute: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    effectiveDate: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.EffectiveDateEntry}
 */
proto.hr_system.EffectiveDateEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.EffectiveDateEntry;
  return proto.hr_system.EffectiveDateEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.EffectiveDateEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.EffectiveDateEntry}
 */
proto.hr_system.EffectiveDateEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.hr_system.EffectiveDateEntry.EffectedAttribute} */ (reader.readEnum());
      msg.setEffectedAttribute(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectiveDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.EffectiveDateEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.EffectiveDateEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.EffectiveDateEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.EffectiveDateEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.hr_system.EffectiveDateEntry.EffectedAttribute} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.hr_system.EffectiveDateEntry.EffectedAttribute = {
  UNKNOWN: 0,
  DEPARTMENT: 1,
  DIRECT_MANAGER: 2,
  USER: 3
};

/**
 * optional EffectedAttribute effected_attribute = 1;
 * @return {!proto.hr_system.EffectiveDateEntry.EffectedAttribute}
 */
proto.hr_system.EffectiveDateEntry.prototype.getEffectedAttribute = function() {
  return /** @type {!proto.hr_system.EffectiveDateEntry.EffectedAttribute} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.hr_system.EffectiveDateEntry.EffectedAttribute} value
 * @return {!proto.hr_system.EffectiveDateEntry} returns this
 */
proto.hr_system.EffectiveDateEntry.prototype.setEffectedAttribute = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.EffectiveDateEntry} returns this
 */
proto.hr_system.EffectiveDateEntry.prototype.clearEffectedAttribute = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.EffectiveDateEntry.prototype.hasEffectedAttribute = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string effective_date = 2;
 * @return {string}
 */
proto.hr_system.EffectiveDateEntry.prototype.getEffectiveDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.EffectiveDateEntry} returns this
 */
proto.hr_system.EffectiveDateEntry.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.EffectiveDateEntry} returns this
 */
proto.hr_system.EffectiveDateEntry.prototype.clearEffectiveDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.EffectiveDateEntry.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    userId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateUserResponse}
 */
proto.hr_system.CreateOrUpdateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateUserResponse;
  return proto.hr_system.CreateOrUpdateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateUserResponse}
 */
proto.hr_system.CreateOrUpdateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateUserResponse} returns this
*/
proto.hr_system.CreateOrUpdateUserResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateUserResponse} returns this
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateUserResponse} returns this
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateUserResponse} returns this
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateUserResponse.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userSearchIdentifier: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    includeInactive: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    pageNumber: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    pageSize: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    departmentIds: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    siteIds: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    employeeStatus: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUsersRequest}
 */
proto.hr_system.GetUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUsersRequest;
  return proto.hr_system.GetUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUsersRequest}
 */
proto.hr_system.GetUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserSearchIdentifier(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIncludeInactive(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteIds(value);
      break;
    case 6:
      var value = /** @type {!proto.hr_system.User.EmployeeStatus} */ (reader.readEnum());
      msg.setEmployeeStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {!proto.hr_system.User.EmployeeStatus} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string user_search_identifier = 1;
 * @return {string}
 */
proto.hr_system.GetUsersRequest.prototype.getUserSearchIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.setUserSearchIdentifier = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.clearUserSearchIdentifier = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersRequest.prototype.hasUserSearchIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 include_inactive = 7;
 * @return {number}
 */
proto.hr_system.GetUsersRequest.prototype.getIncludeInactive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.setIncludeInactive = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.clearIncludeInactive = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersRequest.prototype.hasIncludeInactive = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 page_number = 2;
 * @return {number}
 */
proto.hr_system.GetUsersRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.clearPageNumber = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersRequest.prototype.hasPageNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 page_size = 3;
 * @return {number}
 */
proto.hr_system.GetUsersRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.clearPageSize = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string department_ids = 4;
 * @return {string}
 */
proto.hr_system.GetUsersRequest.prototype.getDepartmentIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.setDepartmentIds = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.clearDepartmentIds = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersRequest.prototype.hasDepartmentIds = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string site_ids = 5;
 * @return {string}
 */
proto.hr_system.GetUsersRequest.prototype.getSiteIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.setSiteIds = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.clearSiteIds = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersRequest.prototype.hasSiteIds = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional User.EmployeeStatus employee_status = 6;
 * @return {!proto.hr_system.User.EmployeeStatus}
 */
proto.hr_system.GetUsersRequest.prototype.getEmployeeStatus = function() {
  return /** @type {!proto.hr_system.User.EmployeeStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.hr_system.User.EmployeeStatus} value
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.setEmployeeStatus = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUsersRequest} returns this
 */
proto.hr_system.GetUsersRequest.prototype.clearEmployeeStatus = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersRequest.prototype.hasEmployeeStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUsersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_entities_pb.User.toObject, includeInstance),
    totalCount: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUsersResponse}
 */
proto.hr_system.GetUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUsersResponse;
  return proto.hr_system.GetUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUsersResponse}
 */
proto.hr_system.GetUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetUsersResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetUsersResponse} returns this
*/
proto.hr_system.GetUsersResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetUsersResponse} returns this
 */
proto.hr_system.GetUsersResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User users = 2;
 * @return {!Array<!proto.hr_system.User>}
 */
proto.hr_system.GetUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.hr_system.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.User, 2));
};


/**
 * @param {!Array<!proto.hr_system.User>} value
 * @return {!proto.hr_system.GetUsersResponse} returns this
*/
proto.hr_system.GetUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.User}
 */
proto.hr_system.GetUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUsersResponse} returns this
 */
proto.hr_system.GetUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional int64 total_count = 3;
 * @return {number}
 */
proto.hr_system.GetUsersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUsersResponse} returns this
 */
proto.hr_system.GetUsersResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUsersResponse} returns this
 */
proto.hr_system.GetUsersResponse.prototype.clearTotalCount = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUsersResponse.prototype.hasTotalCount = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUserResponse.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto_entities_pb.User.toObject(includeInstance, f),
    directManagersList: jspb.Message.toObjectList(msg.getDirectManagersList(),
    proto_entities_pb.User.toObject, includeInstance),
    directReportsList: jspb.Message.toObjectList(msg.getDirectReportsList(),
    proto_entities_pb.User.toObject, includeInstance),
    dottedManagersList: jspb.Message.toObjectList(msg.getDottedManagersList(),
    proto_entities_pb.User.toObject, includeInstance),
    dottedReportsList: jspb.Message.toObjectList(msg.getDottedReportsList(),
    proto_entities_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserResponse}
 */
proto.hr_system.GetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserResponse;
  return proto.hr_system.GetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserResponse}
 */
proto.hr_system.GetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.addDirectManagers(value);
      break;
    case 4:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.addDirectReports(value);
      break;
    case 5:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.addDottedManagers(value);
      break;
    case 6:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.addDottedReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getDirectManagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getDirectReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getDottedManagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getDottedReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetUserResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetUserResponse} returns this
*/
proto.hr_system.GetUserResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetUserResponse} returns this
 */
proto.hr_system.GetUserResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 2;
 * @return {?proto.hr_system.User}
 */
proto.hr_system.GetUserResponse.prototype.getUser = function() {
  return /** @type{?proto.hr_system.User} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.User, 2));
};


/**
 * @param {?proto.hr_system.User|undefined} value
 * @return {!proto.hr_system.GetUserResponse} returns this
*/
proto.hr_system.GetUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetUserResponse} returns this
 */
proto.hr_system.GetUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated User direct_managers = 3;
 * @return {!Array<!proto.hr_system.User>}
 */
proto.hr_system.GetUserResponse.prototype.getDirectManagersList = function() {
  return /** @type{!Array<!proto.hr_system.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.User, 3));
};


/**
 * @param {!Array<!proto.hr_system.User>} value
 * @return {!proto.hr_system.GetUserResponse} returns this
*/
proto.hr_system.GetUserResponse.prototype.setDirectManagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hr_system.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.User}
 */
proto.hr_system.GetUserResponse.prototype.addDirectManagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hr_system.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserResponse} returns this
 */
proto.hr_system.GetUserResponse.prototype.clearDirectManagersList = function() {
  return this.setDirectManagersList([]);
};


/**
 * repeated User direct_reports = 4;
 * @return {!Array<!proto.hr_system.User>}
 */
proto.hr_system.GetUserResponse.prototype.getDirectReportsList = function() {
  return /** @type{!Array<!proto.hr_system.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.User, 4));
};


/**
 * @param {!Array<!proto.hr_system.User>} value
 * @return {!proto.hr_system.GetUserResponse} returns this
*/
proto.hr_system.GetUserResponse.prototype.setDirectReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hr_system.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.User}
 */
proto.hr_system.GetUserResponse.prototype.addDirectReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hr_system.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserResponse} returns this
 */
proto.hr_system.GetUserResponse.prototype.clearDirectReportsList = function() {
  return this.setDirectReportsList([]);
};


/**
 * repeated User dotted_managers = 5;
 * @return {!Array<!proto.hr_system.User>}
 */
proto.hr_system.GetUserResponse.prototype.getDottedManagersList = function() {
  return /** @type{!Array<!proto.hr_system.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.User, 5));
};


/**
 * @param {!Array<!proto.hr_system.User>} value
 * @return {!proto.hr_system.GetUserResponse} returns this
*/
proto.hr_system.GetUserResponse.prototype.setDottedManagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hr_system.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.User}
 */
proto.hr_system.GetUserResponse.prototype.addDottedManagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hr_system.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserResponse} returns this
 */
proto.hr_system.GetUserResponse.prototype.clearDottedManagersList = function() {
  return this.setDottedManagersList([]);
};


/**
 * repeated User dotted_reports = 6;
 * @return {!Array<!proto.hr_system.User>}
 */
proto.hr_system.GetUserResponse.prototype.getDottedReportsList = function() {
  return /** @type{!Array<!proto.hr_system.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.User, 6));
};


/**
 * @param {!Array<!proto.hr_system.User>} value
 * @return {!proto.hr_system.GetUserResponse} returns this
*/
proto.hr_system.GetUserResponse.prototype.setDottedReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.hr_system.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.User}
 */
proto.hr_system.GetUserResponse.prototype.addDottedReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.hr_system.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserResponse} returns this
 */
proto.hr_system.GetUserResponse.prototype.clearDottedReportsList = function() {
  return this.setDottedReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserSummariesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserSummariesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserSummariesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserSummariesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forFinance: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    departmentIds: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    siteIds: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    employeeStatus: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    pageNumber: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    pageSize: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserSummariesRequest}
 */
proto.hr_system.GetUserSummariesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserSummariesRequest;
  return proto.hr_system.GetUserSummariesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserSummariesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserSummariesRequest}
 */
proto.hr_system.GetUserSummariesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForFinance(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteIds(value);
      break;
    case 4:
      var value = /** @type {!proto.hr_system.User.EmployeeStatus} */ (reader.readEnum());
      msg.setEmployeeStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserSummariesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserSummariesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserSummariesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserSummariesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.hr_system.User.EmployeeStatus} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional bool for_finance = 1;
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesRequest.prototype.getForFinance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.setForFinance = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.clearForFinance = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesRequest.prototype.hasForFinance = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string department_ids = 2;
 * @return {string}
 */
proto.hr_system.GetUserSummariesRequest.prototype.getDepartmentIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.setDepartmentIds = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.clearDepartmentIds = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesRequest.prototype.hasDepartmentIds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string site_ids = 3;
 * @return {string}
 */
proto.hr_system.GetUserSummariesRequest.prototype.getSiteIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.setSiteIds = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.clearSiteIds = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesRequest.prototype.hasSiteIds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User.EmployeeStatus employee_status = 4;
 * @return {!proto.hr_system.User.EmployeeStatus}
 */
proto.hr_system.GetUserSummariesRequest.prototype.getEmployeeStatus = function() {
  return /** @type {!proto.hr_system.User.EmployeeStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.hr_system.User.EmployeeStatus} value
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.setEmployeeStatus = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.clearEmployeeStatus = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesRequest.prototype.hasEmployeeStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 page_number = 5;
 * @return {number}
 */
proto.hr_system.GetUserSummariesRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.clearPageNumber = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesRequest.prototype.hasPageNumber = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 page_size = 6;
 * @return {number}
 */
proto.hr_system.GetUserSummariesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSummariesRequest} returns this
 */
proto.hr_system.GetUserSummariesRequest.prototype.clearPageSize = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUserSummariesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserSummariesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserSummariesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserSummariesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserSummariesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    userSummariesList: jspb.Message.toObjectList(msg.getUserSummariesList(),
    proto_entities_pb.UserSummary.toObject, includeInstance),
    totalCount: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserSummariesResponse}
 */
proto.hr_system.GetUserSummariesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserSummariesResponse;
  return proto.hr_system.GetUserSummariesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserSummariesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserSummariesResponse}
 */
proto.hr_system.GetUserSummariesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.UserSummary;
      reader.readMessage(value,proto_entities_pb.UserSummary.deserializeBinaryFromReader);
      msg.addUserSummaries(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserSummariesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserSummariesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserSummariesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserSummariesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getUserSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.UserSummary.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetUserSummariesResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetUserSummariesResponse} returns this
*/
proto.hr_system.GetUserSummariesResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetUserSummariesResponse} returns this
 */
proto.hr_system.GetUserSummariesResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated UserSummary user_summaries = 2;
 * @return {!Array<!proto.hr_system.UserSummary>}
 */
proto.hr_system.GetUserSummariesResponse.prototype.getUserSummariesList = function() {
  return /** @type{!Array<!proto.hr_system.UserSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.UserSummary, 2));
};


/**
 * @param {!Array<!proto.hr_system.UserSummary>} value
 * @return {!proto.hr_system.GetUserSummariesResponse} returns this
*/
proto.hr_system.GetUserSummariesResponse.prototype.setUserSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.UserSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserSummary}
 */
proto.hr_system.GetUserSummariesResponse.prototype.addUserSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.UserSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserSummariesResponse} returns this
 */
proto.hr_system.GetUserSummariesResponse.prototype.clearUserSummariesList = function() {
  return this.setUserSummariesList([]);
};


/**
 * optional int32 total_count = 3;
 * @return {number}
 */
proto.hr_system.GetUserSummariesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetUserSummariesResponse} returns this
 */
proto.hr_system.GetUserSummariesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserSummariesResponse} returns this
 */
proto.hr_system.GetUserSummariesResponse.prototype.clearTotalCount = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserSummariesResponse.prototype.hasTotalCount = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUserBasicInfoRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserBasicInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserBasicInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserBasicInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    companyEmailsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserBasicInfoRequest}
 */
proto.hr_system.GetUserBasicInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserBasicInfoRequest;
  return proto.hr_system.GetUserBasicInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserBasicInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserBasicInfoRequest}
 */
proto.hr_system.GetUserBasicInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addUserIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addCompanyEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserBasicInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserBasicInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserBasicInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      2,
      f
    );
  }
  f = message.getCompanyEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetUserBasicInfoRequest} returns this
*/
proto.hr_system.GetUserBasicInfoRequest.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetUserBasicInfoRequest} returns this
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int64 user_ids = 2;
 * @return {!Array<number>}
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.GetUserBasicInfoRequest} returns this
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GetUserBasicInfoRequest} returns this
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserBasicInfoRequest} returns this
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * repeated string company_emails = 3;
 * @return {!Array<string>}
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.getCompanyEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.GetUserBasicInfoRequest} returns this
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.setCompanyEmailsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.GetUserBasicInfoRequest} returns this
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.addCompanyEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserBasicInfoRequest} returns this
 */
proto.hr_system.GetUserBasicInfoRequest.prototype.clearCompanyEmailsList = function() {
  return this.setCompanyEmailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUserBasicInfoResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserBasicInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserBasicInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserBasicInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserBasicInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_entities_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserBasicInfoResponse}
 */
proto.hr_system.GetUserBasicInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserBasicInfoResponse;
  return proto.hr_system.GetUserBasicInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserBasicInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserBasicInfoResponse}
 */
proto.hr_system.GetUserBasicInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserBasicInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserBasicInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserBasicInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserBasicInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetUserBasicInfoResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetUserBasicInfoResponse} returns this
*/
proto.hr_system.GetUserBasicInfoResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetUserBasicInfoResponse} returns this
 */
proto.hr_system.GetUserBasicInfoResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserBasicInfoResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User users = 2;
 * @return {!Array<!proto.hr_system.User>}
 */
proto.hr_system.GetUserBasicInfoResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.hr_system.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.User, 2));
};


/**
 * @param {!Array<!proto.hr_system.User>} value
 * @return {!proto.hr_system.GetUserBasicInfoResponse} returns this
*/
proto.hr_system.GetUserBasicInfoResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.User}
 */
proto.hr_system.GetUserBasicInfoResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserBasicInfoResponse} returns this
 */
proto.hr_system.GetUserBasicInfoResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetTeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetTeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetTeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetTeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    team: (f = msg.getTeam()) && proto_entities_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetTeamResponse}
 */
proto.hr_system.GetTeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetTeamResponse;
  return proto.hr_system.GetTeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetTeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetTeamResponse}
 */
proto.hr_system.GetTeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Team;
      reader.readMessage(value,proto_entities_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetTeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetTeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetTeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetTeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetTeamResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetTeamResponse} returns this
*/
proto.hr_system.GetTeamResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetTeamResponse} returns this
 */
proto.hr_system.GetTeamResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetTeamResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Team team = 2;
 * @return {?proto.hr_system.Team}
 */
proto.hr_system.GetTeamResponse.prototype.getTeam = function() {
  return /** @type{?proto.hr_system.Team} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Team, 2));
};


/**
 * @param {?proto.hr_system.Team|undefined} value
 * @return {!proto.hr_system.GetTeamResponse} returns this
*/
proto.hr_system.GetTeamResponse.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetTeamResponse} returns this
 */
proto.hr_system.GetTeamResponse.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetTeamResponse.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetTeamsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetTeamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetTeamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetTeamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetTeamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto_entities_pb.Team.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetTeamsResponse}
 */
proto.hr_system.GetTeamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetTeamsResponse;
  return proto.hr_system.GetTeamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetTeamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetTeamsResponse}
 */
proto.hr_system.GetTeamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Team;
      reader.readMessage(value,proto_entities_pb.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetTeamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetTeamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetTeamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetTeamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetTeamsResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetTeamsResponse} returns this
*/
proto.hr_system.GetTeamsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetTeamsResponse} returns this
 */
proto.hr_system.GetTeamsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetTeamsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Team teams = 2;
 * @return {!Array<!proto.hr_system.Team>}
 */
proto.hr_system.GetTeamsResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.hr_system.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.Team, 2));
};


/**
 * @param {!Array<!proto.hr_system.Team>} value
 * @return {!proto.hr_system.GetTeamsResponse} returns this
*/
proto.hr_system.GetTeamsResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.Team}
 */
proto.hr_system.GetTeamsResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetTeamsResponse} returns this
 */
proto.hr_system.GetTeamsResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    team: (f = msg.getTeam()) && proto_entities_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateTeamRequest}
 */
proto.hr_system.CreateOrUpdateTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateTeamRequest;
  return proto.hr_system.CreateOrUpdateTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateTeamRequest}
 */
proto.hr_system.CreateOrUpdateTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.Team;
      reader.readMessage(value,proto_entities_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional Team team = 1;
 * @return {?proto.hr_system.Team}
 */
proto.hr_system.CreateOrUpdateTeamRequest.prototype.getTeam = function() {
  return /** @type{?proto.hr_system.Team} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Team, 1));
};


/**
 * @param {?proto.hr_system.Team|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateTeamRequest} returns this
*/
proto.hr_system.CreateOrUpdateTeamRequest.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateTeamRequest} returns this
 */
proto.hr_system.CreateOrUpdateTeamRequest.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateTeamRequest.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateTeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateTeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateTeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    teamId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateTeamResponse}
 */
proto.hr_system.CreateOrUpdateTeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateTeamResponse;
  return proto.hr_system.CreateOrUpdateTeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateTeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateTeamResponse}
 */
proto.hr_system.CreateOrUpdateTeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTeamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateTeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateTeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateTeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateTeamResponse} returns this
*/
proto.hr_system.CreateOrUpdateTeamResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateTeamResponse} returns this
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 team_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateTeamResponse} returns this
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.setTeamId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateTeamResponse} returns this
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.clearTeamId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateTeamResponse.prototype.hasTeamId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetDepartmentsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetDepartmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetDepartmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetDepartmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetDepartmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    proto_entities_pb.Department.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetDepartmentsResponse}
 */
proto.hr_system.GetDepartmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetDepartmentsResponse;
  return proto.hr_system.GetDepartmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetDepartmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetDepartmentsResponse}
 */
proto.hr_system.GetDepartmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Department;
      reader.readMessage(value,proto_entities_pb.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetDepartmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetDepartmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetDepartmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetDepartmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.Department.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetDepartmentsResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetDepartmentsResponse} returns this
*/
proto.hr_system.GetDepartmentsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetDepartmentsResponse} returns this
 */
proto.hr_system.GetDepartmentsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetDepartmentsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Department departments = 2;
 * @return {!Array<!proto.hr_system.Department>}
 */
proto.hr_system.GetDepartmentsResponse.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.hr_system.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.Department, 2));
};


/**
 * @param {!Array<!proto.hr_system.Department>} value
 * @return {!proto.hr_system.GetDepartmentsResponse} returns this
*/
proto.hr_system.GetDepartmentsResponse.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.Department}
 */
proto.hr_system.GetDepartmentsResponse.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetDepartmentsResponse} returns this
 */
proto.hr_system.GetDepartmentsResponse.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetDepartmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetDepartmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetDepartmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetDepartmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    department: (f = msg.getDepartment()) && proto_entities_pb.Department.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetDepartmentResponse}
 */
proto.hr_system.GetDepartmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetDepartmentResponse;
  return proto.hr_system.GetDepartmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetDepartmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetDepartmentResponse}
 */
proto.hr_system.GetDepartmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Department;
      reader.readMessage(value,proto_entities_pb.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetDepartmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetDepartmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetDepartmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetDepartmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.Department.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetDepartmentResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetDepartmentResponse} returns this
*/
proto.hr_system.GetDepartmentResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetDepartmentResponse} returns this
 */
proto.hr_system.GetDepartmentResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetDepartmentResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Department department = 2;
 * @return {?proto.hr_system.Department}
 */
proto.hr_system.GetDepartmentResponse.prototype.getDepartment = function() {
  return /** @type{?proto.hr_system.Department} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Department, 2));
};


/**
 * @param {?proto.hr_system.Department|undefined} value
 * @return {!proto.hr_system.GetDepartmentResponse} returns this
*/
proto.hr_system.GetDepartmentResponse.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetDepartmentResponse} returns this
 */
proto.hr_system.GetDepartmentResponse.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetDepartmentResponse.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateDepartmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateDepartmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    department: (f = msg.getDepartment()) && proto_entities_pb.Department.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateDepartmentRequest}
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateDepartmentRequest;
  return proto.hr_system.CreateOrUpdateDepartmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateDepartmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateDepartmentRequest}
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.Department;
      reader.readMessage(value,proto_entities_pb.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateDepartmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateDepartmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.Department.serializeBinaryToWriter
    );
  }
};


/**
 * optional Department department = 1;
 * @return {?proto.hr_system.Department}
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.prototype.getDepartment = function() {
  return /** @type{?proto.hr_system.Department} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Department, 1));
};


/**
 * @param {?proto.hr_system.Department|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateDepartmentRequest} returns this
*/
proto.hr_system.CreateOrUpdateDepartmentRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateDepartmentRequest} returns this
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateDepartmentRequest.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateDepartmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateDepartmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    departmentId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateDepartmentResponse}
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateDepartmentResponse;
  return proto.hr_system.CreateOrUpdateDepartmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateDepartmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateDepartmentResponse}
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateDepartmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateDepartmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateDepartmentResponse} returns this
*/
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateDepartmentResponse} returns this
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 department_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.getDepartmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateDepartmentResponse} returns this
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.setDepartmentId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateDepartmentResponse} returns this
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.clearDepartmentId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateDepartmentResponse.prototype.hasDepartmentId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetJobFunctionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetJobFunctionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetJobFunctionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetJobFunctionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetJobFunctionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    jobFunctionsList: jspb.Message.toObjectList(msg.getJobFunctionsList(),
    proto_entities_pb.JobFunction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetJobFunctionsResponse}
 */
proto.hr_system.GetJobFunctionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetJobFunctionsResponse;
  return proto.hr_system.GetJobFunctionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetJobFunctionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetJobFunctionsResponse}
 */
proto.hr_system.GetJobFunctionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.JobFunction;
      reader.readMessage(value,proto_entities_pb.JobFunction.deserializeBinaryFromReader);
      msg.addJobFunctions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetJobFunctionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetJobFunctionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetJobFunctionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetJobFunctionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getJobFunctionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.JobFunction.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetJobFunctionsResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetJobFunctionsResponse} returns this
*/
proto.hr_system.GetJobFunctionsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetJobFunctionsResponse} returns this
 */
proto.hr_system.GetJobFunctionsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetJobFunctionsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated JobFunction job_functions = 2;
 * @return {!Array<!proto.hr_system.JobFunction>}
 */
proto.hr_system.GetJobFunctionsResponse.prototype.getJobFunctionsList = function() {
  return /** @type{!Array<!proto.hr_system.JobFunction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.JobFunction, 2));
};


/**
 * @param {!Array<!proto.hr_system.JobFunction>} value
 * @return {!proto.hr_system.GetJobFunctionsResponse} returns this
*/
proto.hr_system.GetJobFunctionsResponse.prototype.setJobFunctionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.JobFunction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.JobFunction}
 */
proto.hr_system.GetJobFunctionsResponse.prototype.addJobFunctions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.JobFunction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetJobFunctionsResponse} returns this
 */
proto.hr_system.GetJobFunctionsResponse.prototype.clearJobFunctionsList = function() {
  return this.setJobFunctionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetJobFunctionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetJobFunctionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetJobFunctionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetJobFunctionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    jobFunction: (f = msg.getJobFunction()) && proto_entities_pb.JobFunction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetJobFunctionResponse}
 */
proto.hr_system.GetJobFunctionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetJobFunctionResponse;
  return proto.hr_system.GetJobFunctionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetJobFunctionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetJobFunctionResponse}
 */
proto.hr_system.GetJobFunctionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.JobFunction;
      reader.readMessage(value,proto_entities_pb.JobFunction.deserializeBinaryFromReader);
      msg.setJobFunction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetJobFunctionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetJobFunctionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetJobFunctionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetJobFunctionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getJobFunction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.JobFunction.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetJobFunctionResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetJobFunctionResponse} returns this
*/
proto.hr_system.GetJobFunctionResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetJobFunctionResponse} returns this
 */
proto.hr_system.GetJobFunctionResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetJobFunctionResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional JobFunction job_function = 2;
 * @return {?proto.hr_system.JobFunction}
 */
proto.hr_system.GetJobFunctionResponse.prototype.getJobFunction = function() {
  return /** @type{?proto.hr_system.JobFunction} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.JobFunction, 2));
};


/**
 * @param {?proto.hr_system.JobFunction|undefined} value
 * @return {!proto.hr_system.GetJobFunctionResponse} returns this
*/
proto.hr_system.GetJobFunctionResponse.prototype.setJobFunction = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetJobFunctionResponse} returns this
 */
proto.hr_system.GetJobFunctionResponse.prototype.clearJobFunction = function() {
  return this.setJobFunction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetJobFunctionResponse.prototype.hasJobFunction = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateJobFunctionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateJobFunctionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobFunction: (f = msg.getJobFunction()) && proto_entities_pb.JobFunction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionRequest}
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateJobFunctionRequest;
  return proto.hr_system.CreateOrUpdateJobFunctionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateJobFunctionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionRequest}
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.JobFunction;
      reader.readMessage(value,proto_entities_pb.JobFunction.deserializeBinaryFromReader);
      msg.setJobFunction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateJobFunctionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateJobFunctionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobFunction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.JobFunction.serializeBinaryToWriter
    );
  }
};


/**
 * optional JobFunction job_function = 1;
 * @return {?proto.hr_system.JobFunction}
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.prototype.getJobFunction = function() {
  return /** @type{?proto.hr_system.JobFunction} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.JobFunction, 1));
};


/**
 * @param {?proto.hr_system.JobFunction|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionRequest} returns this
*/
proto.hr_system.CreateOrUpdateJobFunctionRequest.prototype.setJobFunction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionRequest} returns this
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.prototype.clearJobFunction = function() {
  return this.setJobFunction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateJobFunctionRequest.prototype.hasJobFunction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateJobFunctionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateJobFunctionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    jobFunctionId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionResponse}
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateJobFunctionResponse;
  return proto.hr_system.CreateOrUpdateJobFunctionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateJobFunctionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionResponse}
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobFunctionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateJobFunctionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateJobFunctionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionResponse} returns this
*/
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionResponse} returns this
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 job_function_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.getJobFunctionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionResponse} returns this
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.setJobFunctionId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateJobFunctionResponse} returns this
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.clearJobFunctionId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateJobFunctionResponse.prototype.hasJobFunctionId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetCostCentersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetCostCentersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetCostCentersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetCostCentersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetCostCentersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    costCentersList: jspb.Message.toObjectList(msg.getCostCentersList(),
    proto_entities_pb.CostCenter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetCostCentersResponse}
 */
proto.hr_system.GetCostCentersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetCostCentersResponse;
  return proto.hr_system.GetCostCentersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetCostCentersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetCostCentersResponse}
 */
proto.hr_system.GetCostCentersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.CostCenter;
      reader.readMessage(value,proto_entities_pb.CostCenter.deserializeBinaryFromReader);
      msg.addCostCenters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetCostCentersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetCostCentersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetCostCentersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetCostCentersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getCostCentersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.CostCenter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetCostCentersResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetCostCentersResponse} returns this
*/
proto.hr_system.GetCostCentersResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetCostCentersResponse} returns this
 */
proto.hr_system.GetCostCentersResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetCostCentersResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CostCenter cost_centers = 2;
 * @return {!Array<!proto.hr_system.CostCenter>}
 */
proto.hr_system.GetCostCentersResponse.prototype.getCostCentersList = function() {
  return /** @type{!Array<!proto.hr_system.CostCenter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.CostCenter, 2));
};


/**
 * @param {!Array<!proto.hr_system.CostCenter>} value
 * @return {!proto.hr_system.GetCostCentersResponse} returns this
*/
proto.hr_system.GetCostCentersResponse.prototype.setCostCentersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.CostCenter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.CostCenter}
 */
proto.hr_system.GetCostCentersResponse.prototype.addCostCenters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.CostCenter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetCostCentersResponse} returns this
 */
proto.hr_system.GetCostCentersResponse.prototype.clearCostCentersList = function() {
  return this.setCostCentersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetCostCenterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetCostCenterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetCostCenterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetCostCenterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    costCenter: (f = msg.getCostCenter()) && proto_entities_pb.CostCenter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetCostCenterResponse}
 */
proto.hr_system.GetCostCenterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetCostCenterResponse;
  return proto.hr_system.GetCostCenterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetCostCenterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetCostCenterResponse}
 */
proto.hr_system.GetCostCenterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.CostCenter;
      reader.readMessage(value,proto_entities_pb.CostCenter.deserializeBinaryFromReader);
      msg.setCostCenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetCostCenterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetCostCenterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetCostCenterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetCostCenterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getCostCenter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.CostCenter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetCostCenterResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetCostCenterResponse} returns this
*/
proto.hr_system.GetCostCenterResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetCostCenterResponse} returns this
 */
proto.hr_system.GetCostCenterResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetCostCenterResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CostCenter cost_center = 2;
 * @return {?proto.hr_system.CostCenter}
 */
proto.hr_system.GetCostCenterResponse.prototype.getCostCenter = function() {
  return /** @type{?proto.hr_system.CostCenter} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.CostCenter, 2));
};


/**
 * @param {?proto.hr_system.CostCenter|undefined} value
 * @return {!proto.hr_system.GetCostCenterResponse} returns this
*/
proto.hr_system.GetCostCenterResponse.prototype.setCostCenter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetCostCenterResponse} returns this
 */
proto.hr_system.GetCostCenterResponse.prototype.clearCostCenter = function() {
  return this.setCostCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetCostCenterResponse.prototype.hasCostCenter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateCostCenterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateCostCenterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    costCenter: (f = msg.getCostCenter()) && proto_entities_pb.CostCenter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateCostCenterRequest}
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateCostCenterRequest;
  return proto.hr_system.CreateOrUpdateCostCenterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateCostCenterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateCostCenterRequest}
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.CostCenter;
      reader.readMessage(value,proto_entities_pb.CostCenter.deserializeBinaryFromReader);
      msg.setCostCenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateCostCenterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateCostCenterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostCenter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.CostCenter.serializeBinaryToWriter
    );
  }
};


/**
 * optional CostCenter cost_center = 1;
 * @return {?proto.hr_system.CostCenter}
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.prototype.getCostCenter = function() {
  return /** @type{?proto.hr_system.CostCenter} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.CostCenter, 1));
};


/**
 * @param {?proto.hr_system.CostCenter|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateCostCenterRequest} returns this
*/
proto.hr_system.CreateOrUpdateCostCenterRequest.prototype.setCostCenter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateCostCenterRequest} returns this
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.prototype.clearCostCenter = function() {
  return this.setCostCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateCostCenterRequest.prototype.hasCostCenter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateCostCenterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateCostCenterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    costCenterId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateCostCenterResponse}
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateCostCenterResponse;
  return proto.hr_system.CreateOrUpdateCostCenterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateCostCenterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateCostCenterResponse}
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostCenterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateCostCenterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateCostCenterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateCostCenterResponse} returns this
*/
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateCostCenterResponse} returns this
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 cost_center_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.getCostCenterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateCostCenterResponse} returns this
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.setCostCenterId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateCostCenterResponse} returns this
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.clearCostCenterId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateCostCenterResponse.prototype.hasCostCenterId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetJobTypesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetJobTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetJobTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetJobTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetJobTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    jobTypesList: jspb.Message.toObjectList(msg.getJobTypesList(),
    proto_entities_pb.JobType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetJobTypesResponse}
 */
proto.hr_system.GetJobTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetJobTypesResponse;
  return proto.hr_system.GetJobTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetJobTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetJobTypesResponse}
 */
proto.hr_system.GetJobTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.JobType;
      reader.readMessage(value,proto_entities_pb.JobType.deserializeBinaryFromReader);
      msg.addJobTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetJobTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetJobTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetJobTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetJobTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getJobTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.JobType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetJobTypesResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetJobTypesResponse} returns this
*/
proto.hr_system.GetJobTypesResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetJobTypesResponse} returns this
 */
proto.hr_system.GetJobTypesResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetJobTypesResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated JobType job_types = 2;
 * @return {!Array<!proto.hr_system.JobType>}
 */
proto.hr_system.GetJobTypesResponse.prototype.getJobTypesList = function() {
  return /** @type{!Array<!proto.hr_system.JobType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.JobType, 2));
};


/**
 * @param {!Array<!proto.hr_system.JobType>} value
 * @return {!proto.hr_system.GetJobTypesResponse} returns this
*/
proto.hr_system.GetJobTypesResponse.prototype.setJobTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.JobType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.JobType}
 */
proto.hr_system.GetJobTypesResponse.prototype.addJobTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.JobType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetJobTypesResponse} returns this
 */
proto.hr_system.GetJobTypesResponse.prototype.clearJobTypesList = function() {
  return this.setJobTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetJobTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetJobTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetJobTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetJobTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    jobType: (f = msg.getJobType()) && proto_entities_pb.JobType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetJobTypeResponse}
 */
proto.hr_system.GetJobTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetJobTypeResponse;
  return proto.hr_system.GetJobTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetJobTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetJobTypeResponse}
 */
proto.hr_system.GetJobTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.JobType;
      reader.readMessage(value,proto_entities_pb.JobType.deserializeBinaryFromReader);
      msg.setJobType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetJobTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetJobTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetJobTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetJobTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getJobType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.JobType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetJobTypeResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetJobTypeResponse} returns this
*/
proto.hr_system.GetJobTypeResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetJobTypeResponse} returns this
 */
proto.hr_system.GetJobTypeResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetJobTypeResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional JobType job_type = 2;
 * @return {?proto.hr_system.JobType}
 */
proto.hr_system.GetJobTypeResponse.prototype.getJobType = function() {
  return /** @type{?proto.hr_system.JobType} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.JobType, 2));
};


/**
 * @param {?proto.hr_system.JobType|undefined} value
 * @return {!proto.hr_system.GetJobTypeResponse} returns this
*/
proto.hr_system.GetJobTypeResponse.prototype.setJobType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetJobTypeResponse} returns this
 */
proto.hr_system.GetJobTypeResponse.prototype.clearJobType = function() {
  return this.setJobType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetJobTypeResponse.prototype.hasJobType = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateJobTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateJobTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobType: (f = msg.getJobType()) && proto_entities_pb.JobType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateJobTypeRequest}
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateJobTypeRequest;
  return proto.hr_system.CreateOrUpdateJobTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateJobTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateJobTypeRequest}
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.JobType;
      reader.readMessage(value,proto_entities_pb.JobType.deserializeBinaryFromReader);
      msg.setJobType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateJobTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateJobTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.JobType.serializeBinaryToWriter
    );
  }
};


/**
 * optional JobType job_type = 1;
 * @return {?proto.hr_system.JobType}
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.prototype.getJobType = function() {
  return /** @type{?proto.hr_system.JobType} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.JobType, 1));
};


/**
 * @param {?proto.hr_system.JobType|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateJobTypeRequest} returns this
*/
proto.hr_system.CreateOrUpdateJobTypeRequest.prototype.setJobType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateJobTypeRequest} returns this
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.prototype.clearJobType = function() {
  return this.setJobType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateJobTypeRequest.prototype.hasJobType = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateJobTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateJobTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    jobTypeId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateJobTypeResponse}
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateJobTypeResponse;
  return proto.hr_system.CreateOrUpdateJobTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateJobTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateJobTypeResponse}
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateJobTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateJobTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateJobTypeResponse} returns this
*/
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateJobTypeResponse} returns this
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 job_type_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.getJobTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateJobTypeResponse} returns this
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.setJobTypeId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateJobTypeResponse} returns this
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.clearJobTypeId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateJobTypeResponse.prototype.hasJobTypeId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetBusinessUnitsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetBusinessUnitsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetBusinessUnitsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetBusinessUnitsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetBusinessUnitsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    businessUnitsList: jspb.Message.toObjectList(msg.getBusinessUnitsList(),
    proto_entities_pb.BusinessUnit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetBusinessUnitsResponse}
 */
proto.hr_system.GetBusinessUnitsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetBusinessUnitsResponse;
  return proto.hr_system.GetBusinessUnitsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetBusinessUnitsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetBusinessUnitsResponse}
 */
proto.hr_system.GetBusinessUnitsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.BusinessUnit;
      reader.readMessage(value,proto_entities_pb.BusinessUnit.deserializeBinaryFromReader);
      msg.addBusinessUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetBusinessUnitsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetBusinessUnitsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetBusinessUnitsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetBusinessUnitsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBusinessUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.BusinessUnit.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetBusinessUnitsResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetBusinessUnitsResponse} returns this
*/
proto.hr_system.GetBusinessUnitsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetBusinessUnitsResponse} returns this
 */
proto.hr_system.GetBusinessUnitsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetBusinessUnitsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated BusinessUnit business_units = 2;
 * @return {!Array<!proto.hr_system.BusinessUnit>}
 */
proto.hr_system.GetBusinessUnitsResponse.prototype.getBusinessUnitsList = function() {
  return /** @type{!Array<!proto.hr_system.BusinessUnit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.BusinessUnit, 2));
};


/**
 * @param {!Array<!proto.hr_system.BusinessUnit>} value
 * @return {!proto.hr_system.GetBusinessUnitsResponse} returns this
*/
proto.hr_system.GetBusinessUnitsResponse.prototype.setBusinessUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.BusinessUnit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.BusinessUnit}
 */
proto.hr_system.GetBusinessUnitsResponse.prototype.addBusinessUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.BusinessUnit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetBusinessUnitsResponse} returns this
 */
proto.hr_system.GetBusinessUnitsResponse.prototype.clearBusinessUnitsList = function() {
  return this.setBusinessUnitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetBusinessUnitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetBusinessUnitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetBusinessUnitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetBusinessUnitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    businessUnit: (f = msg.getBusinessUnit()) && proto_entities_pb.BusinessUnit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetBusinessUnitResponse}
 */
proto.hr_system.GetBusinessUnitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetBusinessUnitResponse;
  return proto.hr_system.GetBusinessUnitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetBusinessUnitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetBusinessUnitResponse}
 */
proto.hr_system.GetBusinessUnitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.BusinessUnit;
      reader.readMessage(value,proto_entities_pb.BusinessUnit.deserializeBinaryFromReader);
      msg.setBusinessUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetBusinessUnitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetBusinessUnitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetBusinessUnitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetBusinessUnitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getBusinessUnit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.BusinessUnit.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetBusinessUnitResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetBusinessUnitResponse} returns this
*/
proto.hr_system.GetBusinessUnitResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetBusinessUnitResponse} returns this
 */
proto.hr_system.GetBusinessUnitResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetBusinessUnitResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessUnit business_unit = 2;
 * @return {?proto.hr_system.BusinessUnit}
 */
proto.hr_system.GetBusinessUnitResponse.prototype.getBusinessUnit = function() {
  return /** @type{?proto.hr_system.BusinessUnit} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.BusinessUnit, 2));
};


/**
 * @param {?proto.hr_system.BusinessUnit|undefined} value
 * @return {!proto.hr_system.GetBusinessUnitResponse} returns this
*/
proto.hr_system.GetBusinessUnitResponse.prototype.setBusinessUnit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetBusinessUnitResponse} returns this
 */
proto.hr_system.GetBusinessUnitResponse.prototype.clearBusinessUnit = function() {
  return this.setBusinessUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetBusinessUnitResponse.prototype.hasBusinessUnit = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateBusinessUnitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateBusinessUnitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessUnit: (f = msg.getBusinessUnit()) && proto_entities_pb.BusinessUnit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitRequest}
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateBusinessUnitRequest;
  return proto.hr_system.CreateOrUpdateBusinessUnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateBusinessUnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitRequest}
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.BusinessUnit;
      reader.readMessage(value,proto_entities_pb.BusinessUnit.deserializeBinaryFromReader);
      msg.setBusinessUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateBusinessUnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateBusinessUnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessUnit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.BusinessUnit.serializeBinaryToWriter
    );
  }
};


/**
 * optional BusinessUnit business_unit = 1;
 * @return {?proto.hr_system.BusinessUnit}
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.prototype.getBusinessUnit = function() {
  return /** @type{?proto.hr_system.BusinessUnit} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.BusinessUnit, 1));
};


/**
 * @param {?proto.hr_system.BusinessUnit|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitRequest} returns this
*/
proto.hr_system.CreateOrUpdateBusinessUnitRequest.prototype.setBusinessUnit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitRequest} returns this
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.prototype.clearBusinessUnit = function() {
  return this.setBusinessUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateBusinessUnitRequest.prototype.hasBusinessUnit = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateBusinessUnitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateBusinessUnitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    businessUnitId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitResponse}
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateBusinessUnitResponse;
  return proto.hr_system.CreateOrUpdateBusinessUnitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateBusinessUnitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitResponse}
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBusinessUnitId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateBusinessUnitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateBusinessUnitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitResponse} returns this
*/
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitResponse} returns this
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 business_unit_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.getBusinessUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitResponse} returns this
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.setBusinessUnitId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateBusinessUnitResponse} returns this
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.clearBusinessUnitId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateBusinessUnitResponse.prototype.hasBusinessUnitId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetEmploymentTypesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetEmploymentTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetEmploymentTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetEmploymentTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetEmploymentTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    employmentTypesList: jspb.Message.toObjectList(msg.getEmploymentTypesList(),
    proto_entities_pb.EmploymentType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetEmploymentTypesResponse}
 */
proto.hr_system.GetEmploymentTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetEmploymentTypesResponse;
  return proto.hr_system.GetEmploymentTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetEmploymentTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetEmploymentTypesResponse}
 */
proto.hr_system.GetEmploymentTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.EmploymentType;
      reader.readMessage(value,proto_entities_pb.EmploymentType.deserializeBinaryFromReader);
      msg.addEmploymentTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetEmploymentTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetEmploymentTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetEmploymentTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetEmploymentTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getEmploymentTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.EmploymentType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetEmploymentTypesResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetEmploymentTypesResponse} returns this
*/
proto.hr_system.GetEmploymentTypesResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetEmploymentTypesResponse} returns this
 */
proto.hr_system.GetEmploymentTypesResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetEmploymentTypesResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated EmploymentType employment_types = 2;
 * @return {!Array<!proto.hr_system.EmploymentType>}
 */
proto.hr_system.GetEmploymentTypesResponse.prototype.getEmploymentTypesList = function() {
  return /** @type{!Array<!proto.hr_system.EmploymentType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.EmploymentType, 2));
};


/**
 * @param {!Array<!proto.hr_system.EmploymentType>} value
 * @return {!proto.hr_system.GetEmploymentTypesResponse} returns this
*/
proto.hr_system.GetEmploymentTypesResponse.prototype.setEmploymentTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.EmploymentType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.EmploymentType}
 */
proto.hr_system.GetEmploymentTypesResponse.prototype.addEmploymentTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.EmploymentType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetEmploymentTypesResponse} returns this
 */
proto.hr_system.GetEmploymentTypesResponse.prototype.clearEmploymentTypesList = function() {
  return this.setEmploymentTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetEmploymentTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetEmploymentTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetEmploymentTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetEmploymentTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    employmentType: (f = msg.getEmploymentType()) && proto_entities_pb.EmploymentType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetEmploymentTypeResponse}
 */
proto.hr_system.GetEmploymentTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetEmploymentTypeResponse;
  return proto.hr_system.GetEmploymentTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetEmploymentTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetEmploymentTypeResponse}
 */
proto.hr_system.GetEmploymentTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.EmploymentType;
      reader.readMessage(value,proto_entities_pb.EmploymentType.deserializeBinaryFromReader);
      msg.setEmploymentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetEmploymentTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetEmploymentTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetEmploymentTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetEmploymentTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getEmploymentType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.EmploymentType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetEmploymentTypeResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetEmploymentTypeResponse} returns this
*/
proto.hr_system.GetEmploymentTypeResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetEmploymentTypeResponse} returns this
 */
proto.hr_system.GetEmploymentTypeResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetEmploymentTypeResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EmploymentType employment_type = 2;
 * @return {?proto.hr_system.EmploymentType}
 */
proto.hr_system.GetEmploymentTypeResponse.prototype.getEmploymentType = function() {
  return /** @type{?proto.hr_system.EmploymentType} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.EmploymentType, 2));
};


/**
 * @param {?proto.hr_system.EmploymentType|undefined} value
 * @return {!proto.hr_system.GetEmploymentTypeResponse} returns this
*/
proto.hr_system.GetEmploymentTypeResponse.prototype.setEmploymentType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetEmploymentTypeResponse} returns this
 */
proto.hr_system.GetEmploymentTypeResponse.prototype.clearEmploymentType = function() {
  return this.setEmploymentType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetEmploymentTypeResponse.prototype.hasEmploymentType = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateEmploymentTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateEmploymentTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    employmentType: (f = msg.getEmploymentType()) && proto_entities_pb.EmploymentType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeRequest}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateEmploymentTypeRequest;
  return proto.hr_system.CreateOrUpdateEmploymentTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateEmploymentTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeRequest}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.EmploymentType;
      reader.readMessage(value,proto_entities_pb.EmploymentType.deserializeBinaryFromReader);
      msg.setEmploymentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateEmploymentTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateEmploymentTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmploymentType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.EmploymentType.serializeBinaryToWriter
    );
  }
};


/**
 * optional EmploymentType employment_type = 1;
 * @return {?proto.hr_system.EmploymentType}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.prototype.getEmploymentType = function() {
  return /** @type{?proto.hr_system.EmploymentType} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.EmploymentType, 1));
};


/**
 * @param {?proto.hr_system.EmploymentType|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeRequest} returns this
*/
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.prototype.setEmploymentType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeRequest} returns this
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.prototype.clearEmploymentType = function() {
  return this.setEmploymentType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeRequest.prototype.hasEmploymentType = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateEmploymentTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    employmentTypeId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateEmploymentTypeResponse;
  return proto.hr_system.CreateOrUpdateEmploymentTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEmploymentTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateEmploymentTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse} returns this
*/
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse} returns this
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 employment_type_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.getEmploymentTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse} returns this
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.setEmploymentTypeId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateEmploymentTypeResponse} returns this
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.clearEmploymentTypeId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateEmploymentTypeResponse.prototype.hasEmploymentTypeId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetLevelsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetLevelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetLevelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetLevelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetLevelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    levelsList: jspb.Message.toObjectList(msg.getLevelsList(),
    proto_entities_pb.Level.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetLevelsResponse}
 */
proto.hr_system.GetLevelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetLevelsResponse;
  return proto.hr_system.GetLevelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetLevelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetLevelsResponse}
 */
proto.hr_system.GetLevelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Level;
      reader.readMessage(value,proto_entities_pb.Level.deserializeBinaryFromReader);
      msg.addLevels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetLevelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetLevelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetLevelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetLevelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.Level.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetLevelsResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetLevelsResponse} returns this
*/
proto.hr_system.GetLevelsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetLevelsResponse} returns this
 */
proto.hr_system.GetLevelsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetLevelsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Level levels = 2;
 * @return {!Array<!proto.hr_system.Level>}
 */
proto.hr_system.GetLevelsResponse.prototype.getLevelsList = function() {
  return /** @type{!Array<!proto.hr_system.Level>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.Level, 2));
};


/**
 * @param {!Array<!proto.hr_system.Level>} value
 * @return {!proto.hr_system.GetLevelsResponse} returns this
*/
proto.hr_system.GetLevelsResponse.prototype.setLevelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.Level=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.Level}
 */
proto.hr_system.GetLevelsResponse.prototype.addLevels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.Level, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetLevelsResponse} returns this
 */
proto.hr_system.GetLevelsResponse.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetLevelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetLevelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetLevelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetLevelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    level: (f = msg.getLevel()) && proto_entities_pb.Level.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetLevelResponse}
 */
proto.hr_system.GetLevelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetLevelResponse;
  return proto.hr_system.GetLevelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetLevelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetLevelResponse}
 */
proto.hr_system.GetLevelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Level;
      reader.readMessage(value,proto_entities_pb.Level.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetLevelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetLevelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetLevelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetLevelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.Level.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetLevelResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetLevelResponse} returns this
*/
proto.hr_system.GetLevelResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetLevelResponse} returns this
 */
proto.hr_system.GetLevelResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetLevelResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Level level = 2;
 * @return {?proto.hr_system.Level}
 */
proto.hr_system.GetLevelResponse.prototype.getLevel = function() {
  return /** @type{?proto.hr_system.Level} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Level, 2));
};


/**
 * @param {?proto.hr_system.Level|undefined} value
 * @return {!proto.hr_system.GetLevelResponse} returns this
*/
proto.hr_system.GetLevelResponse.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetLevelResponse} returns this
 */
proto.hr_system.GetLevelResponse.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetLevelResponse.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateLevelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateLevelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateLevelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateLevelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    level: (f = msg.getLevel()) && proto_entities_pb.Level.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateLevelRequest}
 */
proto.hr_system.CreateOrUpdateLevelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateLevelRequest;
  return proto.hr_system.CreateOrUpdateLevelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateLevelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateLevelRequest}
 */
proto.hr_system.CreateOrUpdateLevelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.Level;
      reader.readMessage(value,proto_entities_pb.Level.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateLevelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateLevelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateLevelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateLevelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.Level.serializeBinaryToWriter
    );
  }
};


/**
 * optional Level level = 1;
 * @return {?proto.hr_system.Level}
 */
proto.hr_system.CreateOrUpdateLevelRequest.prototype.getLevel = function() {
  return /** @type{?proto.hr_system.Level} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Level, 1));
};


/**
 * @param {?proto.hr_system.Level|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateLevelRequest} returns this
*/
proto.hr_system.CreateOrUpdateLevelRequest.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateLevelRequest} returns this
 */
proto.hr_system.CreateOrUpdateLevelRequest.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateLevelRequest.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateLevelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateLevelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateLevelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    levelId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateLevelResponse}
 */
proto.hr_system.CreateOrUpdateLevelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateLevelResponse;
  return proto.hr_system.CreateOrUpdateLevelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateLevelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateLevelResponse}
 */
proto.hr_system.CreateOrUpdateLevelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLevelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateLevelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateLevelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateLevelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateLevelResponse} returns this
*/
proto.hr_system.CreateOrUpdateLevelResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateLevelResponse} returns this
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 level_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.getLevelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateLevelResponse} returns this
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.setLevelId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateLevelResponse} returns this
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.clearLevelId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateLevelResponse.prototype.hasLevelId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetSitesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_entities_pb.Site.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetSitesResponse}
 */
proto.hr_system.GetSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetSitesResponse;
  return proto.hr_system.GetSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetSitesResponse}
 */
proto.hr_system.GetSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Site;
      reader.readMessage(value,proto_entities_pb.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetSitesResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetSitesResponse} returns this
*/
proto.hr_system.GetSitesResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetSitesResponse} returns this
 */
proto.hr_system.GetSitesResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetSitesResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Site sites = 2;
 * @return {!Array<!proto.hr_system.Site>}
 */
proto.hr_system.GetSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.hr_system.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.Site, 2));
};


/**
 * @param {!Array<!proto.hr_system.Site>} value
 * @return {!proto.hr_system.GetSitesResponse} returns this
*/
proto.hr_system.GetSitesResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.Site}
 */
proto.hr_system.GetSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetSitesResponse} returns this
 */
proto.hr_system.GetSitesResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    site: (f = msg.getSite()) && proto_entities_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetSiteResponse}
 */
proto.hr_system.GetSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetSiteResponse;
  return proto.hr_system.GetSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetSiteResponse}
 */
proto.hr_system.GetSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Site;
      reader.readMessage(value,proto_entities_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetSiteResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetSiteResponse} returns this
*/
proto.hr_system.GetSiteResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetSiteResponse} returns this
 */
proto.hr_system.GetSiteResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetSiteResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Site site = 2;
 * @return {?proto.hr_system.Site}
 */
proto.hr_system.GetSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.hr_system.Site} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Site, 2));
};


/**
 * @param {?proto.hr_system.Site|undefined} value
 * @return {!proto.hr_system.GetSiteResponse} returns this
*/
proto.hr_system.GetSiteResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetSiteResponse} returns this
 */
proto.hr_system.GetSiteResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_entities_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateSiteRequest}
 */
proto.hr_system.CreateOrUpdateSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateSiteRequest;
  return proto.hr_system.CreateOrUpdateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateSiteRequest}
 */
proto.hr_system.CreateOrUpdateSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.Site;
      reader.readMessage(value,proto_entities_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.hr_system.Site}
 */
proto.hr_system.CreateOrUpdateSiteRequest.prototype.getSite = function() {
  return /** @type{?proto.hr_system.Site} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Site, 1));
};


/**
 * @param {?proto.hr_system.Site|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateSiteRequest} returns this
*/
proto.hr_system.CreateOrUpdateSiteRequest.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateSiteRequest} returns this
 */
proto.hr_system.CreateOrUpdateSiteRequest.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateSiteRequest.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    siteId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateSiteResponse}
 */
proto.hr_system.CreateOrUpdateSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateSiteResponse;
  return proto.hr_system.CreateOrUpdateSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateSiteResponse}
 */
proto.hr_system.CreateOrUpdateSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateSiteResponse} returns this
*/
proto.hr_system.CreateOrUpdateSiteResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateSiteResponse} returns this
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 site_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateSiteResponse} returns this
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.setSiteId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateSiteResponse} returns this
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.clearSiteId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateSiteResponse.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetTitlesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetTitlesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetTitlesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetTitlesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetTitlesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    titlesList: jspb.Message.toObjectList(msg.getTitlesList(),
    proto_entities_pb.Title.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetTitlesResponse}
 */
proto.hr_system.GetTitlesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetTitlesResponse;
  return proto.hr_system.GetTitlesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetTitlesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetTitlesResponse}
 */
proto.hr_system.GetTitlesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Title;
      reader.readMessage(value,proto_entities_pb.Title.deserializeBinaryFromReader);
      msg.addTitles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetTitlesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetTitlesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetTitlesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetTitlesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getTitlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.Title.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetTitlesResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetTitlesResponse} returns this
*/
proto.hr_system.GetTitlesResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetTitlesResponse} returns this
 */
proto.hr_system.GetTitlesResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetTitlesResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Title titles = 2;
 * @return {!Array<!proto.hr_system.Title>}
 */
proto.hr_system.GetTitlesResponse.prototype.getTitlesList = function() {
  return /** @type{!Array<!proto.hr_system.Title>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.Title, 2));
};


/**
 * @param {!Array<!proto.hr_system.Title>} value
 * @return {!proto.hr_system.GetTitlesResponse} returns this
*/
proto.hr_system.GetTitlesResponse.prototype.setTitlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.Title=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.Title}
 */
proto.hr_system.GetTitlesResponse.prototype.addTitles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.Title, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetTitlesResponse} returns this
 */
proto.hr_system.GetTitlesResponse.prototype.clearTitlesList = function() {
  return this.setTitlesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetTitleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetTitleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetTitleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetTitleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && proto_entities_pb.Title.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetTitleResponse}
 */
proto.hr_system.GetTitleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetTitleResponse;
  return proto.hr_system.GetTitleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetTitleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetTitleResponse}
 */
proto.hr_system.GetTitleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.Title;
      reader.readMessage(value,proto_entities_pb.Title.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetTitleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetTitleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetTitleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetTitleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_entities_pb.Title.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetTitleResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetTitleResponse} returns this
*/
proto.hr_system.GetTitleResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetTitleResponse} returns this
 */
proto.hr_system.GetTitleResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetTitleResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Title title = 2;
 * @return {?proto.hr_system.Title}
 */
proto.hr_system.GetTitleResponse.prototype.getTitle = function() {
  return /** @type{?proto.hr_system.Title} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Title, 2));
};


/**
 * @param {?proto.hr_system.Title|undefined} value
 * @return {!proto.hr_system.GetTitleResponse} returns this
*/
proto.hr_system.GetTitleResponse.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetTitleResponse} returns this
 */
proto.hr_system.GetTitleResponse.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetTitleResponse.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateTitleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateTitleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateTitleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateTitleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = msg.getTitle()) && proto_entities_pb.Title.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateTitleRequest}
 */
proto.hr_system.CreateOrUpdateTitleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateTitleRequest;
  return proto.hr_system.CreateOrUpdateTitleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateTitleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateTitleRequest}
 */
proto.hr_system.CreateOrUpdateTitleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_entities_pb.Title;
      reader.readMessage(value,proto_entities_pb.Title.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateTitleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateTitleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateTitleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateTitleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_entities_pb.Title.serializeBinaryToWriter
    );
  }
};


/**
 * optional Title title = 1;
 * @return {?proto.hr_system.Title}
 */
proto.hr_system.CreateOrUpdateTitleRequest.prototype.getTitle = function() {
  return /** @type{?proto.hr_system.Title} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.Title, 1));
};


/**
 * @param {?proto.hr_system.Title|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateTitleRequest} returns this
*/
proto.hr_system.CreateOrUpdateTitleRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateTitleRequest} returns this
 */
proto.hr_system.CreateOrUpdateTitleRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateTitleRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdateTitleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdateTitleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateTitleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    titleId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdateTitleResponse}
 */
proto.hr_system.CreateOrUpdateTitleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdateTitleResponse;
  return proto.hr_system.CreateOrUpdateTitleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdateTitleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdateTitleResponse}
 */
proto.hr_system.CreateOrUpdateTitleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTitleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdateTitleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdateTitleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdateTitleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.CreateOrUpdateTitleResponse} returns this
*/
proto.hr_system.CreateOrUpdateTitleResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateTitleResponse} returns this
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 title_id = 2;
 * @return {number}
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.getTitleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.CreateOrUpdateTitleResponse} returns this
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.setTitleId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdateTitleResponse} returns this
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.clearTitleId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdateTitleResponse.prototype.hasTitleId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetAuditRecordsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetAuditRecordsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetAuditRecordsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastAuditRecordId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    editorId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    targetModel: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    targetKeyId: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 100)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetAuditRecordsRequest}
 */
proto.hr_system.GetAuditRecordsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetAuditRecordsRequest;
  return proto.hr_system.GetAuditRecordsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetAuditRecordsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetAuditRecordsRequest}
 */
proto.hr_system.GetAuditRecordsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastAuditRecordId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEditorId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetModel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetKeyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetAuditRecordsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetAuditRecordsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetAuditRecordsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 last_audit_record_id = 1;
 * @return {number}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.getLastAuditRecordId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.setLastAuditRecordId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.clearLastAuditRecordId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.hasLastAuditRecordId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 editor_id = 3;
 * @return {number}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.getEditorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.setEditorId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.clearEditorId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.hasEditorId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 target_model = 4;
 * @return {number}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.getTargetModel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.setTargetModel = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.clearTargetModel = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.hasTargetModel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 target_key_id = 5;
 * @return {number}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.getTargetKeyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.setTargetKeyId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.clearTargetKeyId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.hasTargetKeyId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 100));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetAuditRecordsRequest} returns this
 */
proto.hr_system.GetAuditRecordsRequest.prototype.clearPageSize = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetAuditRecordsRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetAuditRecordsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetAuditRecordsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetAuditRecordsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetAuditRecordsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetAuditRecordsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f),
    auditRecordsList: jspb.Message.toObjectList(msg.getAuditRecordsList(),
    proto_entities_pb.AuditRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetAuditRecordsResponse}
 */
proto.hr_system.GetAuditRecordsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetAuditRecordsResponse;
  return proto.hr_system.GetAuditRecordsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetAuditRecordsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetAuditRecordsResponse}
 */
proto.hr_system.GetAuditRecordsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 2:
      var value = new proto_entities_pb.AuditRecord;
      reader.readMessage(value,proto_entities_pb.AuditRecord.deserializeBinaryFromReader);
      msg.addAuditRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetAuditRecordsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetAuditRecordsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetAuditRecordsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetAuditRecordsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
  f = message.getAuditRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_entities_pb.AuditRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.GetAuditRecordsResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.GetAuditRecordsResponse} returns this
*/
proto.hr_system.GetAuditRecordsResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetAuditRecordsResponse} returns this
 */
proto.hr_system.GetAuditRecordsResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetAuditRecordsResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AuditRecord audit_records = 2;
 * @return {!Array<!proto.hr_system.AuditRecord>}
 */
proto.hr_system.GetAuditRecordsResponse.prototype.getAuditRecordsList = function() {
  return /** @type{!Array<!proto.hr_system.AuditRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_entities_pb.AuditRecord, 2));
};


/**
 * @param {!Array<!proto.hr_system.AuditRecord>} value
 * @return {!proto.hr_system.GetAuditRecordsResponse} returns this
*/
proto.hr_system.GetAuditRecordsResponse.prototype.setAuditRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.AuditRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.AuditRecord}
 */
proto.hr_system.GetAuditRecordsResponse.prototype.addAuditRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.AuditRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetAuditRecordsResponse} returns this
 */
proto.hr_system.GetAuditRecordsResponse.prototype.clearAuditRecordsList = function() {
  return this.setAuditRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.hr_system.ResponseHeader.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.DeleteResponse}
 */
proto.hr_system.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.DeleteResponse;
  return proto.hr_system.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.DeleteResponse}
 */
proto.hr_system.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.ResponseHeader;
      reader.readMessage(value,proto.hr_system.ResponseHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.ResponseHeader.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseHeader header = 1;
 * @return {?proto.hr_system.ResponseHeader}
 */
proto.hr_system.DeleteResponse.prototype.getHeader = function() {
  return /** @type{?proto.hr_system.ResponseHeader} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ResponseHeader, 1));
};


/**
 * @param {?proto.hr_system.ResponseHeader|undefined} value
 * @return {!proto.hr_system.DeleteResponse} returns this
*/
proto.hr_system.DeleteResponse.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.DeleteResponse} returns this
 */
proto.hr_system.DeleteResponse.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.DeleteResponse.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ReportRelation.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ReportRelation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ReportRelation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReportRelation.toObject = function(includeInstance, msg) {
  var f, obj = {
    relationType: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reportType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    isDirect: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ReportRelation}
 */
proto.hr_system.ReportRelation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ReportRelation;
  return proto.hr_system.ReportRelation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ReportRelation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ReportRelation}
 */
proto.hr_system.ReportRelation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.hr_system.RelationType.Enum} */ (reader.readEnum());
      msg.setRelationType(value);
      break;
    case 2:
      var value = /** @type {!proto.hr_system.ReportType.Enum} */ (reader.readEnum());
      msg.setReportType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDirect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ReportRelation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ReportRelation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ReportRelation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReportRelation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.hr_system.RelationType.Enum} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.hr_system.ReportType.Enum} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional RelationType.Enum relation_type = 1;
 * @return {!proto.hr_system.RelationType.Enum}
 */
proto.hr_system.ReportRelation.prototype.getRelationType = function() {
  return /** @type {!proto.hr_system.RelationType.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.hr_system.RelationType.Enum} value
 * @return {!proto.hr_system.ReportRelation} returns this
 */
proto.hr_system.ReportRelation.prototype.setRelationType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReportRelation} returns this
 */
proto.hr_system.ReportRelation.prototype.clearRelationType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReportRelation.prototype.hasRelationType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReportType.Enum report_type = 2;
 * @return {!proto.hr_system.ReportType.Enum}
 */
proto.hr_system.ReportRelation.prototype.getReportType = function() {
  return /** @type {!proto.hr_system.ReportType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.hr_system.ReportType.Enum} value
 * @return {!proto.hr_system.ReportRelation} returns this
 */
proto.hr_system.ReportRelation.prototype.setReportType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReportRelation} returns this
 */
proto.hr_system.ReportRelation.prototype.clearReportType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReportRelation.prototype.hasReportType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_direct = 3;
 * @return {boolean}
 */
proto.hr_system.ReportRelation.prototype.getIsDirect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.ReportRelation} returns this
 */
proto.hr_system.ReportRelation.prototype.setIsDirect = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.ReportRelation} returns this
 */
proto.hr_system.ReportRelation.prototype.clearIsDirect = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.ReportRelation.prototype.hasIsDirect = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.UserNode.repeatedFields_ = [7,8,9,10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UserNode.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UserNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UserNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UserNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    firstName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lastName: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    displayName: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    siteName: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    titleName: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    departmentNamesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    businessUnitNamesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    directManagerUserIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    directReportUserIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    dottedManagerUserIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    dottedReportUserIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UserNode}
 */
proto.hr_system.UserNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UserNode;
  return proto.hr_system.UserNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UserNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UserNode}
 */
proto.hr_system.UserNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDepartmentNames(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessUnitNames(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addDirectManagerUserIds(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addDirectReportUserIds(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addDottedManagerUserIds(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addDottedReportUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UserNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UserNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UserNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UserNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDepartmentNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getBusinessUnitNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getDirectManagerUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      9,
      f
    );
  }
  f = message.getDirectReportUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      10,
      f
    );
  }
  f = message.getDottedManagerUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      11,
      f
    );
  }
  f = message.getDottedReportUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.hr_system.UserNode.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UserNode.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.hr_system.UserNode.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setFirstName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearFirstName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UserNode.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.hr_system.UserNode.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setLastName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearLastName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UserNode.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.hr_system.UserNode.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setDisplayName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearDisplayName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UserNode.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string site_name = 5;
 * @return {string}
 */
proto.hr_system.UserNode.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setSiteName = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearSiteName = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UserNode.prototype.hasSiteName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string title_name = 6;
 * @return {string}
 */
proto.hr_system.UserNode.prototype.getTitleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setTitleName = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearTitleName = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UserNode.prototype.hasTitleName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string department_names = 7;
 * @return {!Array<string>}
 */
proto.hr_system.UserNode.prototype.getDepartmentNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setDepartmentNamesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.addDepartmentNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearDepartmentNamesList = function() {
  return this.setDepartmentNamesList([]);
};


/**
 * repeated string business_unit_names = 8;
 * @return {!Array<string>}
 */
proto.hr_system.UserNode.prototype.getBusinessUnitNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setBusinessUnitNamesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.addBusinessUnitNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearBusinessUnitNamesList = function() {
  return this.setBusinessUnitNamesList([]);
};


/**
 * repeated int64 direct_manager_user_ids = 9;
 * @return {!Array<number>}
 */
proto.hr_system.UserNode.prototype.getDirectManagerUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setDirectManagerUserIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.addDirectManagerUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearDirectManagerUserIdsList = function() {
  return this.setDirectManagerUserIdsList([]);
};


/**
 * repeated int64 direct_report_user_ids = 10;
 * @return {!Array<number>}
 */
proto.hr_system.UserNode.prototype.getDirectReportUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setDirectReportUserIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.addDirectReportUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearDirectReportUserIdsList = function() {
  return this.setDirectReportUserIdsList([]);
};


/**
 * repeated int64 dotted_manager_user_ids = 11;
 * @return {!Array<number>}
 */
proto.hr_system.UserNode.prototype.getDottedManagerUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setDottedManagerUserIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.addDottedManagerUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearDottedManagerUserIdsList = function() {
  return this.setDottedManagerUserIdsList([]);
};


/**
 * repeated int64 dotted_report_user_ids = 12;
 * @return {!Array<number>}
 */
proto.hr_system.UserNode.prototype.getDottedReportUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.setDottedReportUserIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.addDottedReportUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UserNode} returns this
 */
proto.hr_system.UserNode.prototype.clearDottedReportUserIdsList = function() {
  return this.setDottedReportUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUserOrgChartsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserOrgChartsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserOrgChartsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserOrgChartsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseUserId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reportRelationsList: jspb.Message.toObjectList(msg.getReportRelationsList(),
    proto.hr_system.ReportRelation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserOrgChartsRequest}
 */
proto.hr_system.GetUserOrgChartsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserOrgChartsRequest;
  return proto.hr_system.GetUserOrgChartsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserOrgChartsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserOrgChartsRequest}
 */
proto.hr_system.GetUserOrgChartsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUserId(value);
      break;
    case 2:
      var value = new proto.hr_system.ReportRelation;
      reader.readMessage(value,proto.hr_system.ReportRelation.deserializeBinaryFromReader);
      msg.addReportRelations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserOrgChartsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserOrgChartsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserOrgChartsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportRelationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.hr_system.ReportRelation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string base_user_id = 1;
 * @return {string}
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.getBaseUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetUserOrgChartsRequest} returns this
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.setBaseUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetUserOrgChartsRequest} returns this
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.clearBaseUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.hasBaseUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ReportRelation report_relations = 2;
 * @return {!Array<!proto.hr_system.ReportRelation>}
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.getReportRelationsList = function() {
  return /** @type{!Array<!proto.hr_system.ReportRelation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.ReportRelation, 2));
};


/**
 * @param {!Array<!proto.hr_system.ReportRelation>} value
 * @return {!proto.hr_system.GetUserOrgChartsRequest} returns this
*/
proto.hr_system.GetUserOrgChartsRequest.prototype.setReportRelationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.ReportRelation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.ReportRelation}
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.addReportRelations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.ReportRelation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserOrgChartsRequest} returns this
 */
proto.hr_system.GetUserOrgChartsRequest.prototype.clearReportRelationsList = function() {
  return this.setReportRelationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.RelatedUserNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.RelatedUserNode.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.RelatedUserNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.RelatedUserNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.RelatedUserNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportRelation: (f = msg.getReportRelation()) && proto.hr_system.ReportRelation.toObject(includeInstance, f),
    userNodesList: jspb.Message.toObjectList(msg.getUserNodesList(),
    proto.hr_system.UserNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.RelatedUserNode}
 */
proto.hr_system.RelatedUserNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.RelatedUserNode;
  return proto.hr_system.RelatedUserNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.RelatedUserNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.RelatedUserNode}
 */
proto.hr_system.RelatedUserNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.hr_system.ReportRelation;
      reader.readMessage(value,proto.hr_system.ReportRelation.deserializeBinaryFromReader);
      msg.setReportRelation(value);
      break;
    case 1:
      var value = new proto.hr_system.UserNode;
      reader.readMessage(value,proto.hr_system.UserNode.deserializeBinaryFromReader);
      msg.addUserNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.RelatedUserNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.RelatedUserNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.RelatedUserNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.RelatedUserNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportRelation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hr_system.ReportRelation.serializeBinaryToWriter
    );
  }
  f = message.getUserNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hr_system.UserNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReportRelation report_relation = 2;
 * @return {?proto.hr_system.ReportRelation}
 */
proto.hr_system.RelatedUserNode.prototype.getReportRelation = function() {
  return /** @type{?proto.hr_system.ReportRelation} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.ReportRelation, 2));
};


/**
 * @param {?proto.hr_system.ReportRelation|undefined} value
 * @return {!proto.hr_system.RelatedUserNode} returns this
*/
proto.hr_system.RelatedUserNode.prototype.setReportRelation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.RelatedUserNode} returns this
 */
proto.hr_system.RelatedUserNode.prototype.clearReportRelation = function() {
  return this.setReportRelation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.RelatedUserNode.prototype.hasReportRelation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated UserNode user_nodes = 1;
 * @return {!Array<!proto.hr_system.UserNode>}
 */
proto.hr_system.RelatedUserNode.prototype.getUserNodesList = function() {
  return /** @type{!Array<!proto.hr_system.UserNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.UserNode, 1));
};


/**
 * @param {!Array<!proto.hr_system.UserNode>} value
 * @return {!proto.hr_system.RelatedUserNode} returns this
*/
proto.hr_system.RelatedUserNode.prototype.setUserNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.UserNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UserNode}
 */
proto.hr_system.RelatedUserNode.prototype.addUserNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.UserNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.RelatedUserNode} returns this
 */
proto.hr_system.RelatedUserNode.prototype.clearUserNodesList = function() {
  return this.setUserNodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetUserOrgChartsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetUserOrgChartsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetUserOrgChartsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetUserOrgChartsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserOrgChartsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseUser: (f = msg.getBaseUser()) && proto.hr_system.UserNode.toObject(includeInstance, f),
    relatedUserNodesList: jspb.Message.toObjectList(msg.getRelatedUserNodesList(),
    proto.hr_system.RelatedUserNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetUserOrgChartsResponse}
 */
proto.hr_system.GetUserOrgChartsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetUserOrgChartsResponse;
  return proto.hr_system.GetUserOrgChartsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetUserOrgChartsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetUserOrgChartsResponse}
 */
proto.hr_system.GetUserOrgChartsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hr_system.UserNode;
      reader.readMessage(value,proto.hr_system.UserNode.deserializeBinaryFromReader);
      msg.setBaseUser(value);
      break;
    case 2:
      var value = new proto.hr_system.RelatedUserNode;
      reader.readMessage(value,proto.hr_system.RelatedUserNode.deserializeBinaryFromReader);
      msg.addRelatedUserNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetUserOrgChartsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetUserOrgChartsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetUserOrgChartsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetUserOrgChartsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hr_system.UserNode.serializeBinaryToWriter
    );
  }
  f = message.getRelatedUserNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.hr_system.RelatedUserNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserNode base_user = 1;
 * @return {?proto.hr_system.UserNode}
 */
proto.hr_system.GetUserOrgChartsResponse.prototype.getBaseUser = function() {
  return /** @type{?proto.hr_system.UserNode} */ (
    jspb.Message.getWrapperField(this, proto.hr_system.UserNode, 1));
};


/**
 * @param {?proto.hr_system.UserNode|undefined} value
 * @return {!proto.hr_system.GetUserOrgChartsResponse} returns this
*/
proto.hr_system.GetUserOrgChartsResponse.prototype.setBaseUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.GetUserOrgChartsResponse} returns this
 */
proto.hr_system.GetUserOrgChartsResponse.prototype.clearBaseUser = function() {
  return this.setBaseUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetUserOrgChartsResponse.prototype.hasBaseUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RelatedUserNode related_user_nodes = 2;
 * @return {!Array<!proto.hr_system.RelatedUserNode>}
 */
proto.hr_system.GetUserOrgChartsResponse.prototype.getRelatedUserNodesList = function() {
  return /** @type{!Array<!proto.hr_system.RelatedUserNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.RelatedUserNode, 2));
};


/**
 * @param {!Array<!proto.hr_system.RelatedUserNode>} value
 * @return {!proto.hr_system.GetUserOrgChartsResponse} returns this
*/
proto.hr_system.GetUserOrgChartsResponse.prototype.setRelatedUserNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hr_system.RelatedUserNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.RelatedUserNode}
 */
proto.hr_system.GetUserOrgChartsResponse.prototype.addRelatedUserNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hr_system.RelatedUserNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetUserOrgChartsResponse} returns this
 */
proto.hr_system.GetUserOrgChartsResponse.prototype.clearRelatedUserNodesList = function() {
  return this.setRelatedUserNodesList([]);
};


export const { BatchCreateOrUpdateUsersRequest, BatchCreateOrUpdateUsersResponse, CreateOrUpdateBusinessUnitRequest, CreateOrUpdateBusinessUnitResponse, CreateOrUpdateCostCenterRequest, CreateOrUpdateCostCenterResponse, CreateOrUpdateDepartmentRequest, CreateOrUpdateDepartmentResponse, CreateOrUpdateEmploymentTypeRequest, CreateOrUpdateEmploymentTypeResponse, CreateOrUpdateJobFunctionRequest, CreateOrUpdateJobFunctionResponse, CreateOrUpdateJobTypeRequest, CreateOrUpdateJobTypeResponse, CreateOrUpdateLevelRequest, CreateOrUpdateLevelResponse, CreateOrUpdateSiteRequest, CreateOrUpdateSiteResponse, CreateOrUpdateTeamRequest, CreateOrUpdateTeamResponse, CreateOrUpdateTitleRequest, CreateOrUpdateTitleResponse, CreateOrUpdateUserRequest, CreateOrUpdateUserResponse, DeleteResponse, EffectiveDateEntry, GetAuditRecordsRequest, GetAuditRecordsResponse, GetBusinessUnitResponse, GetBusinessUnitsResponse, GetCostCenterResponse, GetCostCentersResponse, GetDepartmentResponse, GetDepartmentsResponse, GetEmploymentTypeResponse, GetEmploymentTypesResponse, GetJobFunctionResponse, GetJobFunctionsResponse, GetJobTypeResponse, GetJobTypesResponse, GetLevelResponse, GetLevelsResponse, GetSiteResponse, GetSitesResponse, GetTeamResponse, GetTeamsResponse, GetTitleResponse, GetTitlesResponse, GetUserBasicInfoRequest, GetUserBasicInfoResponse, GetUserOrgChartsRequest, GetUserOrgChartsResponse, GetUserResponse, GetUserSummariesRequest, GetUserSummariesResponse, GetUsersRequest, GetUsersResponse, RelatedUserNode, ReportRelation, ResponseHeader, UserNode } = proto.hr_system
