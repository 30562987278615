import { Reducer } from 'redux';

import { FileUploadAction, FileUploadActionType, FileUploadState } from './types';

const initialState: FileUploadState = {
  fileContent: undefined,
  fileName: '',
  previewContent: '',
};

export const fileUpload: Reducer<FileUploadState, FileUploadAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case FileUploadActionType.UPDATE_CURRENT_FILE_CONTENT:
      return {
        ...state,
        fileContent: action.fileContent,
        fileName: action.fileName,
      };
    case FileUploadActionType.UPDATE_PREVIEW_CONTENT:
      return {
        ...state,
        previewContent: action.previewContent,
      };
    case FileUploadActionType.CLEAR_CONTENT:
      return initialState;
    default:
      return state;
  }
};

export default { fileUpload };
