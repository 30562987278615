import { createSelector } from 'reselect';

import { GroupBasedUserPermission } from 'external/hr_system/proto/permission_pb';

import { AppStateType } from 'store';
import { User } from './types';
import { getEmployeeDetailsOriginalUserSiteId } from '../employees';

export const getUserPermissions = (
  state: AppStateType,
): Array<GroupBasedUserPermission.AsObject> | undefined => state.auth.permissions;

export const selectCanEditEmployeeFullInfo = createSelector(
  [getEmployeeDetailsOriginalUserSiteId, getUserPermissions],
  (
    site_id: number | undefined,
    userPermissions: Array<GroupBasedUserPermission.AsObject> | undefined,
  ): boolean => {
    if (!userPermissions) {
      return false;
    }
    const canManageAll = userPermissions.some(
      (p) => p.permission === GroupBasedUserPermission.Permission.ORG_CHART_MANAGE_ALL,
    );
    const canManageSite = userPermissions.some(
      (p) =>
        p.permission === GroupBasedUserPermission.Permission.ORG_CHART_SITE_MANAGE_ALL &&
        (!site_id || p.siteId === site_id),
    );
    return canManageAll || canManageSite;
  },
);

export const selectCanEditReportRelations = createSelector(
  [getEmployeeDetailsOriginalUserSiteId, getUserPermissions],
  (
    site_id: number | undefined,
    userPermissions: Array<GroupBasedUserPermission.AsObject> | undefined,
  ): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.ORG_CHART_MANAGE_ALL ||
          permission.permission ===
            GroupBasedUserPermission.Permission.ORG_CHART_SELF_MANAGE_SUB_REPORT_CHAIN ||
          (permission.permission ===
            GroupBasedUserPermission.Permission.ORG_CHART_SITE_MANAGE_ALL &&
            permission.siteId === site_id) ||
          (permission.permission ===
            GroupBasedUserPermission.Permission.ORG_CHART_SITE_MANAGE_REPORT_CHAIN &&
            permission.siteId === site_id),
      )
    );
  },
);

export const selectIsOrgChartSuperAdmin = createSelector(
  [getUserPermissions],
  (userPermissions: Array<GroupBasedUserPermission.AsObject> | undefined): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.ORG_CHART_MANAGE_ALL,
      )
    );
  },
);

export const selectIsOrgChartAdmin = createSelector(
  [getUserPermissions],
  (userPermissions: Array<GroupBasedUserPermission.AsObject> | undefined): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.ORG_CHART_MANAGE_ALL ||
          permission.permission === GroupBasedUserPermission.Permission.ORG_CHART_SITE_MANAGE_ALL,
      )
    );
  },
);

export const selectCanEditOrgChart = createSelector(
  [getUserPermissions],
  (userPermissions: Array<GroupBasedUserPermission.AsObject> | undefined): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.ORG_CHART_MANAGE_ALL ||
          permission.permission === GroupBasedUserPermission.Permission.ORG_CHART_SITE_MANAGE_ALL ||
          permission.permission ===
            GroupBasedUserPermission.Permission.ORG_CHART_SELF_MANAGE_SUB_REPORT_CHAIN ||
          permission.permission ===
            GroupBasedUserPermission.Permission.ORG_CHART_SITE_MANAGE_REPORT_CHAIN,
      )
    );
  },
);

export const selectCanViewPerf = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.PERF_GROUP_VIEW ||
          permission.permission === GroupBasedUserPermission.Permission.PERF_MANAGE_ALL ||
          permission.permission === GroupBasedUserPermission.Permission.PERF_GROUP_MANAGE,
      )
    );
  },
);

export const selectCanEditPerf = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.PERF_MANAGE_ALL ||
          permission.permission === GroupBasedUserPermission.Permission.PERF_GROUP_MANAGE,
      )
    );
  },
);

export const selectCanEditOthersReviewee = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.PERF_MANAGE_ALL ||
          permission.permission === GroupBasedUserPermission.Permission.PERF_GROUP_MANAGE,
      )
    );
  },
);

export const selectCanViewFinance = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.ORG_CHART_FINANCE,
      )
    );
  },
);

export const selectCanEditPerfCycle = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.PERF_MANAGE_ALL,
      )
    );
  },
);

export const selectIsHcPlanningSiteAdmin = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.HC_PLANNING_SITE_MANAGE,
      )
    );
  },
);

export const selectCanRequestHc = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission === GroupBasedUserPermission.Permission.HC_PLANNING_REQUEST,
      )
    );
  },
);

export const selectCanAccessHcPlanning = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return !!(
      userPermissions &&
      userPermissions.find(
        (permission) =>
          permission.permission ===
            GroupBasedUserPermission.Permission.HC_PLANNING_CONFIGURATION_MANAGE ||
          permission.permission === GroupBasedUserPermission.Permission.HC_PLANNING_SITE_MANAGE ||
          permission.permission === GroupBasedUserPermission.Permission.HC_PLANNING_SITE_VIEW_ALL ||
          permission.permission ===
            GroupBasedUserPermission.Permission.HC_PLANNING_SITE_VIEW_OPEN ||
          permission.permission === GroupBasedUserPermission.Permission.HC_PLANNING_REQUEST,
      )
    );
  },
);

export const selectCanManagePromoTool = createSelector(
  [getUserPermissions],
  (userPermissions): boolean => {
    return (
      !!userPermissions &&
      userPermissions.some(
        ({ permission }) => permission === GroupBasedUserPermission.Permission.PROMO_MANAGE_ALL,
      )
    );
  },
);

export const getCurrentUser = (state: AppStateType): User | undefined => state.auth.session?.user;

export const getCurrentUserIsManager = (state: AppStateType): boolean =>
  state.auth.isManager === true;
