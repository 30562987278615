import store from 'store';
import { setSnackbarContent } from 'store/snackbarContent';

export const errorHandler = async (
  error: { response: Response } | string,
  message: string = 'Operation Failed',
) => {
  console.error(error);
  let errorMessage = '';
  try {
    errorMessage =
      typeof error === 'string' ? error : (await error.response.json())?.error?.message ?? '';
  } catch (e) {
    console.warn('error occurs when parse error message', e);
  }
  const content = errorMessage ? `${message}: ${errorMessage}` : message;
  store.dispatch(setSnackbarContent({ type: 'error', content }));
};

export type PartialNonNullable<T, K extends keyof T> = { [key in K]-?: NonNullable<T[key]> } &
  { [key in Exclude<keyof T, K>]: T[key] };

export const nonNull = <T, K extends keyof T>(item: T, nonNullKeys: K[]): boolean =>
  !nonNullKeys.some((key) => item[key] === undefined || item[key] === null);

const divideByKeysNullability = <T, K extends keyof T>(
  items: T[],
  nonNullKeys: K[],
): { nonNulls: PartialNonNullable<T, K>[]; nullables: T[] } => {
  const nonNulls: PartialNonNullable<T, K>[] = [];
  const nullables: T[] = [];
  items.forEach((item) => {
    nonNull(item, nonNullKeys)
      ? nonNulls.push(item as unknown as PartialNonNullable<T, K>)
      : nullables.push(item);
  });
  return {
    nonNulls,
    nullables,
  };
};

export const filterNonNulls = <T, K extends keyof T>(
  items: T[],
  nonNullKeys: K[],
): PartialNonNullable<T, K>[] => {
  const { nonNulls, nullables } = divideByKeysNullability(items, nonNullKeys);
  if (nullables.length) {
    console.warn(
      `Some items in the list do not have required props ${nonNullKeys.join(',')}`,
      nullables,
    );
  }
  return nonNulls;
};
