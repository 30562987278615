import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'external/hr_system/proto/entities_pb';

import userApi from 'api/userApi';
import { AppStateType } from 'store';
import { saveEmployeeDetails } from './employeeDetails';

interface EmployeeMapState {
  employeesById: Record<number, User.AsObject>;
  loaded: boolean;
  filteredIds: number[];
}

const initialState: EmployeeMapState = {
  loaded: false,
  employeesById: {},
  filteredIds: [],
};

export const fetchEmployees = createAsyncThunk(
  'employees/fetchAsMap',
  (force: boolean, { getState }) => {
    const { employeeMap } = (getState() as AppStateType).employees;
    if (!force && employeeMap.loaded) {
      return employeeMap.employeesById;
    }
    return userApi.listAsCachedMap(force);
  },
);

const employeeMap = createSlice({
  name: 'employeeMap',
  initialState,
  reducers: {
    filteredIdsChanged(state, action: PayloadAction<number[]>) {
      state.filteredIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.employeesById = action.payload;
        state.loaded = true;
      })
      .addCase(saveEmployeeDetails.fulfilled, (state, action) => {
        const user = action.payload;
        if (!user) {
          return;
        }
        if (user.id) {
          state.employeesById[user.id] = user;
        }
      });
  },
});

export const { filteredIdsChanged } = employeeMap.actions;

export default employeeMap.reducer;
