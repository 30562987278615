import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

export interface FileUploadState {
  fileContent?: string;
  fileName: string;
  previewContent: string;
}

export enum FileUploadActionType {
  UPDATE_CURRENT_FILE_CONTENT = 'FILEUPLOAD.UPDATE_CURRENT_FILE_CONTENT',
  UPDATE_PREVIEW_CONTENT = 'FILEUPLOAD.UPDATE_PREVIEW_CONTENT',
  CLEAR_CONTENT = 'FILEUPLOAD.CLEAR_CONTENT',
}

interface UpdateCurrentFileContentAction
  extends Action<FileUploadActionType.UPDATE_CURRENT_FILE_CONTENT> {
  fileContent: string;
  fileName: string;
}

interface UpdatePreviewContentAction extends Action<FileUploadActionType.UPDATE_PREVIEW_CONTENT> {
  previewContent: string;
}

interface ClearContentAction extends Action<FileUploadActionType.CLEAR_CONTENT> {}

export interface FileUploadStateProps {
  fileContent?: string;
  fileName?: string;
  previewContent?: string;
}

export interface FileUploadDispatchProps {
  updateCurrentFileContent?: (fileContent: string, fileName: string) => void;
  updatePreviewContent?: (previewContent: string) => void;
  clearContent?: () => void;
}

export type FileUploadThunkAction<R> = ThunkAction<R, {}, void, FileUploadAction>;
export type FileUploadThunkDispatch = ThunkDispatch<{}, void, FileUploadAction>;

export type FileUploadAction =
  | UpdateCurrentFileContentAction
  | UpdatePreviewContentAction
  | ClearContentAction;
