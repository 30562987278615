import { Department } from 'external/hr_system/proto/entities_pb';
import {
  CreateOrUpdateDepartmentRequest,
  CreateOrUpdateDepartmentResponse,
  DeleteResponse,
  GetDepartmentsResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';

class DepartmentApi extends BaseApi {
  private endpointPrefix = '/departments';
  private idBasedMapCacher: IdBasedMapCacher<Department.AsObject, GetDepartmentsResponse.AsObject>;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetDepartmentsResponse.AsObject>(() => this.list());
    this.idBasedMapCacher = new IdBasedMapCacher<
      Department.AsObject,
      GetDepartmentsResponse.AsObject
    >(apiCacher, (response) => response.departmentsList);
  }

  public async listAsCachedMap(force = false): Promise<Record<number, Department.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async list(): Promise<GetDepartmentsResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetDepartmentsResponse.toObject(false, GetDepartmentsResponse.deserializeBinary(buffer));
  }

  public async add(name: string): Promise<CreateOrUpdateDepartmentResponse.AsObject> {
    const request = new CreateOrUpdateDepartmentRequest();
    const department = new Department();
    department.setName(name);
    request.setDepartment(department);

    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateDepartmentResponse.toObject(
      false,
      CreateOrUpdateDepartmentResponse.deserializeBinary(buffer),
    );
  }

  public async update({
    name,
    id,
    parentId,
  }: {
    name: string;
    id: number;
    parentId?: number;
  }): Promise<CreateOrUpdateDepartmentResponse.AsObject> {
    const request = new CreateOrUpdateDepartmentRequest();
    const department = new Department();
    department.setName(name);
    department.setId(id);
    if (parentId !== undefined) {
      department.setParentId(parentId);
    }
    request.setDepartment(department);

    const response = await this.put(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateDepartmentResponse.toObject(
      false,
      CreateOrUpdateDepartmentResponse.deserializeBinary(buffer),
    );
  }

  public async deleteOne(id: number): Promise<DeleteResponse.AsObject> {
    const response = await super.delete(this.endpointPrefix + '/' + id, {});
    const buffer = await getBufferArrayFromResponse(response);
    return DeleteResponse.toObject(false, DeleteResponse.deserializeBinary(buffer));
  }
}

export default new DepartmentApi();
