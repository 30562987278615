import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { JobFunction } from 'external/hr_system/proto/entities_pb';

import jobFunctionApi from 'api/jobFunctionApi';

interface JobFunctionsState {
  jobFunctionsById: Record<number, JobFunction.AsObject>;
  error?: string;
}

export const fetchJobFunctions = createAsyncThunk('jobFunctions/fetchAsMap', (force: boolean) =>
  jobFunctionApi.listAsCachedMap(force),
);

export const addJobFunction = createAsyncThunk(
  'jobFunctions/add',
  async (name: string): Promise<JobFunction.AsObject> => {
    const response = await jobFunctionApi.add(name);
    return constructJobFunction(name, response.jobFunctionId);
  },
);

export const updateJobFunction = createAsyncThunk(
  'jobFunctions/update',
  async (params: { name: string; id: number }) => {
    const response = await jobFunctionApi.update(params);
    return constructJobFunction(params.name, response.jobFunctionId);
  },
);

export const deleteJobFunction = createAsyncThunk('jobFunctions/delete', async (id: number) => {
  await jobFunctionApi.deleteOne(id);
  return id;
});

const constructJobFunction = (name: string, id?: number) => {
  const result = new JobFunction();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting jobFunctionId but it is undefined.');
  }
};

const jobFunctions = createSlice({
  name: 'jobFunctions',
  initialState: {
    jobFunctionsById: {},
  } as JobFunctionsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobFunctions.fulfilled, (state, action) => {
        state.jobFunctionsById = action.payload;
      })
      .addCase(addJobFunction.fulfilled, (state, action) => {
        const jobFunction = action.payload;
        if (jobFunction.id) {
          state.jobFunctionsById[jobFunction.id] = jobFunction;
        }
        state.error = undefined;
      })
      .addCase(addJobFunction.rejected, (state, action) => {
        state.error = `Add job function failed ${action.error?.message}`;
      })
      .addCase(updateJobFunction.fulfilled, (state, action) => {
        const jobFunction = action.payload;
        if (jobFunction.id) {
          state.jobFunctionsById[jobFunction.id] = jobFunction;
        }
        state.error = undefined;
      })
      .addCase(updateJobFunction.rejected, (state, action) => {
        state.error = `Update job function failed ${action.error?.message}`;
      })
      .addCase(deleteJobFunction.fulfilled, (state, action) => {
        delete state.jobFunctionsById[action.payload];
        state.error = undefined;
      })
      .addCase(deleteJobFunction.rejected, (state, action) => {
        state.error = `Delete job function failed ${action.error?.message}`;
      });
  },
});

export default jobFunctions.reducer;
