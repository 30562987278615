import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const prodEnv = process.env.REACT_APP_ENV === 'production';
const newFrontendHost = prodEnv
  ? 'https://hr-system.k8s.corp.pony.ai:30443'
  : 'https://dev.hr-system.k8s.corp.pony.ai:30443';
const newFrontendLink = <a href={newFrontendHost}>{newFrontendHost}</a>;
const newGoLink = prodEnv ? (
  <a href="http://go/hr-system">go/hr-system</a>
) : (
  <a href="http://go/hr-system-dev">go/hr-system-dev</a>
);

const useStyles = makeStyles((theme) => ({
  div: {
    fontSize: theme.spacing(6),
    color: 'red',
    fontWeight: 500,
    marginLeft: '300px',
    marginTop: '20px',
  },
}));

const MigrationNotice: React.FC = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.div}>
      {' '}
      The website has been migrated to {newFrontendLink}. You can use {newGoLink} to access.
    </Typography>
  );
};

export default MigrationNotice;
