export type KeyTypeProps<T> = {
  [key in keyof T]: T[key] extends PropertyKey | undefined ? key : never;
}[keyof T];

export type MapWith<T, K extends KeyTypeProps<T>> = Required<T>[K] extends PropertyKey
  ? {
      [key in Required<T>[K]]: T[];
    }
  : never;

export type MapBy<T, K extends KeyTypeProps<T>> = Required<T>[K] extends PropertyKey
  ? {
      [key in Required<T>[K]]: T;
    }
  : never;

export const mapWith = <T, K extends KeyTypeProps<T>>(values: T[], keyName: K): MapWith<T, K> => {
  return values.reduce((acc, cur) => {
    const key = cur[keyName];
    if (key !== undefined) {
      if (!acc[key]) {
        (acc[key] as T[]) = [];
      }
      (acc[key] as T[]).push(cur);
    }
    return acc;
  }, {} as MapWith<T, K>);
};

export const mapBy = <T, K extends KeyTypeProps<T>>(values: T[], keyName: K): MapBy<T, K> => {
  return values.reduce((acc, cur) => {
    const key = cur[keyName];
    if (key !== undefined) {
      if (acc[key] !== undefined) {
        console.warn(`key<${key}> already exist: ${acc[key]}, replace it with new value: ${cur}`);
      }
      (acc[key] as T) = cur;
    }
    return acc;
  }, {} as MapBy<T, K>);
};
