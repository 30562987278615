import { Action } from 'external/hr_system/proto/monitor_pb';

import { addApiPoint } from 'common/utils/monitor';
import ApiError from './apiError';
import { BasicObject } from '../common/types';

const handleFetchApiResponse = (response: Response): Response => {
  if (!response.ok) {
    throw new ApiError(response.statusText, response);
  }
  return response;
};

const getOptions = (options: RequestInit = { headers: {} }): RequestInit => ({
  credentials: 'include',
  ...options,
  headers: new Headers({ 'Content-Type': 'application/x-protobuf', ...options.headers }),
});

const getActionFromApiMethod = (method?: string) => {
  switch (method) {
    case 'GET':
      return Action.Enum.GET;
    case 'POST':
      return Action.Enum.POST;
    case 'PUT':
      return Action.Enum.PUT;
    case 'DELETE':
      return Action.Enum.DELETE;
    default:
      return Action.Enum.UNKNOWN;
  }
};

const monitoredFetch = async (href: string, init?: RequestInit): Promise<Response> => {
  const url = new URL(href);
  const path = url.pathname + url.search;
  const action = getActionFromApiMethod(init?.method?.toUpperCase());
  const start = performance.now();
  const response = await fetch(href, init);
  const durationInMs = Math.floor(performance.now() - start);
  addApiPoint(path, action, durationInMs);
  return response;
};

export default class BaseApi {
  private readonly apiHost: string;
  private fetchMethod: (href: string, init?: RequestInit) => Promise<Response>;

  constructor(apiHost?: string, addMonitor: boolean = true) {
    this.apiHost = apiHost || process.env.REACT_APP_SERVER_API_HOST || 'http://test.mock';
    this.fetchMethod = addMonitor ? monitoredFetch : window.fetch.bind(window);
  }

  public async get(
    path: string,
    params: BasicObject = {},
    options?: RequestInit,
  ): Promise<Response> {
    const url = new URL(this.apiHost + path);
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined) {
        url.searchParams.append(key, String(params[key]));
      }
    });
    const response = await this.fetchMethod(url.href, {
      method: 'GET',
      ...getOptions(options),
    });
    return handleFetchApiResponse(response);
  }

  public async post(path: string, options?: RequestInit): Promise<Response> {
    const response = await this.fetchMethod(this.apiHost + path, {
      method: 'POST',
      ...getOptions(options),
    });
    return handleFetchApiResponse(response);
  }

  public async put(path: string, options?: RequestInit): Promise<Response> {
    const response = await this.fetchMethod(this.apiHost + path, {
      method: 'PUT',
      ...getOptions(options),
    });
    return handleFetchApiResponse(response);
  }

  public async delete(path: string, options?: RequestInit): Promise<Response> {
    const response = await this.fetchMethod(this.apiHost + path, {
      method: 'DELETE',
      ...getOptions(options),
    });
    return handleFetchApiResponse(response);
  }
}
