import { FileUploadActionType, FileUploadThunkAction } from './types';

export const updateCurrentFileContent =
  (fileContent: string, fileName: string): FileUploadThunkAction<void> =>
  (dispatch) => {
    dispatch({
      type: FileUploadActionType.UPDATE_CURRENT_FILE_CONTENT,
      fileContent,
      fileName,
    });
  };

export const updatePreviewContent =
  (previewContent: string): FileUploadThunkAction<void> =>
  (dispatch) => {
    dispatch({
      type: FileUploadActionType.UPDATE_PREVIEW_CONTENT,
      previewContent,
    });
  };

export const clearContent = (): FileUploadThunkAction<void> => (dispatch) => {
  dispatch({
    type: FileUploadActionType.CLEAR_CONTENT,
  });
};
