// source: proto/promo_tool_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

import * as proto_promo_nominee_pb from 'hr_web/proto/promo_nominee_pb';
goog.object.extend(proto, proto_promo_nominee_pb);
import * as proto_promo_panel_pb from 'hr_web/proto/promo_panel_pb';
goog.object.extend(proto, proto_promo_panel_pb);
goog.exportSymbol('proto.hr_system.CreateOrUpdatePromoPanelRequest', null, global);
goog.exportSymbol('proto.hr_system.CreateOrUpdatePromoPanelResponse', null, global);
goog.exportSymbol('proto.hr_system.DeletePromoPanelsResponse', null, global);
goog.exportSymbol('proto.hr_system.GetIsPromoCommitteeRequest', null, global);
goog.exportSymbol('proto.hr_system.GetIsPromoCommitteeResponse', null, global);
goog.exportSymbol('proto.hr_system.GetPromoNomineesRequest', null, global);
goog.exportSymbol('proto.hr_system.GetPromoNomineesResponse', null, global);
goog.exportSymbol('proto.hr_system.GetPromoPanelsRequest', null, global);
goog.exportSymbol('proto.hr_system.GetPromoPanelsResponse', null, global);
goog.exportSymbol('proto.hr_system.UploadPromoPanelsRequest', null, global);
goog.exportSymbol('proto.hr_system.UploadPromoPanelsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdatePromoPanelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdatePromoPanelRequest.displayName = 'proto.hr_system.CreateOrUpdatePromoPanelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.CreateOrUpdatePromoPanelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.CreateOrUpdatePromoPanelResponse.displayName = 'proto.hr_system.CreateOrUpdatePromoPanelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPromoPanelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetPromoPanelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPromoPanelsRequest.displayName = 'proto.hr_system.GetPromoPanelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPromoPanelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetPromoPanelsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetPromoPanelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPromoPanelsResponse.displayName = 'proto.hr_system.GetPromoPanelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.DeletePromoPanelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.DeletePromoPanelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.DeletePromoPanelsResponse.displayName = 'proto.hr_system.DeletePromoPanelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UploadPromoPanelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.UploadPromoPanelsRequest.repeatedFields_, null);
};
goog.inherits(proto.hr_system.UploadPromoPanelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UploadPromoPanelsRequest.displayName = 'proto.hr_system.UploadPromoPanelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.UploadPromoPanelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.UploadPromoPanelsResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.UploadPromoPanelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.UploadPromoPanelsResponse.displayName = 'proto.hr_system.UploadPromoPanelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPromoNomineesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetPromoNomineesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPromoNomineesRequest.displayName = 'proto.hr_system.GetPromoNomineesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetPromoNomineesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.GetPromoNomineesResponse.repeatedFields_, null);
};
goog.inherits(proto.hr_system.GetPromoNomineesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetPromoNomineesResponse.displayName = 'proto.hr_system.GetPromoNomineesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetIsPromoCommitteeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetIsPromoCommitteeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetIsPromoCommitteeRequest.displayName = 'proto.hr_system.GetIsPromoCommitteeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.GetIsPromoCommitteeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.GetIsPromoCommitteeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.GetIsPromoCommitteeResponse.displayName = 'proto.hr_system.GetIsPromoCommitteeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdatePromoPanelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdatePromoPanelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    promoPanel: (f = msg.getPromoPanel()) && proto_promo_panel_pb.PromoPanel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdatePromoPanelRequest}
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdatePromoPanelRequest;
  return proto.hr_system.CreateOrUpdatePromoPanelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdatePromoPanelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdatePromoPanelRequest}
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_promo_panel_pb.PromoPanel;
      reader.readMessage(value,proto_promo_panel_pb.PromoPanel.deserializeBinaryFromReader);
      msg.setPromoPanel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdatePromoPanelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdatePromoPanelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromoPanel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_promo_panel_pb.PromoPanel.serializeBinaryToWriter
    );
  }
};


/**
 * optional PromoPanel promo_panel = 1;
 * @return {?proto.hr_system.PromoPanel}
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.prototype.getPromoPanel = function() {
  return /** @type{?proto.hr_system.PromoPanel} */ (
    jspb.Message.getWrapperField(this, proto_promo_panel_pb.PromoPanel, 1));
};


/**
 * @param {?proto.hr_system.PromoPanel|undefined} value
 * @return {!proto.hr_system.CreateOrUpdatePromoPanelRequest} returns this
*/
proto.hr_system.CreateOrUpdatePromoPanelRequest.prototype.setPromoPanel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdatePromoPanelRequest} returns this
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.prototype.clearPromoPanel = function() {
  return this.setPromoPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdatePromoPanelRequest.prototype.hasPromoPanel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.CreateOrUpdatePromoPanelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.CreateOrUpdatePromoPanelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    promoPanel: (f = msg.getPromoPanel()) && proto_promo_panel_pb.PromoPanel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.CreateOrUpdatePromoPanelResponse}
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.CreateOrUpdatePromoPanelResponse;
  return proto.hr_system.CreateOrUpdatePromoPanelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.CreateOrUpdatePromoPanelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.CreateOrUpdatePromoPanelResponse}
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_promo_panel_pb.PromoPanel;
      reader.readMessage(value,proto_promo_panel_pb.PromoPanel.deserializeBinaryFromReader);
      msg.setPromoPanel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.CreateOrUpdatePromoPanelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.CreateOrUpdatePromoPanelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromoPanel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_promo_panel_pb.PromoPanel.serializeBinaryToWriter
    );
  }
};


/**
 * optional PromoPanel promo_panel = 1;
 * @return {?proto.hr_system.PromoPanel}
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.prototype.getPromoPanel = function() {
  return /** @type{?proto.hr_system.PromoPanel} */ (
    jspb.Message.getWrapperField(this, proto_promo_panel_pb.PromoPanel, 1));
};


/**
 * @param {?proto.hr_system.PromoPanel|undefined} value
 * @return {!proto.hr_system.CreateOrUpdatePromoPanelResponse} returns this
*/
proto.hr_system.CreateOrUpdatePromoPanelResponse.prototype.setPromoPanel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.CreateOrUpdatePromoPanelResponse} returns this
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.prototype.clearPromoPanel = function() {
  return this.setPromoPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.CreateOrUpdatePromoPanelResponse.prototype.hasPromoPanel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPromoPanelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPromoPanelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPromoPanelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPromoPanelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPromoPanelsRequest}
 */
proto.hr_system.GetPromoPanelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPromoPanelsRequest;
  return proto.hr_system.GetPromoPanelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPromoPanelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPromoPanelsRequest}
 */
proto.hr_system.GetPromoPanelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPromoPanelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPromoPanelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPromoPanelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPromoPanelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.GetPromoPanelsRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPromoPanelsRequest} returns this
 */
proto.hr_system.GetPromoPanelsRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoPanelsRequest} returns this
 */
proto.hr_system.GetPromoPanelsRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoPanelsRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetPromoPanelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPromoPanelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPromoPanelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPromoPanelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPromoPanelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    promoPanelsList: jspb.Message.toObjectList(msg.getPromoPanelsList(),
    proto_promo_panel_pb.PromoPanel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPromoPanelsResponse}
 */
proto.hr_system.GetPromoPanelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPromoPanelsResponse;
  return proto.hr_system.GetPromoPanelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPromoPanelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPromoPanelsResponse}
 */
proto.hr_system.GetPromoPanelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_promo_panel_pb.PromoPanel;
      reader.readMessage(value,proto_promo_panel_pb.PromoPanel.deserializeBinaryFromReader);
      msg.addPromoPanels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPromoPanelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPromoPanelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPromoPanelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPromoPanelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromoPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_promo_panel_pb.PromoPanel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PromoPanel promo_panels = 1;
 * @return {!Array<!proto.hr_system.PromoPanel>}
 */
proto.hr_system.GetPromoPanelsResponse.prototype.getPromoPanelsList = function() {
  return /** @type{!Array<!proto.hr_system.PromoPanel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_promo_panel_pb.PromoPanel, 1));
};


/**
 * @param {!Array<!proto.hr_system.PromoPanel>} value
 * @return {!proto.hr_system.GetPromoPanelsResponse} returns this
*/
proto.hr_system.GetPromoPanelsResponse.prototype.setPromoPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.PromoPanel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.PromoPanel}
 */
proto.hr_system.GetPromoPanelsResponse.prototype.addPromoPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.PromoPanel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetPromoPanelsResponse} returns this
 */
proto.hr_system.GetPromoPanelsResponse.prototype.clearPromoPanelsList = function() {
  return this.setPromoPanelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.DeletePromoPanelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.DeletePromoPanelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.DeletePromoPanelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeletePromoPanelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deletedCount: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.DeletePromoPanelsResponse}
 */
proto.hr_system.DeletePromoPanelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.DeletePromoPanelsResponse;
  return proto.hr_system.DeletePromoPanelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.DeletePromoPanelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.DeletePromoPanelsResponse}
 */
proto.hr_system.DeletePromoPanelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.DeletePromoPanelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.DeletePromoPanelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.DeletePromoPanelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.DeletePromoPanelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 deleted_count = 1;
 * @return {number}
 */
proto.hr_system.DeletePromoPanelsResponse.prototype.getDeletedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.DeletePromoPanelsResponse} returns this
 */
proto.hr_system.DeletePromoPanelsResponse.prototype.setDeletedCount = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.DeletePromoPanelsResponse} returns this
 */
proto.hr_system.DeletePromoPanelsResponse.prototype.clearDeletedCount = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.DeletePromoPanelsResponse.prototype.hasDeletedCount = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.UploadPromoPanelsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UploadPromoPanelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UploadPromoPanelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadPromoPanelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    promoPanelsList: jspb.Message.toObjectList(msg.getPromoPanelsList(),
    proto_promo_panel_pb.PromoPanel.toObject, includeInstance),
    overwrite: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UploadPromoPanelsRequest}
 */
proto.hr_system.UploadPromoPanelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UploadPromoPanelsRequest;
  return proto.hr_system.UploadPromoPanelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UploadPromoPanelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UploadPromoPanelsRequest}
 */
proto.hr_system.UploadPromoPanelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_promo_panel_pb.PromoPanel;
      reader.readMessage(value,proto_promo_panel_pb.PromoPanel.deserializeBinaryFromReader);
      msg.addPromoPanels(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverwrite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UploadPromoPanelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UploadPromoPanelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadPromoPanelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromoPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_promo_panel_pb.PromoPanel.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated PromoPanel promo_panels = 1;
 * @return {!Array<!proto.hr_system.PromoPanel>}
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.getPromoPanelsList = function() {
  return /** @type{!Array<!proto.hr_system.PromoPanel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_promo_panel_pb.PromoPanel, 1));
};


/**
 * @param {!Array<!proto.hr_system.PromoPanel>} value
 * @return {!proto.hr_system.UploadPromoPanelsRequest} returns this
*/
proto.hr_system.UploadPromoPanelsRequest.prototype.setPromoPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.PromoPanel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.PromoPanel}
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.addPromoPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.PromoPanel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UploadPromoPanelsRequest} returns this
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.clearPromoPanelsList = function() {
  return this.setPromoPanelsList([]);
};


/**
 * optional bool overwrite = 2;
 * @return {boolean}
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.getOverwrite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.UploadPromoPanelsRequest} returns this
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.setOverwrite = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.UploadPromoPanelsRequest} returns this
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.clearOverwrite = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.UploadPromoPanelsRequest.prototype.hasOverwrite = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.UploadPromoPanelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.UploadPromoPanelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.UploadPromoPanelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.UploadPromoPanelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadPromoPanelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    promoPanelIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.UploadPromoPanelsResponse}
 */
proto.hr_system.UploadPromoPanelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.UploadPromoPanelsResponse;
  return proto.hr_system.UploadPromoPanelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.UploadPromoPanelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.UploadPromoPanelsResponse}
 */
proto.hr_system.UploadPromoPanelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.addPromoPanelIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.UploadPromoPanelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.UploadPromoPanelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.UploadPromoPanelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.UploadPromoPanelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromoPanelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 promo_panel_ids = 1;
 * @return {!Array<number>}
 */
proto.hr_system.UploadPromoPanelsResponse.prototype.getPromoPanelIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hr_system.UploadPromoPanelsResponse} returns this
 */
proto.hr_system.UploadPromoPanelsResponse.prototype.setPromoPanelIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hr_system.UploadPromoPanelsResponse} returns this
 */
proto.hr_system.UploadPromoPanelsResponse.prototype.addPromoPanelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.UploadPromoPanelsResponse} returns this
 */
proto.hr_system.UploadPromoPanelsResponse.prototype.clearPromoPanelIdsList = function() {
  return this.setPromoPanelIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPromoNomineesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPromoNomineesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPromoNomineesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageNumber: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    pageSize: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    userId: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    managerUserId: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    sites: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    departments: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    levels: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    resolutionRating: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPromoNomineesRequest}
 */
proto.hr_system.GetPromoNomineesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPromoNomineesRequest;
  return proto.hr_system.GetPromoNomineesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPromoNomineesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPromoNomineesRequest}
 */
proto.hr_system.GetPromoNomineesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setManagerUserId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSites(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartments(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevels(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setResolutionRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPromoNomineesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPromoNomineesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPromoNomineesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 page_number = 2;
 * @return {number}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearPageNumber = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasPageNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 page_size = 3;
 * @return {number}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearPageSize = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 user_id = 4;
 * @return {number}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 manager_user_id = 6;
 * @return {number}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getManagerUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setManagerUserId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearManagerUserId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasManagerUserId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string sites = 7;
 * @return {string}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getSites = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setSites = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearSites = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasSites = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string departments = 8;
 * @return {string}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getDepartments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setDepartments = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearDepartments = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasDepartments = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string levels = 9;
 * @return {string}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getLevels = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setLevels = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearLevels = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasLevels = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string resolution_rating = 10;
 * @return {string}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.getResolutionRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.setResolutionRating = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesRequest} returns this
 */
proto.hr_system.GetPromoNomineesRequest.prototype.clearResolutionRating = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesRequest.prototype.hasResolutionRating = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.GetPromoNomineesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetPromoNomineesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetPromoNomineesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetPromoNomineesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPromoNomineesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nomineesList: jspb.Message.toObjectList(msg.getNomineesList(),
    proto_promo_nominee_pb.PromoNominee.toObject, includeInstance),
    totalCount: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetPromoNomineesResponse}
 */
proto.hr_system.GetPromoNomineesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetPromoNomineesResponse;
  return proto.hr_system.GetPromoNomineesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetPromoNomineesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetPromoNomineesResponse}
 */
proto.hr_system.GetPromoNomineesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_promo_nominee_pb.PromoNominee;
      reader.readMessage(value,proto_promo_nominee_pb.PromoNominee.deserializeBinaryFromReader);
      msg.addNominees(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetPromoNomineesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetPromoNomineesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetPromoNomineesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetPromoNomineesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNomineesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_promo_nominee_pb.PromoNominee.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated PromoNominee nominees = 1;
 * @return {!Array<!proto.hr_system.PromoNominee>}
 */
proto.hr_system.GetPromoNomineesResponse.prototype.getNomineesList = function() {
  return /** @type{!Array<!proto.hr_system.PromoNominee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_promo_nominee_pb.PromoNominee, 1));
};


/**
 * @param {!Array<!proto.hr_system.PromoNominee>} value
 * @return {!proto.hr_system.GetPromoNomineesResponse} returns this
*/
proto.hr_system.GetPromoNomineesResponse.prototype.setNomineesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hr_system.PromoNominee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.PromoNominee}
 */
proto.hr_system.GetPromoNomineesResponse.prototype.addNominees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hr_system.PromoNominee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.GetPromoNomineesResponse} returns this
 */
proto.hr_system.GetPromoNomineesResponse.prototype.clearNomineesList = function() {
  return this.setNomineesList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.hr_system.GetPromoNomineesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetPromoNomineesResponse} returns this
 */
proto.hr_system.GetPromoNomineesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetPromoNomineesResponse} returns this
 */
proto.hr_system.GetPromoNomineesResponse.prototype.clearTotalCount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetPromoNomineesResponse.prototype.hasTotalCount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetIsPromoCommitteeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetIsPromoCommitteeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetIsPromoCommitteeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetIsPromoCommitteeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewCycleId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetIsPromoCommitteeRequest}
 */
proto.hr_system.GetIsPromoCommitteeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetIsPromoCommitteeRequest;
  return proto.hr_system.GetIsPromoCommitteeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetIsPromoCommitteeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetIsPromoCommitteeRequest}
 */
proto.hr_system.GetIsPromoCommitteeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetIsPromoCommitteeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetIsPromoCommitteeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetIsPromoCommitteeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetIsPromoCommitteeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 review_cycle_id = 1;
 * @return {number}
 */
proto.hr_system.GetIsPromoCommitteeRequest.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.GetIsPromoCommitteeRequest} returns this
 */
proto.hr_system.GetIsPromoCommitteeRequest.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetIsPromoCommitteeRequest} returns this
 */
proto.hr_system.GetIsPromoCommitteeRequest.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetIsPromoCommitteeRequest.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.GetIsPromoCommitteeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.GetIsPromoCommitteeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.GetIsPromoCommitteeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetIsPromoCommitteeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isPromoCommittee: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.GetIsPromoCommitteeResponse}
 */
proto.hr_system.GetIsPromoCommitteeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.GetIsPromoCommitteeResponse;
  return proto.hr_system.GetIsPromoCommitteeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.GetIsPromoCommitteeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.GetIsPromoCommitteeResponse}
 */
proto.hr_system.GetIsPromoCommitteeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPromoCommittee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.GetIsPromoCommitteeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.GetIsPromoCommitteeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.GetIsPromoCommitteeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.GetIsPromoCommitteeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_promo_committee = 1;
 * @return {boolean}
 */
proto.hr_system.GetIsPromoCommitteeResponse.prototype.getIsPromoCommittee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hr_system.GetIsPromoCommitteeResponse} returns this
 */
proto.hr_system.GetIsPromoCommitteeResponse.prototype.setIsPromoCommittee = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.GetIsPromoCommitteeResponse} returns this
 */
proto.hr_system.GetIsPromoCommitteeResponse.prototype.clearIsPromoCommittee = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.GetIsPromoCommitteeResponse.prototype.hasIsPromoCommittee = function() {
  return jspb.Message.getField(this, 1) != null;
};


export const { CreateOrUpdatePromoPanelRequest, CreateOrUpdatePromoPanelResponse, DeletePromoPanelsResponse, GetIsPromoCommitteeRequest, GetIsPromoCommitteeResponse, GetPromoNomineesRequest, GetPromoNomineesResponse, GetPromoPanelsRequest, GetPromoPanelsResponse, UploadPromoPanelsRequest, UploadPromoPanelsResponse } = proto.hr_system
