import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  root: {
    position: 'fixed',
    height: 50,
    width: 50,
    bottom: 24,
    right: 24,
    borderRadius: '50%',
    background: '#CBCDD9',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity .3s, visibility .3s',
    '&.display': {
      opacity: 0.5,
      visibility: 'visible',
      '&:hover': {
        opacity: 1,
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '32px',
    },
  },
});

export default useStyle;
