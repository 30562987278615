// source: proto/audit_action.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.hr_system.Action', null, global);
/**
 * @enum {number}
 */
proto.hr_system.Action = {
  UNKNOWN: 0,
  CREATE: 1,
  MODIFY: 2,
  DELETE: 3
};

export const { Action } = proto.hr_system
