// source: proto/review_stage.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.hr_system.ReviewStage', null, global);
goog.exportSymbol('proto.hr_system.ReviewStage.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.ReviewStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.ReviewStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.ReviewStage.displayName = 'proto.hr_system.ReviewStage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.ReviewStage.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.ReviewStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.ReviewStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewStage.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.ReviewStage}
 */
proto.hr_system.ReviewStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.ReviewStage;
  return proto.hr_system.ReviewStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.ReviewStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.ReviewStage}
 */
proto.hr_system.ReviewStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.ReviewStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.ReviewStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.ReviewStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.ReviewStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.hr_system.ReviewStage.Enum = {
  UNKNOWN: 0,
  PLANNED: 1,
  SELF_ASSESSMENT_AND_PEER_REVIEW: 2,
  MANAGER_REVIEW: 3,
  CALIBRATION: 4,
  RESULT_CONFIRMATION: 7,
  MANAGER_COMMUNICATION: 5,
  COMPLETED: 6,
  PROMOTION_MANAGER_NOMINATION: 8,
  PROMOTION_NOMINATION: 9,
  PROMOTION_SELF_ASSESSMENT: 10,
  PROMOTION_MANAGER_REVIEW_AND_PEER_REVIEW: 11,
  PROMOTION_COMMITTEE_REVIEW: 12,
  PROMOTION_CALIBRATION: 13,
  PROMOTION_RESOLUTION: 14,
  PROMOTION_MANAGER_COMMUNICATION: 15,
  PROMOTION_COMPLETED: 16
};

export const { ReviewStage } = proto.hr_system
