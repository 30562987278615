import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  root: {
    background: 'inherit',
    height: 22,
    fontSize: 14,
    margin: '20px 24px',
    lineHeight: '22px',
    textAlign: 'right',
  },
  feedback: {
    color: '#98A3B2',
  },
});

export default useStyle;
