import { User } from 'external/hr_system/proto/entities_pb';
import {
  PromoPanel,
  PromoPanelType,
  PromoPanelUser,
  PromotionPanelRole,
} from 'external/hr_system/proto/promo_panel_pb';
import {
  GetIsPromoCommitteeResponse,
  GetPromoPanelsResponse,
  UploadPromoPanelsRequest,
  UploadPromoPanelsResponse,
} from 'external/hr_system/proto/promo_tool_api_pb';

import { getBufferArrayFromResponse, toClientJson, toServerJson } from 'common/utils/json';
import BaseApi from './baseApi';

export type PanelRole = PromotionPanelRole.EnumMap[keyof PromotionPanelRole.EnumMap];
export type PanelType = PromoPanelType.EnumMap[keyof PromoPanelType.EnumMap];
export type UploadPanelUser = {
  email: string;
  role: PanelRole;
};
export type UploadPanel = {
  name: string;
  type: PanelType;
  users: UploadPanelUser[];
};

class PromoPanelApi extends BaseApi {
  private endpointPrefix = '/promo/panels' as const;

  public async byCycleId(reviewCycleId: number): Promise<GetPromoPanelsResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, toServerJson({ reviewCycleId }));
    const buffer = await getBufferArrayFromResponse(response);

    return GetPromoPanelsResponse.toObject(false, GetPromoPanelsResponse.deserializeBinary(buffer));
  }

  public async uploadPanels(
    panelInfos: UploadPanel[],
    reviewCycleId: number,
    overwrite?: boolean,
  ): Promise<UploadPromoPanelsResponse.AsObject> {
    const panels = panelInfos.map((panelInfo) => {
      const panelPb = new PromoPanel();
      panelPb.setName(panelInfo.name);
      panelPb.setReviewCycleId(reviewCycleId);
      panelPb.setPanelType(panelInfo.type);
      panelPb.setPromoPanelUsersList(this.toPanelUserPbs(panelInfo.users));
      return panelPb;
    });

    const request = new UploadPromoPanelsRequest();
    overwrite && request.setOverwrite(true);
    request.setPromoPanelsList(panels);

    const response = await this.post(this.endpointPrefix + '/upload', {
      body: JSON.stringify(toServerJson(request.toObject())),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async isPromoCommittee(
    reviewCycleId?: number,
  ): Promise<GetIsPromoCommitteeResponse.AsObject> {
    const response = await this.get(
      this.endpointPrefix + '/check_is_promo_committee',
      reviewCycleId ? toServerJson({ reviewCycleId }) : undefined,
    );
    const buffer = await getBufferArrayFromResponse(response);

    return GetIsPromoCommitteeResponse.toObject(
      false,
      GetIsPromoCommitteeResponse.deserializeBinary(buffer),
    );
  }

  private toPanelUserPbs(uploadUsers: UploadPanelUser[]): PromoPanelUser[] {
    return uploadUsers.map((uploadUser) => {
      const panelUserPb = new PromoPanelUser();
      panelUserPb.setRole(uploadUser.role);

      const userPb = new User();
      userPb.setCompanyEmail(uploadUser.email);
      panelUserPb.setUser(userPb);

      return panelUserPb;
    });
  }
}

const promoPanelApi = new PromoPanelApi();
export default promoPanelApi;
