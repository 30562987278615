import { ThunkAction } from 'redux-thunk';

import authApi from 'api/authApi';
import userPermissionApi from 'api/userPermissionApi';
import { toClientJson } from 'common/utils/json';
import { AuthAction, AuthActionType, Session } from 'store/auth/types';
import reviewCollectionApi from '../../api/reviewCollectionApi';

type AuthThunkAction = ThunkAction<Promise<void>, {}, void, AuthAction>;

const fetchUserPermissions =
  (email: string): AuthThunkAction =>
  async (dispatch) => {
    const response = await userPermissionApi.getUserPermissions(email);
    dispatch({
      type: AuthActionType.FETCH_PERMISSIONS_COMPLETE,
      permissions: response.permissionsList,
    });
    const isManagerResponse = await reviewCollectionApi.checkIsManager();
    dispatch({
      type: AuthActionType.UPDATE_IS_MANAGER,
      isManager: isManagerResponse.isManager === true,
    });
  };

const onLoginComplete =
  (session: Session): AuthThunkAction =>
  async (dispatch) => {
    dispatch({ type: AuthActionType.LOGIN_COMPLETE, session });
    dispatch(fetchUserPermissions(session.user.email));
  };

export const refreshLogin =
  (callback?: () => void): AuthThunkAction =>
  async (dispatch) => {
    try {
      dispatch({ type: AuthActionType.LOGIN_START });
      const session = await authApi.refreshLogin();
      dispatch(onLoginComplete(toClientJson(session)));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await authApi.refreshTokens();
          const session = await authApi.refreshLogin();
          dispatch(onLoginComplete(toClientJson(session)));
        } catch (error) {
          dispatch({ type: AuthActionType.LOGOUT_COMPLETE });
        }
      } else {
        dispatch({ type: AuthActionType.LOGOUT_COMPLETE });
      }
    } finally {
      callback && callback();
    }
  };

export const loginWithSSO = (): AuthThunkAction => async (dispatch) => {
  dispatch({ type: AuthActionType.LOGIN_START });
  const response = await authApi.loginWithSSO();
  if (response.redirect) {
    window.location.href = response.redirect;
  }
};

export const logout = (): AuthThunkAction => async (dispatch) => {
  const response = await authApi.logout();
  if (response.redirect) {
    window.location.href = response.redirect;
  }
  dispatch({ type: AuthActionType.LOGOUT_COMPLETE });
};
