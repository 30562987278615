import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User } from 'external/hr_system/proto/entities_pb';

import userApi from 'api/userApi';
import { AppStateType } from 'store';
import { EmployeeStatusValues } from 'types/types';

export interface EmployeeFilter {
  departments?: number[];
  sites?: number[];
  employeeStatus?: EmployeeStatusValues | -1;
}

interface EmployeePaginationState extends EmployeeFilter {
  employees: User.AsObject[];
  // count from 0, following mui table pagination component
  page: number;
  pageSize: number;
  totalCount: number;
  loading: boolean;
}

const parseSearchParam = (key: string): number[] => {
  const search = new URLSearchParams(window.location.search);
  return (
    search
      .get(key)
      ?.split(',')
      .map((s) => Number.parseInt(s)) || []
  );
};

const initialState: EmployeePaginationState = {
  employees: [],
  page: 0,
  pageSize: 10,
  totalCount: 1,
  loading: false,
  departments: parseSearchParam('departments'),
  sites: parseSearchParam('sites'),
  employeeStatus:
    (parseSearchParam('employeeStatus')[0] as EmployeeStatusValues) || User.EmployeeStatus.ACTIVE,
};

export const fetchEmployeesByPagination = createAsyncThunk(
  'employees/fetchByPagination',
  async (params: Partial<EmployeePaginationState>, { getState }) => {
    const { employeePagination } = (getState() as AppStateType).employees;
    const { page, pageSize, departments, sites, employeeStatus } = {
      ...employeePagination,
      ...params,
    };

    // users api page number count from 1, and mui table page component count from 0
    const response = await userApi.getUsersByPagination(
      page + 1,
      pageSize,
      departments,
      sites,
      employeeStatus === -1 ? undefined : employeeStatus,
    );
    return { response, ...params };
  },
);

export const setEmployees = createAction<User.AsObject[]>('employees/setEmployees');

export const setTotalCount = createAction<number>('employees/setTotalCount');

export const setPage = createAction<number>('employees/setPage');

export const setPageSize = createAction<number>('employees/setPageSize');

const employeePagination = createSlice({
  name: 'employeePagination',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeesByPagination.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEmployeesByPagination.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchEmployeesByPagination.fulfilled, (state, action) => {
        const { response, page, pageSize, departments, sites, employeeStatus } = action.payload;
        state.employees = response.usersList;
        state.totalCount = response.totalCount || 0;
        page !== undefined && (state.page = page);
        pageSize !== undefined && (state.pageSize = pageSize);
        departments !== undefined && (state.departments = departments);
        sites !== undefined && (state.sites = sites);
        employeeStatus !== undefined && (state.employeeStatus = employeeStatus);
        state.loading = false;
      })
      .addCase(setEmployees, (state, action) => {
        state.employees = action.payload;
      })
      .addCase(setTotalCount, (state, action) => {
        state.totalCount = action.payload;
      })
      .addCase(setPage, (state, action) => {
        state.page = action.payload;
      })
      .addCase(setPageSize, (state, action) => {
        state.pageSize = action.payload;
      });
  },
});

export default employeePagination.reducer;
