import {
  CreateOrUpdateUserPermissionsRequest,
  GetAllUserPermissionsResponse,
  GetUserPermissionsResponse,
} from 'external/hr_system/proto/permission_pb';

import { getBufferArrayFromResponse, toClientJson, toServerJson } from 'common/utils/json';
import BaseApi from './baseApi';

class UserPermissionApi extends BaseApi {
  private endpointPrefix = '/permissions';

  public async getUserPermissions(email: string): Promise<GetUserPermissionsResponse.AsObject> {
    const response = await this.get(`${this.endpointPrefix}/${email}`, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetUserPermissionsResponse.toObject(
      false,
      GetUserPermissionsResponse.deserializeBinary(buffer),
    );
  }

  public async getAllUserPermission(): Promise<GetAllUserPermissionsResponse.AsObject> {
    const response = await this.get(`${this.endpointPrefix}`, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetAllUserPermissionsResponse.toObject(
      false,
      GetAllUserPermissionsResponse.deserializeBinary(buffer),
    );
  }

  public async updateUserPermission(
    userId: number,
    userPermission: CreateOrUpdateUserPermissionsRequest.AsObject,
  ): Promise<{ userId: string }> {
    const response = await this.post(`${this.endpointPrefix}/${userId}`, {
      body: JSON.stringify(toServerJson(userPermission)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(response.json());
  }
}

export default new UserPermissionApi();
