import { Action, DataPoint, Location, TableConfig } from 'external/hr_system/proto/monitor_pb';

import { AnalyticsFilterParams } from 'api/analyticsApi';
import monitorApi from 'api/monitorApi';
import { timeZoneInAsia } from './dateTime';
import { getJsonObjectFromStorage, setJsonObjectToStorage } from './storage';

export type MonitorPageType = '/performance/calibration' | '/performance/overview';
type PageAction = 'load' | 'reload';
type ActionStatus = 'start' | 'end';

const monitorPointsStorageKey = 'monitorPoints';
const maxPointNumber = 10;
const maxKeepTime = 1 * 60 * 1000; // 1 minutes

let timeoutId: number | null = null;
const clearTimeout = () => {
  timeoutId && window.clearTimeout(timeoutId);
  timeoutId = null;
};

const markName = (path: string, action: PageAction, status: ActionStatus) =>
  `${path}-${action}-${status}`;
const addMark = (path: string, action: PageAction, status: ActionStatus) =>
  performance.mark(markName(path, action, status));
const hasMark = (path: string, action: PageAction, status: ActionStatus) =>
  performance.getEntriesByName(markName(path, action, status)).length > 0;

const getStoragePoints = (): DataPoint.AsObject[] =>
  getJsonObjectFromStorage(sessionStorage, monitorPointsStorageKey) || [];

const addPoint = async (
  action: Action.EnumMap[keyof Action.EnumMap],
  durationInMs: number,
  page?: string,
  api?: string,
  reviewCycleId?: number,
  tableConfig?: TableConfig.AsObject,
) => {
  const point: DataPoint.AsObject = {
    timestampInMs: String(Date.now()),
    location: timeZoneInAsia() ? Location.Enum.CN : Location.Enum.US,
    page,
    api,
    action,
    durationInMs,
    metaData: { reviewCycleId, tableConfig },
  };
  const points = [...getStoragePoints(), point];
  if (points.length >= maxPointNumber) {
    postPoints(points);
  } else {
    if (!timeoutId) {
      timeoutId = window.setTimeout(() => {
        postPoints();
        clearTimeout();
      }, maxKeepTime);
    }
    setJsonObjectToStorage(sessionStorage, monitorPointsStorageKey, points);
  }
};

const postPoints = async (points: DataPoint.AsObject[] = getStoragePoints()) => {
  if (points && points.length > 0) {
    monitorApi.addPoints(points);
    sessionStorage.removeItem(monitorPointsStorageKey);
    clearTimeout();
  }
};

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    postPoints();
  }
});

export const markPageStart = (page: MonitorPageType, action: PageAction) => {
  addMark(page, action, 'start');
};
export const markPageEnd = (
  page: string,
  action: PageAction,
  reviewCycleId?: number,
  tableConfig?: TableConfig.AsObject,
) => {
  if (!hasMark(page, action, 'start')) {
    return;
  }
  addMark(page, action, 'end');
  const startMark = markName(page, action, 'start');
  const endMark = markName(page, action, 'end');
  const measureName = `${startMark} to ${endMark}`;
  performance.measure(measureName, startMark, endMark);
  const measureEntries = performance.getEntriesByName(measureName);
  if (measureEntries.length > 0) {
    const { duration } = measureEntries[measureEntries.length - 1];
    addPoint(
      action === 'load' ? Action.Enum.LOAD : Action.Enum.RELOAD,
      Math.round(duration),
      page,
      undefined,
      reviewCycleId,
      tableConfig,
    );
  }
  performance.clearMarks();
};

export const addApiPoint = (
  api: string,
  action:
    | Action.EnumMap['DELETE']
    | Action.EnumMap['GET']
    | Action.EnumMap['POST']
    | Action.EnumMap['PUT']
    | Action.EnumMap['UNKNOWN'],
  durationInMs: number,
) => addPoint(action, durationInMs, undefined, api);

export const loadMarkNotEnd = (page: MonitorPageType) => {
  return hasMark(page, 'load', 'start') && !hasMark(page, 'load', 'end');
};

export const getFilterTypesFromAnalytics = (params: AnalyticsFilterParams) => {
  const filters: Array<TableConfig.FilterMap[keyof TableConfig.FilterMap]> = [];
  params.reviewCycleId !== undefined && filters.push(TableConfig.Filter.REVIEW_CYCLE);
  params.ratings?.length && filters.push(TableConfig.Filter.RATING);
  params.levels?.length && filters.push(TableConfig.Filter.LEVEL);
  params.userId !== undefined && filters.push(TableConfig.Filter.USER);
  params.managerUserId !== undefined && filters.push(TableConfig.Filter.MANAGER);
  params.department !== undefined && filters.push(TableConfig.Filter.DEPARTMENT);
  params.site !== undefined && filters.push(TableConfig.Filter.SITE);
  params.nominatedOnly !== undefined && filters.push(TableConfig.Filter.NOMINATED);
  return filters;
};
