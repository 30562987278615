import BaseApi from './baseApi';

interface RedirectProp {
  redirect: string;
}

class AuthApi extends BaseApi {
  public async loginWithSSO(): Promise<RedirectProp> {
    const response = await this.get('/auth/sso');
    return response.json();
  }

  public async refreshLogin(): Promise<any> {
    const response = await this.post('/auth/refresh_login', {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    return response.json();
  }

  public async refreshTokens(): Promise<void> {
    await this.post('/auth/refresh');
  }

  public async logout(): Promise<{ logout: boolean; redirect: string }> {
    const response = await this.post('/auth/logout');
    return response.json();
  }
}

const authApi = new AuthApi(process.env.AUTH_API_HOST);
export default authApi;
