import { createSelector } from 'reselect';

import { AppStateType } from 'store';

const getEmployeesById = (state: AppStateType) => state.employees.employeeMap.employeesById;
/**
 * get the site id in employee details, not considering user editing
 */
export const getEmployeeDetailsOriginalUserSiteId = (state: AppStateType) =>
  state.employees.employeeDetails.originalUser?.site?.id ||
  state.employees.employeeDetails.user.site?.id;

export const selectEmployeeList = createSelector(
  [getEmployeesById, (state: AppStateType) => state.employees.employeeMap.filteredIds],
  (employeesById, filteredIds) =>
    filteredIds.length > 0
      ? filteredIds.map((id) => employeesById[id])
      : Object.values(employeesById),
);

export const selectEmployeeIds = createSelector([getEmployeesById], (getEmployeesById) =>
  Object.keys(getEmployeesById).map((key) => Number(key)),
);

export const selectEmployeeSiteId = createSelector([getEmployeesById], (getEmployeesById) =>
  Object.keys(getEmployeesById).map((key) => Number(key)),
);
