import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Site } from 'external/hr_system/proto/entities_pb';

import siteApi from 'api/siteApi';

interface SitesState {
  sitesById: Record<number, Site.AsObject>;
  error?: string;
}

export const fetchSites = createAsyncThunk('sites/fetchAsMap', (force: boolean) =>
  siteApi.listAsCachedMap(force),
);

export const addSite = createAsyncThunk(
  'sites/add',
  async (name: string): Promise<Site.AsObject> => {
    const response = await siteApi.add(name);
    return constructSite(name, response.siteId);
  },
);

export const updateSite = createAsyncThunk(
  'sites/update',
  async (params: { name: string; id: number }) => {
    const response = await siteApi.update(params);
    return constructSite(params.name, response.siteId);
  },
);

export const deleteSite = createAsyncThunk('sites/delete', async (id: number) => {
  await siteApi.deleteOne(id);
  return id;
});

const constructSite = (name: string, id?: number) => {
  const result = new Site();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting siteId but it is undefined.');
  }
};

const sites = createSlice({
  name: 'sites',
  initialState: {
    sitesById: {},
  } as SitesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSites.fulfilled, (state, action) => {
        state.sitesById = action.payload;
      })
      .addCase(addSite.fulfilled, (state, action) => {
        const site = action.payload;
        if (site.id) {
          state.sitesById[site.id] = site;
        }
        state.error = undefined;
      })
      .addCase(addSite.rejected, (state, action) => {
        state.error = `Add site failed ${action.error?.message}`;
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        const site = action.payload;
        if (site.id) {
          state.sitesById[site.id] = site;
        }
        state.error = undefined;
      })
      .addCase(updateSite.rejected, (state, action) => {
        state.error = `Update site failed ${action.error?.message}`;
      })
      .addCase(deleteSite.fulfilled, (state, action) => {
        delete state.sitesById[action.payload];
        state.error = undefined;
      })
      .addCase(deleteSite.rejected, (state, action) => {
        state.error = `Delete site failed ${action.error?.message}`;
      });
  },
});

export default sites.reducer;
