import { User } from 'external/hr_system/proto/entities_pb';
import {
  BatchCreateOrUpdateUsersRequest,
  BatchCreateOrUpdateUsersResponse,
  CreateOrUpdateUserRequest,
  CreateOrUpdateUserResponse,
  GetUserBasicInfoResponse,
  GetUserResponse,
  GetUsersRequest,
  GetUsersResponse,
  GetUserSummariesRequest,
  GetUserSummariesResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import { BasicObject } from 'common/types';
import { getBufferArrayFromResponse, toClientJson, toServerJson } from 'common/utils/json';
import { EmployeeStatusValues } from 'types/types';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';

// TODO get user summaries by multiple fetches and remove this
const pageSizeForFetchAllUserSummaries = 1500 as const;

class UserApi extends BaseApi {
  private endpointPrefix = '/users';
  private idBasedMapCacher: IdBasedMapCacher<User.AsObject, GetUsersResponse.AsObject>;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetUsersResponse.AsObject>(() => this.getUsers());
    this.idBasedMapCacher = new IdBasedMapCacher<User.AsObject, GetUsersResponse.AsObject>(
      apiCacher,
      (response) => response.usersList,
    );
  }

  public async listAsCachedMap(force = false): Promise<Record<number, User.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async getUsers(): Promise<GetUsersResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetUsersResponse.toObject(false, GetUsersResponse.deserializeBinary(buffer));
  }

  public async getUsersByPagination(
    pageNumber: number,
    pageSize: number,
    departmentIdsList?: number[],
    siteIdsList?: number[],
    employeeStatus?: EmployeeStatusValues,
  ): Promise<GetUsersResponse.AsObject> {
    const params: BasicObject = {};
    params.pageNumber = pageNumber;
    params.pageSize = pageSize;
    departmentIdsList && (params.departmentIds = departmentIdsList.join(','));
    siteIdsList && (params.siteIds = siteIdsList.join(','));
    employeeStatus !== undefined && (params.employeeStatus = employeeStatus);

    const response = await this.get(this.endpointPrefix, toServerJson(params));
    const buffer = await getBufferArrayFromResponse(response);
    return GetUsersResponse.toObject(false, GetUsersResponse.deserializeBinary(buffer));
  }

  public async getUsersByIdentifier(
    search?: string,
    includeInactive?: boolean,
  ): Promise<GetUsersResponse.AsObject> {
    const request: GetUsersRequest.AsObject = {
      userSearchIdentifier: search || '',
      includeInactive: includeInactive ? 1 : 0,
    } as GetUsersRequest.AsObject;
    const response = await this.get(this.endpointPrefix, toServerJson(request), {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    const buffer = await getBufferArrayFromResponse(response);
    return GetUsersResponse.toObject(false, GetUsersResponse.deserializeBinary(buffer));
  }

  public async getUser(userId: number | string): Promise<GetUserResponse.AsObject> {
    const response = await this.get(`${this.endpointPrefix}/${userId}`, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetUserResponse.toObject(false, GetUserResponse.deserializeBinary(buffer));
  }

  public async getUserSummaries(params?: {
    forFinance?: boolean;
    departmentIds?: number[];
    siteIds?: number[];
    employeeStatus?: EmployeeStatusValues;
    pageSize?: number;
    pageNumber?: number;
  }): Promise<GetUserSummariesResponse.AsObject> {
    const request: GetUserSummariesRequest = new GetUserSummariesRequest();
    if (params) {
      params.forFinance !== undefined && request.setForFinance(params.forFinance);
      params.departmentIds?.length && request.setDepartmentIds(params.departmentIds.join(','));
      params.siteIds?.length && request.setSiteIds(params.siteIds.join(','));
      params.employeeStatus !== undefined && request.setEmployeeStatus(params.employeeStatus);
    }
    if (params?.pageSize === undefined && params?.pageNumber === undefined) {
      request.setPageSize(pageSizeForFetchAllUserSummaries);
      request.setPageNumber(1);
    } else {
      params?.pageSize !== undefined && request.setPageSize(params.pageSize);
      params?.pageNumber !== undefined && request.setPageNumber(params.pageNumber);
    }

    const response = await this.post(`${this.endpointPrefix}/summary`, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return GetUserSummariesResponse.toObject(
      false,
      GetUserSummariesResponse.deserializeBinary(buffer),
    );
  }

  public async createOrUpdateUser(
    request: CreateOrUpdateUserRequest,
  ): Promise<CreateOrUpdateUserResponse.AsObject> {
    // TODO(jiangwei): distinguish POST and PUT.
    const response = await this.post(this.endpointPrefix, { body: request.serializeBinary() });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateUserResponse.toObject(
      false,
      CreateOrUpdateUserResponse.deserializeBinary(buffer),
    );
  }

  public async createOrUpdateUserJson(
    request: CreateOrUpdateUserRequest.AsObject,
  ): Promise<CreateOrUpdateUserResponse.AsObject> {
    const response = await this.post(this.endpointPrefix, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async batchCreateOrUpdateUserJson(
    request: BatchCreateOrUpdateUsersRequest.AsObject,
  ): Promise<BatchCreateOrUpdateUsersResponse.AsObject> {
    const response = await this.post(`${this.endpointPrefix}/batch_create_or_update`, {
      body: JSON.stringify(toServerJson(request)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return toClientJson(await response.json());
  }

  public async getUserBasicInfos(): Promise<GetUserBasicInfoResponse.AsObject> {
    const response = await this.get(`${this.endpointPrefix}/get_user_basic_info`, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetUserBasicInfoResponse.toObject(
      false,
      GetUserBasicInfoResponse.deserializeBinary(buffer),
    );
  }
}

export default new UserApi();
