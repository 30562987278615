import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { union } from 'lodash';

import { User } from 'external/hr_system/proto/entities_pb';
import {
  CreateOrUpdateUserRequest,
  EffectiveDateEntry,
  GetUserResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import userApi from 'api/userApi';
import { PartialNonNullable } from 'common/utils/errorHandler';
import { PeopleRelationTypeKeys } from 'pages/org-chart/common/peopleRelationType';
import { AppStateType } from 'store';
import { setSnackbarContent } from 'store/snackbarContent';

interface PeopleRelationChangeEvent {
  relationType: PeopleRelationTypeKeys;
  deletedPeopleIds: number[];
  addedPeople: User.AsObject[];
}

interface EmployeeDetailsState {
  // the user info that following front end user updating
  user: User.AsObject;
  // the original user info not considering user update, set before user editing employee details
  originalUser?: User.AsObject;
  peopleRelations: Record<PeopleRelationTypeKeys, User.AsObject[]>;
  costCenters: number[];
  businessUnits: number[];
  departments: number[];
  error?: string;
  loading: boolean;
  saving: boolean;
  editing: boolean;
  departmentEffectiveDate: string;
  directManagerEffectiveDate: string;
  basicInfoEffectiveDate: string;
}

const initialState: EmployeeDetailsState = {
  user: new User().toObject(),
  peopleRelations: {
    directManager: [],
    dottedManager: [],
    directReport: [],
    dottedReport: [],
  },
  businessUnits: [],
  costCenters: [],
  departments: [],
  error: undefined,
  loading: false,
  saving: false,
  editing: false,
  departmentEffectiveDate: '',
  directManagerEffectiveDate: '',
  basicInfoEffectiveDate: '',
};

export const fetchEmployeeDetails = createAsyncThunk<
  GetUserResponse.AsObject,
  string | number,
  { rejectValue: string }
>('employees/fetchDetails', async (id: number | string, { dispatch, rejectWithValue }) => {
  try {
    return await userApi.getUser(id);
  } catch (error) {
    const message: string = (await error.response.json())?.error?.message ?? '';
    dispatch(setSnackbarContent({ type: 'error', content: `Fetch failed: ${message}` }));
    return rejectWithValue(message);
  }
});

export const saveEmployeeDetails = createAsyncThunk<
  User.AsObject,
  number | string | undefined,
  { rejectValue: string }
>(
  'employees/saveDetails',
  async (userId: number | string | undefined, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as AppStateType;
    const {
      user,
      peopleRelations,
      businessUnits,
      costCenters,
      departments,
      departmentEffectiveDate,
      directManagerEffectiveDate,
      basicInfoEffectiveDate,
    } = state.employees.employeeDetails;
    const { departmentsById } = state.entities.departments;
    const { costCentersById } = state.entities.costCenters;
    const { businessUnitsById } = state.entities.businessUnits;

    if (!user) {
      return rejectWithValue('User is undefined');
    }
    const effectiveDateList: EffectiveDateEntry.AsObject[] = [];
    if (departmentEffectiveDate) {
      effectiveDateList.push({
        effectiveDate: departmentEffectiveDate,
        effectedAttribute: EffectiveDateEntry.EffectedAttribute.DEPARTMENT,
      });
    }
    if (directManagerEffectiveDate) {
      effectiveDateList.push({
        effectiveDate: directManagerEffectiveDate,
        effectedAttribute: EffectiveDateEntry.EffectedAttribute.DIRECT_MANAGER,
      });
    }
    if (basicInfoEffectiveDate) {
      effectiveDateList.push({
        effectiveDate: basicInfoEffectiveDate,
        effectedAttribute: EffectiveDateEntry.EffectedAttribute.USER,
      });
    }

    const request: PartialNonNullable<CreateOrUpdateUserRequest.AsObject, 'user'> = {
      user: {
        ...user,
        site: user.site || {},
        employmentType: user.employmentType || {},
        jobFunction: user.jobFunction || {},
        jobType: user.jobType || {},
        title: user.title || {},
        departmentsList: departments.map((id) => departmentsById[id]),
        costCentersList: costCenters.map((id) => costCentersById[id]),
        businessUnitsList: businessUnits.map((id) => businessUnitsById[id]),
      },
      directManagerUserIdsList: peopleRelations.directManager.map((user) => Number(user.id)) || [],
      dottedManagerUserIdsList: peopleRelations.dottedManager.map((user) => Number(user.id)) || [],
      directReportUserIdsList: peopleRelations.directReport.map((user) => Number(user.id)) || [],
      dottedReportUserIdsList: peopleRelations.dottedReport.map((user) => Number(user.id)) || [],
      effectiveDateEntriesList: effectiveDateList,
    };
    try {
      const response = await userApi.createOrUpdateUserJson(request);
      request.user.id = response.userId;
    } catch (error) {
      const message: string = (await error.response.json())?.error?.message ?? '';
      dispatch(setSnackbarContent({ type: 'error', content: `Save failed: ${message}` }));
      return rejectWithValue(message);
    }
    return request.user;
  },
);

export const updateEmployeeDetails =
  createAction<Partial<EmployeeDetailsState>>('employees/updateDetails');
export const updateUser = createAction<Partial<User.AsObject>>('employees/updateUser');

export const updateReportRelations = createAction<PeopleRelationChangeEvent>(
  'employees/updateReportRelations',
);

const updateByKey = <T extends Record<string | number, any>>(origin: T, update: Partial<T>): T => {
  Object.entries(update).forEach(([key, value]) => {
    origin[key as keyof T] = value as T[keyof T];
  });
  return origin;
};

const employeeDetails = createSlice({
  name: 'employeeDetails',
  initialState,
  reducers: {
    employeeDetailsCleared() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeDetails.pending, (state) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(fetchEmployeeDetails.fulfilled, (state, action) => {
        const {
          user,
          directManagersList,
          dottedManagersList,
          directReportsList,
          dottedReportsList,
        } = action.payload;
        state.user = ({ ...user } as User.AsObject) || new User().toObject();
        state.originalUser = undefined;
        state.businessUnits = state.user.businessUnitsList.map((x) => Number(x.id));
        state.costCenters = state.user.costCentersList.map((x) => Number(x.id));
        state.departments = state.user.departmentsList.map((x) => Number(x.id));
        state.peopleRelations.directManager = directManagersList;
        state.peopleRelations.directReport = directReportsList;
        state.peopleRelations.dottedManager = dottedManagersList;
        state.peopleRelations.dottedReport = dottedReportsList;
        state.loading = false;
      })
      .addCase(fetchEmployeeDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(saveEmployeeDetails.pending, (state) => {
        state.error = undefined;
        state.saving = true;
      })
      .addCase(saveEmployeeDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.user.id = action.payload.id;
        }
        state.originalUser = undefined;
        state.saving = false;
      })
      .addCase(saveEmployeeDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.saving = false;
      })
      .addCase(updateUser, (state, action) => {
        if (!state.originalUser) {
          state.originalUser = { ...state.user };
        }
        updateByKey(state.user, action.payload);
      })
      .addCase(updateEmployeeDetails, (state, action) => {
        if (!state.originalUser) {
          state.originalUser = { ...state.user };
        }
        updateByKey(state, action.payload);
      })
      .addCase(
        updateReportRelations,
        (state, { payload: { relationType, addedPeople, deletedPeopleIds } }) => {
          const filteredRelations = state.peopleRelations[relationType].filter(
            (user) => !deletedPeopleIds.includes(Number(user.id)),
          );
          state.peopleRelations[relationType] = union(addedPeople, filteredRelations);
        },
      );
  },
});

export const { employeeDetailsCleared } = employeeDetails.actions;

export default employeeDetails.reducer;
