import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from 'common/PrivateRoute';
import AuthLayout from 'layouts/Auth';
import DashboardLayout from 'layouts/Dashboard';
import { RouteInfoType } from 'types/types';
import { authLayoutRoutes, dashboardLayoutRoutes } from './index';

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  routes.map(({ component: Component, children, path }, index: number) => {
    return children ? (
      children.map((element, index: number) => (
        <PrivateRoute
          key={index}
          path={element.path}
          exact
          component={element.component}
          layout={Layout}
        />
      ))
    ) : Component ? (
      <PrivateRoute key={index} path={path} exact component={Component} layout={Layout} />
    ) : null;
  });

const publiChildRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  routes.map(({ component: Component, children, path }, index: number) => {
    return children ? (
      children.map((element, index: number) => (
        <Layout key={index}>
          <Route key={index} path={element.path} exact component={element.component} />
        </Layout>
      ))
    ) : Component ? (
      <Layout key={index}>
        <Route key={index} path={path} exact component={Component} />
      </Layout>
    ) : null;
  });

const Routes = () => (
  <Router>
    <Switch>
      {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
      {publiChildRoutes(AuthLayout, authLayoutRoutes)}
    </Switch>
  </Router>
);

export default Routes;
