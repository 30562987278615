import { CostCenter } from 'external/hr_system/proto/entities_pb';
import {
  CreateOrUpdateCostCenterRequest,
  CreateOrUpdateCostCenterResponse,
  DeleteResponse,
  GetCostCentersResponse,
} from 'external/hr_system/proto/org_chart_api_pb';

import { getBufferArrayFromResponse } from 'common/utils/json';
import { ApiCacher, IdBasedMapCacher } from './apiCacheHelper';
import BaseApi from './baseApi';

class CostCenterApi extends BaseApi {
  private endpointPrefix = '/cost_centers';
  private idBasedMapCacher: IdBasedMapCacher<CostCenter.AsObject, GetCostCentersResponse.AsObject>;

  constructor() {
    super();
    const apiCacher = new ApiCacher<GetCostCentersResponse.AsObject>(() => this.list());
    this.idBasedMapCacher = new IdBasedMapCacher<
      CostCenter.AsObject,
      GetCostCentersResponse.AsObject
    >(apiCacher, (response) => response.costCentersList);
  }

  public async listAsCachedMap(force = false): Promise<Record<number, CostCenter.AsObject>> {
    return this.idBasedMapCacher.fetch(force);
  }

  public async list(): Promise<GetCostCentersResponse.AsObject> {
    const response = await this.get(this.endpointPrefix, {});
    const buffer = await getBufferArrayFromResponse(response);
    return GetCostCentersResponse.toObject(false, GetCostCentersResponse.deserializeBinary(buffer));
  }

  public async add(name: string): Promise<CreateOrUpdateCostCenterResponse.AsObject> {
    const request = new CreateOrUpdateCostCenterRequest();
    const costCenter = new CostCenter();
    costCenter.setName(name);
    request.setCostCenter(costCenter);

    const response = await this.post(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateCostCenterResponse.toObject(
      false,
      CreateOrUpdateCostCenterResponse.deserializeBinary(buffer),
    );
  }

  public async update({
    name,
    id,
  }: {
    name: string;
    id: number;
  }): Promise<CreateOrUpdateCostCenterResponse.AsObject> {
    const request = new CreateOrUpdateCostCenterRequest();
    const costCenter = new CostCenter();
    costCenter.setName(name);
    costCenter.setId(id);
    request.setCostCenter(costCenter);

    const response = await this.put(this.endpointPrefix, {
      body: request.serializeBinary(),
    });
    const buffer = await getBufferArrayFromResponse(response);
    return CreateOrUpdateCostCenterResponse.toObject(
      false,
      CreateOrUpdateCostCenterResponse.deserializeBinary(buffer),
    );
  }

  public async deleteOne(id: number): Promise<DeleteResponse.AsObject> {
    const response = await super.delete(this.endpointPrefix + '/' + id, {});
    const buffer = await getBufferArrayFromResponse(response);
    return DeleteResponse.toObject(false, DeleteResponse.deserializeBinary(buffer));
  }
}

export default new CostCenterApi();
