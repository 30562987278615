import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BusinessUnit } from 'external/hr_system/proto/entities_pb';

import businessUnitApi from 'api/businessUnitApi';

interface BusinessUnitsState {
  businessUnitsById: Record<number, BusinessUnit.AsObject>;
  error?: string;
}

export const fetchBusinessUnits = createAsyncThunk('businessUnits/fetchAsMap', (force: boolean) =>
  businessUnitApi.listAsCachedMap(force),
);

export const addBusinessUnit = createAsyncThunk(
  'businessUnits/add',
  async (name: string): Promise<BusinessUnit.AsObject> => {
    const response = await businessUnitApi.add(name);
    return constructBusinessUnit(name, response.businessUnitId);
  },
);

export const updateBusinessUnit = createAsyncThunk(
  'businessUnits/update',
  async (params: { name: string; id: number }) => {
    const response = await businessUnitApi.update(params);
    return constructBusinessUnit(params.name, response.businessUnitId);
  },
);

export const deleteBusinessUnit = createAsyncThunk('businessUnits/delete', async (id: number) => {
  await businessUnitApi.deleteOne(id);
  return id;
});

const constructBusinessUnit = (name: string, id?: number) => {
  const result = new BusinessUnit();
  if (id !== undefined) {
    result.setId(id);
    result.setName(name);
    return result.toObject();
  } else {
    throw new Error('Expecting businessUnitId but it is undefined.');
  }
};

const businessUnits = createSlice({
  name: 'businessUnits',
  initialState: {
    businessUnitsById: {},
  } as BusinessUnitsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessUnits.fulfilled, (state, action) => {
        state.businessUnitsById = action.payload;
      })
      .addCase(addBusinessUnit.fulfilled, (state, action) => {
        const businessUnit = action.payload;
        if (businessUnit.id) {
          state.businessUnitsById[businessUnit.id] = businessUnit;
        }
        state.error = undefined;
      })
      .addCase(addBusinessUnit.rejected, (state, action) => {
        state.error = `Add business unit failed ${action.error?.message}`;
      })
      .addCase(updateBusinessUnit.fulfilled, (state, action) => {
        const businessUnit = action.payload;
        if (businessUnit.id) {
          state.businessUnitsById[businessUnit.id] = businessUnit;
        }
        state.error = undefined;
      })
      .addCase(updateBusinessUnit.rejected, (state, action) => {
        state.error = `Update business unit failed ${action.error?.message}`;
      })
      .addCase(deleteBusinessUnit.fulfilled, (state, action) => {
        delete state.businessUnitsById[action.payload];
        state.error = undefined;
      })
      .addCase(deleteBusinessUnit.rejected, (state, action) => {
        state.error = `Delete business unit failed ${action.error?.message}`;
      });
  },
});

export default businessUnits.reducer;
