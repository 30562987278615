// source: proto/promo_panel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

import * as proto_entities_pb from 'hr_web/proto/entities_pb';
goog.object.extend(proto, proto_entities_pb);
goog.exportSymbol('proto.hr_system.PromoPanel', null, global);
goog.exportSymbol('proto.hr_system.PromoPanelType', null, global);
goog.exportSymbol('proto.hr_system.PromoPanelType.Enum', null, global);
goog.exportSymbol('proto.hr_system.PromoPanelUser', null, global);
goog.exportSymbol('proto.hr_system.PromotionPanelRole', null, global);
goog.exportSymbol('proto.hr_system.PromotionPanelRole.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.PromoPanelType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.PromoPanelType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.PromoPanelType.displayName = 'proto.hr_system.PromoPanelType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.PromotionPanelRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.PromotionPanelRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.PromotionPanelRole.displayName = 'proto.hr_system.PromotionPanelRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.PromoPanelUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hr_system.PromoPanelUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.PromoPanelUser.displayName = 'proto.hr_system.PromoPanelUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hr_system.PromoPanel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hr_system.PromoPanel.repeatedFields_, null);
};
goog.inherits(proto.hr_system.PromoPanel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hr_system.PromoPanel.displayName = 'proto.hr_system.PromoPanel';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.PromoPanelType.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.PromoPanelType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.PromoPanelType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.PromoPanelType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.PromoPanelType}
 */
proto.hr_system.PromoPanelType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.PromoPanelType;
  return proto.hr_system.PromoPanelType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.PromoPanelType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.PromoPanelType}
 */
proto.hr_system.PromoPanelType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.PromoPanelType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.PromoPanelType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.PromoPanelType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.PromoPanelType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.hr_system.PromoPanelType.Enum = {
  UNKNOWN: 0,
  NORMAL: 1,
  EXPRESS: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.PromotionPanelRole.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.PromotionPanelRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.PromotionPanelRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.PromotionPanelRole.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.PromotionPanelRole}
 */
proto.hr_system.PromotionPanelRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.PromotionPanelRole;
  return proto.hr_system.PromotionPanelRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.PromotionPanelRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.PromotionPanelRole}
 */
proto.hr_system.PromotionPanelRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.PromotionPanelRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.PromotionPanelRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.PromotionPanelRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.PromotionPanelRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.hr_system.PromotionPanelRole.Enum = {
  UNKNOWN: 0,
  CHAIR: 1,
  MEMBER: 2,
  NOMINEE: 3,
  HR: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.PromoPanelUser.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.PromoPanelUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.PromoPanelUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.PromoPanelUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    role: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    user: (f = msg.getUser()) && proto_entities_pb.User.toObject(includeInstance, f),
    userId: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.PromoPanelUser}
 */
proto.hr_system.PromoPanelUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.PromoPanelUser;
  return proto.hr_system.PromoPanelUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.PromoPanelUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.PromoPanelUser}
 */
proto.hr_system.PromoPanelUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPanelId(value);
      break;
    case 2:
      var value = /** @type {!proto.hr_system.PromotionPanelRole.Enum} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 3:
      var value = new proto_entities_pb.User;
      reader.readMessage(value,proto_entities_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.PromoPanelUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.PromoPanelUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.PromoPanelUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.PromoPanelUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {!proto.hr_system.PromotionPanelRole.Enum} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_entities_pb.User.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 panel_id = 1;
 * @return {number}
 */
proto.hr_system.PromoPanelUser.prototype.getPanelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.PromoPanelUser} returns this
 */
proto.hr_system.PromoPanelUser.prototype.setPanelId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.PromoPanelUser} returns this
 */
proto.hr_system.PromoPanelUser.prototype.clearPanelId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.PromoPanelUser.prototype.hasPanelId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PromotionPanelRole.Enum role = 2;
 * @return {!proto.hr_system.PromotionPanelRole.Enum}
 */
proto.hr_system.PromoPanelUser.prototype.getRole = function() {
  return /** @type {!proto.hr_system.PromotionPanelRole.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.hr_system.PromotionPanelRole.Enum} value
 * @return {!proto.hr_system.PromoPanelUser} returns this
 */
proto.hr_system.PromoPanelUser.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.PromoPanelUser} returns this
 */
proto.hr_system.PromoPanelUser.prototype.clearRole = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.PromoPanelUser.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional User user = 3;
 * @return {?proto.hr_system.User}
 */
proto.hr_system.PromoPanelUser.prototype.getUser = function() {
  return /** @type{?proto.hr_system.User} */ (
    jspb.Message.getWrapperField(this, proto_entities_pb.User, 3));
};


/**
 * @param {?proto.hr_system.User|undefined} value
 * @return {!proto.hr_system.PromoPanelUser} returns this
*/
proto.hr_system.PromoPanelUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hr_system.PromoPanelUser} returns this
 */
proto.hr_system.PromoPanelUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.PromoPanelUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 user_id = 4;
 * @return {number}
 */
proto.hr_system.PromoPanelUser.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.PromoPanelUser} returns this
 */
proto.hr_system.PromoPanelUser.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.PromoPanelUser} returns this
 */
proto.hr_system.PromoPanelUser.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.PromoPanelUser.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hr_system.PromoPanel.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hr_system.PromoPanel.prototype.toObject = function(opt_includeInstance) {
  return proto.hr_system.PromoPanel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hr_system.PromoPanel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.PromoPanel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    reviewCycleId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    promoPanelUsersList: jspb.Message.toObjectList(msg.getPromoPanelUsersList(),
    proto.hr_system.PromoPanelUser.toObject, includeInstance),
    panelType: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hr_system.PromoPanel}
 */
proto.hr_system.PromoPanel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hr_system.PromoPanel;
  return proto.hr_system.PromoPanel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hr_system.PromoPanel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hr_system.PromoPanel}
 */
proto.hr_system.PromoPanel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCycleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.hr_system.PromoPanelUser;
      reader.readMessage(value,proto.hr_system.PromoPanelUser.deserializeBinaryFromReader);
      msg.addPromoPanelUsers(value);
      break;
    case 5:
      var value = /** @type {!proto.hr_system.PromoPanelType.Enum} */ (reader.readEnum());
      msg.setPanelType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hr_system.PromoPanel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hr_system.PromoPanel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hr_system.PromoPanel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hr_system.PromoPanel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPromoPanelUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.hr_system.PromoPanelUser.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.hr_system.PromoPanelType.Enum} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.hr_system.PromoPanel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.PromoPanel.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 review_cycle_id = 2;
 * @return {number}
 */
proto.hr_system.PromoPanel.prototype.getReviewCycleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.setReviewCycleId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.clearReviewCycleId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.PromoPanel.prototype.hasReviewCycleId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.hr_system.PromoPanel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.PromoPanel.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated PromoPanelUser promo_panel_users = 4;
 * @return {!Array<!proto.hr_system.PromoPanelUser>}
 */
proto.hr_system.PromoPanel.prototype.getPromoPanelUsersList = function() {
  return /** @type{!Array<!proto.hr_system.PromoPanelUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hr_system.PromoPanelUser, 4));
};


/**
 * @param {!Array<!proto.hr_system.PromoPanelUser>} value
 * @return {!proto.hr_system.PromoPanel} returns this
*/
proto.hr_system.PromoPanel.prototype.setPromoPanelUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hr_system.PromoPanelUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hr_system.PromoPanelUser}
 */
proto.hr_system.PromoPanel.prototype.addPromoPanelUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hr_system.PromoPanelUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.clearPromoPanelUsersList = function() {
  return this.setPromoPanelUsersList([]);
};


/**
 * optional PromoPanelType.Enum panel_type = 5;
 * @return {!proto.hr_system.PromoPanelType.Enum}
 */
proto.hr_system.PromoPanel.prototype.getPanelType = function() {
  return /** @type {!proto.hr_system.PromoPanelType.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.hr_system.PromoPanelType.Enum} value
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.setPanelType = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hr_system.PromoPanel} returns this
 */
proto.hr_system.PromoPanel.prototype.clearPanelType = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hr_system.PromoPanel.prototype.hasPanelType = function() {
  return jspb.Message.getField(this, 5) != null;
};


export const { PromoPanel, PromoPanelType, PromoPanelUser, PromotionPanelRole } = proto.hr_system
